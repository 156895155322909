import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import configureStore from "./redux/configureStore/index";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./utility/theme";
import "react-notifications/dist/react-notifications.css";
import "./sidebar.css";
import "./components/asset/css/navigation.css";
import "./components/asset/component/buttons/buttons.css";
import "./components/asset/css/landing.css";
import "./components/asset/css/global.css";
import initFacebookSdk from "./facebook.js";
import { PersistGate } from 'redux-persist/integration/react';

const store = configureStore();

  ReactDOM.render(
    <BrowserRouter>
      <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              <App />
            </ThemeProvider>
        </Provider>
      </React.StrictMode>
    </BrowserRouter>,
    document.getElementById("root")
  )
// initFacebookSdk().then(
//   ReactDOM.render(
//     <BrowserRouter>
//       <React.StrictMode>
//         <Provider store={store}>
//             <ThemeProvider theme={theme}>
//               {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
//               <CssBaseline />
//               <App />
//             </ThemeProvider>
//         </Provider>
//       </React.StrictMode>
//     </BrowserRouter>,
//     document.getElementById("root")
//   )
// );


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
