import React, { useState } from "react";
import BarChart from "./BarChart/BarChart";
import { Bar, Pie, Line, Doughnut } from "react-chartjs-2";
import { Chart } from "chart.js/auto";

function SecondComparedItem({
  keywordTwo,
  keywordTwoData,
  // secondEng,
  // secondMention,
  // secondImpress,
  // noOfSecondMention,
  // TotalMentionsKeyTwo,
  // TotalPositiveSentimentKeyTwo,
  // TotalNeutralSentimentKeyTwo,
  // TotalNegativeSentimentKeyTwo,
  // TotalTwitterMentionsKeyTwo,
  // TotalFacebookMentionsKeyTwo,
}) {

   const twitterHashtagKeyTwo = keywordTwoData[0] || [];
   var twitterHashtagResultKeyTwo = twitterHashtagKeyTwo?.result || [];
   var twitterHashtagTotalEngagementKeyTwo =
     twitterHashtagKeyTwo?.Engagement_total || [];
   var twitterHashtagTotalImpressionKeyTwo =
     twitterHashtagKeyTwo?.Impression_total || [];
   var twitterHashtagTotalSentimentKeyTwo =
     twitterHashtagKeyTwo?.Total_sentiment || [];
   var twitterHashtagEngagementAnalysisKeyTwo =
     twitterHashtagKeyTwo?.engagement_analysis || [];
   var { sentiment: twitterHashtagSentimentCountKeyTwo } =
     twitterHashtagKeyTwo?.sentiment_count || {};
   var {
     NEG: twitterNegKeyTwo,
     NEU: twitterNeuKeyTwo,
     POS: twitterPosKeyTwo,
   } = twitterHashtagSentimentCountKeyTwo || {};
   var twitlikesKeyTwo = 0;
   var twitcommentsKeyTwo = 0;
   var twitRetweetsKeyTwo = 0;
   const twitterLikesKeyTwo = twitterHashtagResultKeyTwo.map((item) => {
     return (twitlikesKeyTwo += item.Likes);
   });
   const twitterCommentsKeyTwo = twitterHashtagResultKeyTwo.map((item) => {
     return (twitcommentsKeyTwo += item.Reply_count);
   });
   const twitterRetweetsKeyTwo = twitterHashtagResultKeyTwo.map((item) => {
     return (twitRetweetsKeyTwo += item.Retweet_count);
   });

   // twitterSearchData
   const twitterKeywordKeyTwo = keywordTwoData[2] || [];
   var twitterKeywordResultKeyTwo = twitterKeywordKeyTwo?.result || [];
   var twitterKeywordTotalEngagementKeyTwo =
     twitterKeywordKeyTwo?.Engagement_total || [];
   var twitterKeywordTotalImpressionKeyTwo =
     twitterKeywordKeyTwo?.Impression_total || [];
   var twitterKeywordTotalSentimentKeyTwo =
     twitterKeywordKeyTwo?.Total_sentiment || [];
   var twitterKeywordEngagementAnalysisKeyTwo =
     twitterKeywordKeyTwo?.engagement_analysis || [];
   var { sentiment: twitterKeywordSentimentCountKeyTwo } =
     twitterKeywordKeyTwo?.sentiment_count || {};
   var {
     NEG: twitterSrcNegKeyTwo,
     NEU: twitterSrcNeuKeyTwo,
     POS: twitterSrcPosKeyTwo,
   } = twitterHashtagSentimentCountKeyTwo || {};

   const genTwitterDataKeyTwo = [
     ...twitterHashtagResultKeyTwo,
     ...twitterKeywordResultKeyTwo,
   ];

   // facebookSearchData
   const facebookKeywordKeyTwo = keywordTwoData[1] || [];
   const facebookKeywordResultKeyTwo = facebookKeywordKeyTwo?.result || [];
   const facebookKeywordTotalSentimentKeyTwo =
     facebookKeywordKeyTwo?.Total_sentiment || [];
   const facebookKeywordTotalEngagementKeyTwo =
     facebookKeywordKeyTwo?.Total_engagement || [];
   const { Total_engagement: facebookTotalEngagementKeyTwo } =
     facebookKeywordTotalEngagementKeyTwo[0] || {};
   const facebookKeywordSentimentCountKeyTwo =
     facebookKeywordKeyTwo?.sentiment_count || {};
   const facebookKeywordSentimentCountPostsKeyTwo =
     facebookKeywordSentimentCountKeyTwo.posts_sentiment || {};
   const {
     NEG: FacebookNegKeyTwo,
     NEU: FacebookNeuKeyTwo,
     POS: FacebookPosKeyTwo,
   } = facebookKeywordSentimentCountPostsKeyTwo || {};
   var faceLikesKeyTwo = 0;
   var faceCommentsKeyTwo = 0;
   var faceSharesKeyTwo = 0;
   const facebookLikesKeyTwo = facebookKeywordResultKeyTwo.map((item) => {
     return (faceLikesKeyTwo += item.likes_no);
   });
   const facebookComments = facebookKeywordResultKeyTwo.map((item) => {
     return (faceCommentsKeyTwo += item.comments_no);
   });
   const facebookShares = facebookKeywordResultKeyTwo.map((item) => {
     return (faceSharesKeyTwo += item.shares_no);
   });

   // blogSearchData
   const blogSearchwordKeyTwo = keywordTwoData[3] || [];
   const blogSearchwordResultKeyTwo = blogSearchwordKeyTwo?.result || [];
   const blogSearchwordSentimentCountKeyTwo =
     blogSearchwordKeyTwo?.sentiment_count || {};
   const blogSearchwordSentimentCountPostsKeyTwo =
     blogSearchwordSentimentCountKeyTwo.sentiment || {};
   const {
     NEG: BlogNegKeyTwo,
     NEU: BlogNeuKeyTwo,
     POS: BlogPosKeyTwo,
   } = blogSearchwordSentimentCountPostsKeyTwo || {};

   const TotalMentionsKeyTwo =
     twitterHashtagResultKeyTwo.length +
     facebookKeywordResultKeyTwo.length +
     // blogSearchwordResultKeyTwo.length +
     twitterKeywordResultKeyTwo.length;

  //  console.log("TotalMentionsKeyTwo", TotalMentionsKeyTwo);

   const TotalPositiveSentimentKeyTwo =
     twitterPosKeyTwo + twitterSrcPosKeyTwo + FacebookPosKeyTwo;

   const TotalNegativeSentimentKeyTwo =
     twitterNegKeyTwo + twitterSrcNegKeyTwo + FacebookNegKeyTwo;

   const TotalNeutralSentimentKeyTwo =
     twitterNeuKeyTwo + twitterSrcNeuKeyTwo + FacebookNeuKeyTwo;

   const TotalTwitterMentionsKeyTwo =
     twitterHashtagResultKeyTwo.length + twitterKeywordResultKeyTwo.length || 0;

   const TotalFacebookMentionsKeyTwo = facebookKeywordResultKeyTwo.length || 0;

   const TotalBlogMentionsKeyTwo = blogSearchwordResultKeyTwo.length || 0;

   // console.log(TotalMentionsKeyTwo);
   // console.log(TotalPositiveSentimentKeyTwo);
   // console.log(TotalNegativeSentimentKeyTwo);
   // console.log(TotalNeutralSentimentKeyTwo);
   // console.log(TotalTwitterMentionsKeyTwo);
   // console.log(TotalFacebookMentionsKeyTwo);
   // console.log(TotalBlogMentionsKeyTwo);

   const FacebookTotalSentimentsKeyTwo =
     FacebookNegKeyTwo + FacebookNeuKeyTwo + FacebookPosKeyTwo;
   const FacePosPercentageKeyTwo =
     (FacebookPosKeyTwo / FacebookTotalSentimentsKeyTwo) * 100;
   const FaceNeuPercentageKeyTwo =
     (FacebookNeuKeyTwo / FacebookTotalSentimentsKeyTwo) * 100;
   const FaceNegPercentageKeyTwo =
     (FacebookNegKeyTwo / FacebookTotalSentimentsKeyTwo) * 100;

   const TwitterTotalSentimentsKeyTwo =
     twitterNegKeyTwo +
     twitterSrcNegKeyTwo +
     twitterSrcNeuKeyTwo +
     twitterNeuKeyTwo +
     twitterPosKeyTwo +
     twitterSrcPosKeyTwo;
   const TwitterPosPercentageKeyTwo =
     ((twitterPosKeyTwo + twitterSrcPosKeyTwo) / TwitterTotalSentimentsKeyTwo) *
     100;
   const TwitterNeuPercentageKeyTwo =
     ((twitterNeuKeyTwo + twitterSrcNeuKeyTwo) / TwitterTotalSentimentsKeyTwo) *
     100;
   const TwitterNegPercentageKeyTwo =
     ((twitterNegKeyTwo + twitterSrcNegKeyTwo) / TwitterTotalSentimentsKeyTwo) *
     100;

   const BlogTotalSentimentsKeyTwo =
     BlogNegKeyTwo + BlogNeuKeyTwo + BlogPosKeyTwo;
   const BlogPosPercentageKeyTwo =
     (BlogPosKeyTwo / BlogTotalSentimentsKeyTwo) * 100;
   const BlogNeuPercentageKeyTwo =
     (BlogNeuKeyTwo / BlogTotalSentimentsKeyTwo) * 100;
   const BlogNegPercentageKeyTwo =
     (BlogNegKeyTwo / BlogTotalSentimentsKeyTwo) * 100;


  const dougnutMediaBg = [
    "#ff9326",
    "#ff0033",
    // "#38efc3",
    // "#ea738f",
    // "#34a853",
    // "#003087",
    // "#ff0033",
    // "#9911aa",
    // "#00000",
  ];

  // const dougnutMediaData = [secondEng, secondImpress];

  const mediaSource = [
    {
      socialHandle: "twitter",
      color: "red",
    },
    {
      socialHandle: "instagram",
      color: "yellow",
    },
    {
      socialHandle: "facebook",
      color: "blue",
    },
    {
      socialHandle: "youtube",
      color: "green",
    },
    {
      socialHandle: "linkedin",
      color: "purple",
    },
  ];

  const [barData, setBarData] = useState({
    labels: ["Facebook", "Twitter"],
    datasets: [
      {
        label: `Media Share for ${keywordTwo}`,
        data: [TotalFacebookMentionsKeyTwo, TotalTwitterMentionsKeyTwo],
        backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(255, 159, 64, 0.2)"],
        borderColor: ["rgb(255, 99, 132)", "rgb(255, 159, 64)"],
        borderWidth: 1,
      },
    ],
  });

  function handleHover(evt, item, legend) {
    legend.chart.data.datasets[0].backgroundColor.forEach(
      (color, index, colors) => {
        colors[index] =
          index === item.index || color.length === 9 ? color : color + "4D";
      }
    );
    legend.chart.update();
  }

  function handleLeave(evt, item, legend) {
    legend.chart.data.datasets[0].backgroundColor.forEach(
      (color, index, colors) => {
        colors[index] = color.length === 9 ? color.slice(0, -2) : color;
      }
    );
    legend.chart.update();
  }

  const barOptions = {
    plugins: {
      title: {
        // display: true,
        text: "Social Media Engagement Analysis",
      },
      legend: {
        position: "bottom",
        onHover: handleHover,
        onLeave: handleLeave,
      },
    },
    // responsive: true,
    // scales: {
    //   x: {
    //     stacked: true,
    //   },
    //   y: {
    //     stacked: true,
    //   },
    // },
  };

  // if (!secondImpress) {
  //   <p className='text-center'>loading...</p>;
  // }
  return (
    <div>
      <div
        className='flex justify-between border shadow-lg m-4 px-5 py-4 rounded-lg'
        style={{
          display: "flex",
        }}
      >
        {/* // new w-full */}
        {/* indomie  */}
        <div className='w-48'>
          <p className='text-lg font-bold mb-3 capitalize'>{keywordTwo}</p>

          <div className=''>
            <p className='text-sm'>Total Mentions</p>
            <p className='text-lg space-x-1'>
              {TotalMentionsKeyTwo || "No Data"}
            </p>
          </div>

          <div className='mt-2'>
            <p className='text-sm'>Positive Mentions</p>
            <p className='text-lg space-x-1 text-darkerGreen'>
              {TotalPositiveSentimentKeyTwo || "No Data"}
            </p>
          </div>

          <div className='mt-2'>
            <p className='text-sm'>Neutral Mentions</p>
            <p className='text-lg space-x-1 text-darkYellow'>
              {TotalNeutralSentimentKeyTwo || "No Data"}
            </p>
          </div>

          <div className='mt-2'>
            <p className='text-sm'>Negative Mentions</p>
            <p className='text-lg space-x-1 text-darkPink'>
              {TotalNegativeSentimentKeyTwo || "No Data"}
            </p>
          </div>
        </div>

        {/* media source  */}
        <div className='mx-10 w-95'>
          <p className='text-lg font-bold'>Media source</p>
          <div
            style={{
              display: "flex",
              // width: "100%",
              // height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Bar data={barData} options={barOptions} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecondComparedItem;
