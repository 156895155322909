import React from "react";
import {connect} from "react-redux"
import loader from './../components/asset/img/eclipse.svg'

// 
const ApiLoader = ({ loading }) => {
  //other logic
  const styles = {
    wraperr: {
      position: "fixed",
      left: "0",
      top: "0",
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0,0,0,.2)",
      zIndex:"2000"
    },
  };
  return (
    <>
      {loading.isLoading ? (
        <div style={styles.wraperr}>
          <img src={loader} alt="loader"/>
        </div>
      ) : null}
    </>
  );
};

function mapStateToProps(state) {
  const { loading } = state
  return { loading }
}

export default connect(mapStateToProps)(ApiLoader);
