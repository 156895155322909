import useAxios from "../../../utility/axios-token-manager/init";

export const addSocialAccount = async (info, media) => {
  try {
    const response = await useAxios.post(
      `/account/set-up/social/${media}/add`,
      info
    );
    const { data, status } = response;
    return { data, status };
  } catch (error) {
    const data = {
      message: error.message,
      success: false
    };
    return { data, status: 500 };
  }
};
export const addSocialAccountIG = async (pageId) => {
  try {
    const response = await useAxios.post(
      `/account/set-up/social/instagram/add`,
      { pageId }
    );
    const { data, status } = response;
    return { data, status };
  } catch (error) {
    const data = {
      message: error.message,
      success: false
    };
    return { data, status: 500 };
  }
};

export const postToFacebook = async (info, platform) => {
  const { id, message, mediaFile } = info;
  const formData = new FormData();
  formData.append("message", encodeURIComponent(message));
  formData.append("pageId", id);
  //
  formData.append("contentType", "photo");
  formData.append("image", mediaFile);
  try {
    const response = await useAxios.post(
      `/account/profile/social/${platform}/post`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    const { data, status } = response;
    return { data, status };
  } catch (error) {
    const data = {
      message: error.message,
      success: false
    };
    return { data, status: 500 };
  }
};

export const postToIG = async (inputs) => {
  const { id, message, platform, mediaFile } = inputs;
  const formData = new FormData();
  formData.append("caption", encodeURIComponent(message));
  formData.append("instagramPageId", id);
  //
  formData.append("mediaType", "photo");
  formData.append("image", mediaFile);
  try {
    const response = await useAxios.post(
      `/account/profile/social/${platform}/post`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    const { data, status } = response;
    return { data, status };
  } catch (error) {
    const data = {
      message: error.message,
      success: false
    };
    return { data, status: 500 };
  }
};

export const getFacebookPageInsight = async ({ pageId, media }) => {
  try {
    const response = await useAxios.post(
      `/account/profile/social/${media}/history/page`,
      { pageId: pageId }
    );
    const { pageStat, pageInfo } = response.data;
    const { data } = pageStat;
    return { data, pageInfo };
  } catch (error) {
    const data = {
      message: error.message,
      success: false
    };
    return { data, status: 500 };
  }
};

export const getIGPageInsight = async ({ pageId, media }) => {
  try {
    const response = await useAxios.post(
      `/account/profile/social/${media}/history/page`,
      { pageId: pageId }
    );
    const {
      dailyImpression,
      dailyReach,
      weeklyImpression,
      weeklyReach,
      pageInfo,
      monthlyReach,
      monthlyImpression,
    } = response.data;

    return {
      dailyImpression,
      dailyReach,
      weeklyImpression,
      weeklyReach,
      pageInfo,
      monthlyImpression,
      monthlyReach,
    };
  } catch (error) {
    const data = {
      message: error.message,
      success: false
    };
    return { data, status: 500 };
  }
};

export const getIGMediaInsight = async (data) => {
  // get media post analytic
  try {
    const response = await useAxios.get(
      `/account/profile/social/instagram/history/media/${data.postId}/${data.pageId}`
    );
    return response.data;
  } catch (error) {
    const data = {
      message: error.message,
      success: false
    };
    return { data, status: 500 };
  }
};
