import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import FilterComponent from './FilterComponent';
import { Button } from '@mui/material';

export const WebsiteDisplay = (props) => {
  const websiteColumns = [
    {
      name: 'Website',
      selector: (row) => row.website,
    },
    {
      name: 'Mentions',
      selector: (row) => row.mentions,
    },
    {
      name: 'Visits',
      selector: (row) => row.visits,
    },
    {
      name: 'Options',
      selector: (row) => row.options,
      width: '300px',
      cell: (row) => (
        <>
          <Button variant="outlined" size="small">
            options
          </Button>
        </>
      ),
    },
  ];

  const data = [
    {
      id: 1,
      website: 'www.google.com',
      mentions: '1,000,876',
      visits: '3,000,045',
      otions: '6000000:2500000:5000000',
    },
    {
      id: 2,
      website: 'www.bing.com',
      mentions: '23,934',
      visits: '1,000,045',
      options: '70:10:20',
    },
    {
      id: 3,
      website: 'www.yahoo.com',
      mentions: '200,000',
      visits: '3,000,202',
      options: '20:50:30',
    },
  ];

  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = data.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <DataTable
      columns={websiteColumns}
      data={filteredItems}
      defaultSortField="name"
      striped
      pagination
      highlightOnHover
      subHeader
      subHeaderComponent={subHeaderComponent}
      responsive
      subHeaderWrap
    />
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteDisplay);
