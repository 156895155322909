import useAxios from "../../utility/axios-token-manager/init";
import { instagramPagedata } from "../../utility/host";
import { loadStart, loadStop } from "./loading";

export const getUserProfile = () => async (dispatch) => {
  dispatch(loadStart());
  try {
    const response = await useAxios.get("/account/profile/get");
    const { profile, success, message } = response.data;
    // console.log("response", response.data);
    // console.log("profile", profile);
    if (response.data.success === true) {
      const { authProfiles } = profile;
      // console.log("authprofiles", authProfiles);
      const instagramInfo = localStorage.getItem(instagramPagedata);
      if (authProfiles?.instagram) {
        const newItem = JSON.stringify(
          authProfiles?.instagram[authProfiles?.instagram?.length - 1] || []
        );
        localStorage.setItem(instagramPagedata, newItem);
      }
      // instagramInfo === null && localStorage.setItem(instagramPagedata, newItem)
      // console.log(profile.email);
      localStorage.setItem("email", profile.email);
      dispatch(loadStop());

      return dispatch({
        type: "SET_PROFILE",
        payload: profile,
      });
    } else {
      // dispatch(loadStop());
      return dispatch({
        type: "SET_PROFILE",
        payload: {},
      });
    }
  } catch (error) {
    dispatch(loadStop());
    // const { status } = error.response;
    // console.log(error, "err status");
  }
};

// export const postToSocialMedias = (inputs) => async (dispatch)=>{

// }
