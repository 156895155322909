
export const loadStart=()=>{
    return{
        type:"START-LOADING"
    }
}
export const loadStop=()=>{
    return{
        type:"STOP-LOADING"
    }
}