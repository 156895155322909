import React from "react";
import { connect } from "react-redux";
import { Alert } from "@mui/material";
import "./../App.css";
import classNames from "classnames";
import { renderNotification } from "../redux/actions/notification";

function statusHelper(status) {
  switch (status) {
    case 200:
      return "success";
    case 201:
      return "success";
    case 400:
      return "error";
    case 401:
      return "error";
    case 500:
      return "error";
    default:
      return "info";
  }
}

//
const Notification = ({ notification, renderNotification }) => {

    // const render = React.useCallback((data)=>renderNotification,[])
  const className = classNames({
    "notification-container": true,
    "notification-container-show": notification?.show,
  });

  React.useEffect(() => {
    // console.log(notification);
    if (notification.show) {
      setTimeout(() => {
        renderNotification({ show: false, message: "", type: "" });
      }, 5000);
    }
  }, [notification, renderNotification]);

  return (
    <div className={className}>
      <Alert className="notification-box" severity={statusHelper(notification.type)}>
        {notification?.message}
      </Alert>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    notification: state.notification,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    renderNotification: (data) => dispatch(renderNotification(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
