/* eslint-disable no-redeclare */
import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { connect, useDispatch } from "react-redux";
import { searchKeyWord } from "../../../../redux/actions/modals";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TextField } from "@mui/material";
import languages from "../../../../utility/landuages";
import { ButtonCta } from "../../component/buttons/ButtonComponent";
import useAxios from "../../../../utility/axios-token-manager/init";
import { renderNotification } from "../../../../redux/actions/notification";
import { loadStart, loadStop } from "../../../../redux/actions/loading";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import Mentions from "../../../pages/dashboard/mentions/Index";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { instagramPagedata } from "../../../../utility/host";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxWidth: "90vw",
  bgcolor: "background.paper",
  boxShadow: 15,
  borderRadius: "5px",
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

function SearchKeywordModal({ open, keywordOne }) {
  //   const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const [addLang, setAddLang] = React.useState(false);
  const [lang, setLang] = React.useState("");
  const [keyword, setKeyword] = React.useState("");
  const handleLanguage = (event) => {
    setLang(event.target.value);
  };
  const [instagramInfo, setinstagramInfo] = useState(null);
  const [mentionData, setMentionData] = React.useState([]);
  const [crawledItems, setCrawledItems] = useState([]);
  const [startDate, setStartDate] = useState("empty");
  const [endDate, setEndDate] = useState("empty");
  const [twitterKeyword, setTwitterKeyword] = useState(false);
  const [twitterHashtag, setTwitterHashtag] = useState(false);
  const [blogKeyword, setBlogKeyword] = useState(false);
  const [facebookKeyword, setFacebookKeyword] = useState(false);
  const [instagramKeyword, setInstagramKeyword] = useState(false);

  const storageKeyword = localStorage.getItem("searchedTerm");

  // console.log("keywordOne", keywordOne);
  // console.log("storageKeyword", storageKeyword);

  const handleClose = () => {
    //
    // setLang("");
    dispatch(searchKeyWord(false));
  };

  useEffect(() => {
    localStorage.setItem("crawledItems", JSON.stringify(crawledItems));
    var searchedTerm = localStorage.getItem("searchedTerm");
    if (searchedTerm) {
      setKeyword(searchedTerm);
    }
    const instagramInfo = JSON.parse(localStorage.getItem(instagramPagedata));
    setinstagramInfo(instagramInfo);
  }, [keyword, crawledItems]);

  // console.log("keyword", keyword);

  const keywordSearch = async () => {
    const language = lang || "en";
    const location = "nigeria";
    // const startDate = req.body.startDate;
    // const endDate = req.body.endDate;

    try {
      if (startDate && endDate === "empty") {
        alert("Please select a Start and End Date");
      } else {
        if (
          twitterKeyword === false &&
          twitterHashtag === false &&
          facebookKeyword === false &&
          blogKeyword === false &&
          instagramKeyword === false
        ) {
          alert("Please select at least one platform");
        } else {
          dispatch(loadStart());
          const formattedStorageKeyword = storageKeyword.replace(/[#\s]/g, "");
          const twitterHashtagSearch = twitterHashtag
            ? await axios.post(
                `${process.env.REACT_APP_BRANDTEON}/twitter/tweet-hashtag?search=${formattedStorageKeyword}&location=${location}`
              )
            : [];
          // console.log("twitterHashtagSearch", twitterHashtagSearch);
          const twitterKeywordSearch = twitterKeyword
            ? await axios.post(
                `${process.env.REACT_APP_BRANDTEON}/twitter/search_word?search=${formattedStorageKeyword}&location=${location}&language=${language}`
              )
            : [];
          // console.log("twitterKeywordSearch", twitterKeywordSearch);
          // console.log("facebookKeyword", facebookKeyword);
          const facebookKeywordSearch = facebookKeyword
            ? await axios.post(
                `${process.env.REACT_APP_BRANDTEON}/facebook/post?search_name=${formattedStorageKeyword}`
              )
            : [];
          // console.log("facebookKeywordSearch", facebookKeywordSearch);
          const blogKeywordSearch = blogKeyword
            ? await axios.post(
                `${process.env.REACT_APP_BRANDTEON}/blog?search=${formattedStorageKeyword}&location=${location}&language=${language}`
              )
            : [];
          // console.log("blogKeywordSearch", blogKeywordSearch);
          // console.log("blogKeywordSearch", instagramInfo);
          const { instagramPageId, access_token } = instagramInfo;
          // console.log("instagramKeyword", instagramKeyword);
          // console.log("instagramPageId", instagramPageId);
          // console.log("access_token", access_token);
          // const instStorageKeyword = storageKeyword.replace(/[#\s]/g, "");
          const instagramKeywordSearch = instagramKeyword
            ? await axios.post(
                `${
                  process.env.REACT_APP_BRANDTEON
                }/instagram/hashtag?search_name=${encodeURIComponent(
                  formattedStorageKeyword
                )}&access_token=${access_token}&instagram_id=${instagramPageId}
              //     storageKeyword
              //     .split(" ")
              //     .join(
              //       ""
              // )}&access_token=${access_token}&instagram_id=${instagramPageId}
                  `
              )
            : [];
          // console.log("instagramKeywordSearch", instagramKeywordSearch);
          var twitterHashtagSrc = twitterHashtagSearch.data || [];
          // var twitterHashtagSrc = [];
          var facebookKeywordSrc = facebookKeywordSearch.data || [];
          // var facebookKeywordSrc = [];
          var blogKeywordSrc = blogKeywordSearch.data || [];
          // var blogKeywordSrc =  [];
          var twitterKeywordSrc = twitterKeywordSearch.data || [];
          // var twitterKeywordSrc =  [];
          var instagramKeywordSrc = instagramKeywordSearch.data || [];
          // var instagramKeywordSrc = [];
          var combinedData = [
            twitterHashtagSrc,
            facebookKeywordSrc,
            blogKeywordSrc,
            twitterKeywordSrc,
            instagramKeywordSrc,
          ];
          localStorage.setItem("combinedData", JSON.stringify(combinedData));
          dispatch({ type: "ADD_SENTIMENT", payload: combinedData });
          dispatch({
            type: "SELECTED_KEYWORD",
            payload: keyword,
          });
          // console.log('combinedData',combinedData)
          setCrawledItems(combinedData);
          handleClose();
          dispatch(loadStop());
          dispatch(
            renderNotification({
              show: true,
              message: "Loaded successfully",
            })
          );
        }
      }
    } catch (error) {
      // console.log("error", error);
      dispatch(loadStop());
      return dispatch(
        renderNotification({
          show: true,
          message: "Keyword Not Gotten",
        })
      );
    }
  };

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Select Platforms to search for {storageKeyword} Keyword!
          </Typography>
          <div
            className='m-4'
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              alignContent: "center",
              width: "100%",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label='From'
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(dayjs(newValue).format("YYYY-MM-DD"));
                }}
                renderInput={(params) => <TextField {...params} />}
              />
              <DatePicker
                label='To'
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(dayjs(newValue).format("YYYY-MM-DD"));
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          {/* <Typography id='modal-modal-description'>
            What would you like to search for, a business, an hashtag or your
            competitors?
          </Typography> */}
          <span
            onClick={() => handleClose()}
            style={{
              position: "absolute",
              top: "20px",
              right: "20px",
              cursor: "pointer",
            }}
          >
            X
          </span>
          {/* <br /> */}
          {/* <div
            style={{
              width: "100%",
            }}
          >
            <TextField
              label='Enter Keyword'
              placeholder='e.g Aliko'
              fullWidth
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
          </div> */}
          <div
            style={{
              width: "100%",
              paddingTop: "20px",
            }}
          >
            {/* <FormGroup>
              <FormControlLabel
                control={<Switch checked={addLang} color='primary' />}
                label='Language'
                onChange={(e) => setAddLang(e.target.checked)}
              />
            </FormGroup> */}
            {/* <div style={{ marginTop: "10px" }} /> */}
            {/* <div style={{ marginTop: "10px" }} /> */}
            {/* {addLang && (
              <FormControl sx={{ minWidth: 120, width: "100%" }}>
                <Select
                  value={lang}
                  onChange={handleLanguage}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  fullWidth
                  variant='outlined'
                  native
                >
                  <option value=''>All languages</option>
                  {languages.map((lan, i) => (
                    <option key={i} value={lan.code}>
                      {lan.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )} */}
            <div style={{ marginTop: "10px" }}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  <Typography>Select Parameters</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl component='fieldset'>
                    <FormGroup aria-label='position' row>
                      <FormControlLabel
                        value='Twitter Hashtag'
                        control={<Checkbox />}
                        onChange={(e) => setTwitterHashtag(e.target.checked)}
                        label='Twitter Hashtag'
                        labelPlacement='end'
                      />
                      <FormControlLabel
                        value='Twitter Keyword'
                        control={<Checkbox />}
                        onChange={(e) => setTwitterKeyword(e.target.checked)}
                        label='Twitter Keyword'
                        labelPlacement='end'
                      />
                      <FormControlLabel
                        value='Facebook Keyword'
                        control={<Checkbox />}
                        onChange={(e) => setFacebookKeyword(e.target.checked)}
                        label='Facebook Keyword'
                        labelPlacement='end'
                      />
                      <FormControlLabel
                        value='Blog Keyword'
                        control={<Checkbox />}
                        onChange={(e) => setBlogKeyword(e.target.checked)}
                        label='Blog Keyword'
                        labelPlacement='end'
                      />
                      <FormControlLabel
                        value='Instagram Keyword'
                        control={<Checkbox />}
                        onChange={(e) => setInstagramKeyword(e.target.checked)}
                        label='Instagram Keyword'
                        labelPlacement='end'
                      />
                    </FormGroup>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          {/* <br /> */}
          {/* <br /> */}
          <br />
          <ButtonCta
            className='btn-yellow-bg'
            color='inherit'
            text='Search Keyword'
            // open={!open}
            onClick={() => {
              // handleClose();
              // localStorage.setItem("searchedTerm", keyword);
              keywordSearch();
            }}
            // onClick={handleSubmitKeyword}
            // disabled={lang.trim().length < 2}
          />
        </Box>
      </Modal>
      {/* <Mentions data={mentionData} /> */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    open: state.modals.searchKeyWord,
    keywordOne: state?.sentiment?.selectedKeyWord,
  };
};

export default connect(mapStateToProps)(SearchKeywordModal);
