import React, { useState, Component, Fragment, useRef } from "react";
import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Paper,
} from "@mui/material";
import { connect, useDispatch, useSelector } from "react-redux";
import testPhoto from "../../../../asset/img/feather.svg";
import testPhoto2 from "../../../../asset/img/fi_smile.svg";
import testPhoto3 from "../../../../asset/img/brandteon_black_bg.png";
import {
  SearchwordTitleDiv,
  KeywordTitle,
  Charts,
  SentimentCharts,
  FacebookSentiment,
  FacebookEngagement,
  BlogSentiment,
  DataDiv,
  MentionShare,
  MentionShareInner,
  TotalFacebookShares,
  TotalFacebookLikes,
  TotalFacebookComments,
  BlogMentionsShare,
  ShareTitle,
  ShareContent,
  SentimentTitle,
  Engagements,
  EngagementsTitle,
} from "./style/facebookReportStyle";
import { Bar, Pie, Line, Doughnut } from "react-chartjs-2";
// import { Chart } from "chart.js/auto";
import AnimatedNumber from "react-animated-number";
import SocialEngagements from "../../mentions/charts/socialEngagements";
import ButtonComponent from "../../../../Button/Button";
import Chart from "react-apexcharts";
import dayjs from "dayjs";
import ReactToPrint from "react-to-print";

export const AllReports = (props) => {
  // const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const sentiment = selector.sentiment;
  const sentimentData = sentiment.sentimentData;
  const sentimentKeyword = sentiment.selectedKeyWord;

  const currentDate = new Date();
  const today = currentDate.getDate();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();
  const newDate = new Date(currentYear, 0, 0).getDate();
  var now = dayjs().format("DD MMM YYYY");

  // blogSearchData
  const blogSearchword = sentimentData[2] || [];
  const blogSearchwordResult = blogSearchword?.result || [];
  const blogSearchwordSentimentCount = blogSearchword?.sentiment_count || {};
  const blogSearchwordSentimentCountPosts =
    blogSearchwordSentimentCount.sentiment || {};
  const {
    NEG: BlogNeg,
    NEU: BlogNeu,
    POS: BlogPos,
  } = blogSearchwordSentimentCountPosts || {};

  const blogSentDoughSeries = [BlogNeg || 2, BlogNeu || 5, BlogPos || 8];
  const blogSentDoughOptions = {
    chart: {
      width: 280,
      type: "donut",
    },
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
            },
          },
        },
      },
    },
    labels: ["Positive", "Neutral", "Negative"],
    dataLabels: {
      dropShadow: {
        blur: 3,
        opacity: 0.8,
      },
    },
    states: {
      hover: {
        filter: {
          type: "lighten",
          value: 0.15,
        },
      },
    },
    theme: {
      palette: "palette2",
    },
    title: {
      text: "Blogs Sentiment",
      align: "center",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    colors: ["#38EFC3", "#F2C94C", "#EA738F"],
  };

  // console.log("blogSearchword", blogSearchword);

  const BlogTotalSentiments = BlogNeg + BlogNeu + BlogPos;
  const BlogPosPercentage = (BlogPos / BlogTotalSentiments) * 100;
  const BlogNeuPercentage = (BlogNeu / BlogTotalSentiments) * 100;
  const BlogNegPercentage = (BlogNeg / BlogTotalSentiments) * 100;

  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  const handleAfterPrint = React.useCallback(() => {
    // console.log("`onAfterPrint` called");
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    // console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    // console.log("`onBeforeGetContent` called");
    setLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  React.useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, text]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <ButtonComponent>Print</ButtonComponent>;
  }, []);

  return (
    <Box>
      <div>
        <Paper elevation={2} sx={{ p: 1, mt: 2 }}>
          <SearchwordTitleDiv
            className='sentimentTitle'
            style={{
              display: "flex",
              padding: "15px",
              justifyContent: "center",
            }}
          >
            <KeywordTitle>
              Blogs Analysis based on{" "}
              <span style={{ color: "#ff9326" }}>
                {sentimentKeyword || ""}{" "}
              </span>
              keyword!
            </KeywordTitle>
          </SearchwordTitleDiv>
          <Charts className='charts'>
            {/* <MentionShare className='mentionsShare'>
              <MentionShareInner
                className='mentionShareInner'
                style={{ display: "flex", flexDirection: "row" }}
              >
                <TotalFacebookShares className='TotalFacebookShares'>
                  <ShareTitle>Total Shares</ShareTitle>
                  <ShareContent
                    // component='text'
                    value={faceShares}
                    duration={300}
                    formatValue={(n) => n.toFixed(0)}
                  />
                </TotalFacebookShares>
                <TotalFacebookLikes className='TotalFacebookLikes'>
                  <ShareTitle>Total Likes</ShareTitle>
                  <ShareContent
                    // component='text'
                    value={faceLikes}
                    duration={300}
                    formatValue={(n) => n.toFixed(0)}
                  />
                </TotalFacebookLikes>
                <TotalFacebookComments className='TotalFacebookComments'>
                  <ShareTitle>Total Comments</ShareTitle>
                  <ShareContent
                    // component='text'
                    value={faceComments}
                    duration={300}
                    formatValue={(n) => n.toFixed(0)}
                  />
                </TotalFacebookComments>
              </MentionShareInner>
            </MentionShare> */}
            <div
              className='charts-inner'
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "20px",
                alignItems: "center",
                width: "100%",
              }}
            >
              <SentimentCharts className='sentimentCharts'>
                <FacebookSentiment className='facebookSentiment'>
                  <Chart
                    series={blogSentDoughSeries}
                    options={blogSentDoughOptions}
                    type='donut'
                    width='350'
                  />
                </FacebookSentiment>
              </SentimentCharts>
            </div>
          </Charts>
        </Paper>
      </div>
    </Box>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AllReports);
