import React,{useState} from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import './buttons.css'


export const ButtonLink = ({ text, to, className ,hover}) => {
  const [css, setCss] = useState("#020F54");

  return (
    <Link to={to} className={className + ""}>
      <Button
        variant='primary'
        onMouseOver={() => setCss(hover)}
        onMouseOut={() => setCss("#020F54")}
        style={{ textTransform: "none", color: css }}
      >
        {text}
      </Button>
    </Link>
  );
};

export const ButtonCta = ({text, onClick, className, type, fullWidth, disabled})=>{
  return (
    <div className={className}>
      <Button disabled={disabled} type={type} fullWidth={fullWidth} style={{textTransform:'none', color:"#171717"}} onClick={onClick}>{text}</Button>
    </div>
  );
}
