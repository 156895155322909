import React, { useState, useContext, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import axios from "axios";
import filter from "../../../asset/img/filter.png";
// import ActivityChart from "./BarChart/ActivityChart";
// import BarChart from "./BarChart/BarChart";
import Feeds from "./Feeds/Feeds";
import SocialMediaInsight from "./SocialMediaInsight";
import useAxios from "../../../../utility/axios-token-manager/init";
import { getUserProfile } from "../../../../redux/actions/profile";
import { loadStart, loadStop } from "../../../../redux/actions/loading";
import { renderNotification } from "../../../../redux/actions/notification";
import Store from "../../../../redux/configureStore/index";
import Pagination from "./Pagination";

import { Bar, Pie, Line, Doughnut } from "react-chartjs-2";
import { Chart } from "chart.js/auto";

import {
  Box,
  TextField,
  Button,
  CircularProgress,
  Typography,
  FormLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import dayjs from "dayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  KeywordDiv,
  KeywordInnerDiv,
  ServicesDiv,
  ServicesInnerDiv,
  Service,
  ServiceIcon,
  ServiceName,
  ServiceCount,
  KeywordItem,
  ItemDiv,
  InnerDiv,
  ProfileDiv,
  Content,
  ContentTop,
  ContentTopLeft,
  ProfileName,
  ContentTopRight,
  ContentMiddle,
  ContentMiddleText,
  ContentBottom,
  ContentBottomLeft,
  SentimentColorGreen,
  SentimentColorRed,
  SentimentColorBlue,
  Charts,
  SentimentCharts,
  FacebookSentiment,
  TwitterSentiment,
  BlogSentiment,
  ProfileImage,
  DateDiv,
  TimeDiv,
  MentionsList,
  MentionsListInnerDiv,
} from "./style/MentionsStyle";
import {
  AiOutlineFacebook,
  AiOutlineTwitter,
  AiOutlineInstagram,
} from "react-icons/ai";
import { BsGlobe, BsRssFill } from "react-icons/bs";
import { FacebookProvider, useProfile, Page } from "react-facebook";

import placeholder from "../../../asset/img/placeholder.jfif";
import compare from "../../../asset/img/compare.svg";

import Filtering from "./filterData";
import { client, markedItemData } from "./data";
import ClientDetail from "./ClientDetail";
import ButtonComponent from "../../../Button/Button";
import { instagramPagedata } from "../../../../utility/host";
import SearchKeyword from "../../../asset/component/modals/SearchKeyword";
import { searchKeyWord } from "../../../../redux/actions/modals";

const Services = [
  {
    name: "All",
    value: "all",
    color: "#616060;",
    icon: <BsGlobe style={{ height: "50px", width: "50px" }} />,
  },
  {
    name: "Facebook",
    color: "#3b5998",
    icon: (
      <AiOutlineFacebook
        style={{ height: "50px", width: "50px", color: "#4267B2" }}
      />
    ),
  },
  {
    name: "Twitter",
    color: "#1da1f2",
    icon: (
      <AiOutlineTwitter
        style={{ height: "50px", width: "50px", color: "#1DA1F2" }}
      />
    ),
  },
  {
    name: "Blog",
    color: "#ff5722",
    icon: (
      <BsRssFill style={{ height: "50px", width: "50px", color: "#ee802f" }} />
    ),
  },
  {
    name: "Instagram",
    color: "#e1306c",
    icon: (
      <AiOutlineInstagram
        style={{
          height: "50px",
          width: "50px",
          borderRadius: "20%",
          color: "#fff",
          background:
            "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",
          boxShadow: "0px 3px 10px rgba(0,0,0,.25)",
        }}
      />
    ),
  },
];

export const Index = ({ profile, data, keywordOne }) => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const sentiment = selector.sentiment;
  const sentimentData = sentiment.sentimentData;
  const sentimentKeyword = sentiment.selectedKeyWord;
  const token = selector.auth.token;

  // const { state, dispatch: ctxDispatch } = useContext(Store);
  // const { userInfo } = state;

  // console.log(profile.keywords[0])
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [queryResult, setQueryResult] = useState(data || sentimentData);
  const [clickedKeyword, setClickedKeyword] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [language, setLanguage] = useState("en");
  const [location, setLocation] = useState("nigeria");
  const [startDate, setStartDate] = useState("empty");
  const [endDate, setEndDate] = useState("empty");
  const [histories, sethistories] = useState([]);
  const [instagramInfo, setinstagramInfo] = useState(null);
  const profileKeywords = profile?.keywords;

  // console.log("histories", histories);

  useEffect(() => {
    const crawledItems = JSON.parse(localStorage.getItem("combinedData"));
    const instagramInfo = localStorage.getItem(instagramPagedata);
    // console.log('instagramInfo',instagramInfo)
    instagramInfo === (null || undefined)
      ? setinstagramInfo({})
      : setinstagramInfo(JSON.parse(instagramInfo));
    // console.log(crawledItems);
    // console.log('instagramInfo after if',instagramInfo)
    if ((sentimentData.length && queryResult.length) === 0) {
      setQueryResult(crawledItems);
      dispatch({ type: "ADD_SENTIMENT", payload: crawledItems });
    }
    // let combinedData = localStorage.getItem("combinedData", JSON.stringify(data));
    // genData.push(combinedData)
    // console.log(genData);
    // dispatch({ type: "ADD_SENTIMENT", payload: combinedData });
    // setQueryResult(combinedData);
  }, [sentimentData, dispatch, queryResult]);

  const [twitterData, setTwitterData] = useState("empty");
  // const { profile: profData, isLoading, error } = useProfile(["id", "name"]);

  // console.log(`profile: ${profData}`);
  // console.log(`profileName: ${profData.name}`);
  // console.log(`profileId: ${profData.id}`);

  // twitterHashtagData
  const twitterHashtag = sentimentData[0] || queryResult[0];
  var twitterHashtagResult = twitterHashtag?.result || [];
  var twitterHashtagTotalEngagement = twitterHashtag?.Engagement_total || [];
  var twitterHashtagTotalImpression = twitterHashtag?.Impression_total || [];
  var twitterHashtagTotalSentiment = twitterHashtag?.Total_sentiment || [];
  var twitterHashtagEngagementAnalysis =
    twitterHashtag?.engagement_analysis || [];
  var { sentiment: twitterHashtagSentimentCount } =
    twitterHashtag?.sentiment_count || {};
  var {
    NEG: twitterNeg,
    NEU: twitterNeu,
    POS: twitterPos,
  } = twitterHashtagSentimentCount || {};

  // facebookSearchData
  const facebookKeyword = sentimentData[1] || queryResult[1];
  const facebookKeywordResult = facebookKeyword?.result || [];
  const facebookKeywordTotalSentiment = facebookKeyword?.Total_sentiment || [];
  const facebookKeywordTotalEngagement =
    facebookKeyword?.Total_engagement || [];
  const { Total_engagement: facebookTotalEngagement } =
    facebookKeywordTotalEngagement[0] || {};
  const facebookKeywordSentimentCount = facebookKeyword?.sentiment_count || {};
  const facebookKeywordSentimentCountPosts =
    facebookKeywordSentimentCount.posts_sentiment || {};
  const {
    NEG: FacebookNeg,
    NEU: FacebookNeu,
    POS: FacebookPos,
  } = facebookKeywordSentimentCountPosts || {};

  // blogSearchData
  const blogSearchword = sentimentData[2] || queryResult[2];
  const blogSearchwordResult = blogSearchword?.result || [];
  const blogSearchwordSentimentCount = blogSearchword?.sentiment_count || {};
  const blogSearchwordSentimentCountPosts =
    blogSearchwordSentimentCount.sentiment || {};
  const {
    NEG: BlogNeg,
    NEU: BlogNeu,
    POS: BlogPos,
  } = blogSearchwordSentimentCountPosts || {};

  // twitterSearchData
  const twitterKeyword = sentimentData[3] || queryResult[3];
  var twitterKeywordResult = twitterKeyword?.result || [];
  var twitterKeywordTotalEngagement = twitterKeyword?.Engagement_total || [];
  var twitterKeywordTotalImpression = twitterKeyword?.Impression_total || [];
  var twitterKeywordTotalSentiment = twitterKeyword?.Total_sentiment || [];
  var twitterKeywordEngagementAnalysis =
    twitterKeyword?.engagement_analysis || [];
  var { sentiment: twitterKeywordSentimentCount } =
    twitterKeyword?.sentiment_count || {};
  var {
    NEG: twitterSrcNeg,
    NEU: twitterSrcNeu,
    POS: twitterSrcPos,
  } = twitterKeywordSentimentCount || {};

  // instagramSearchData
  const instagramKeyword = sentimentData[4] || queryResult[4];
  var instagramKeywordResult = instagramKeyword?.result || [];
  var instagramKeywordTotalEngagement =
    instagramKeyword?.Total_engagement || [];
  var instagramKetwordTotalSentiment = instagramKeyword?.Total_sentiment || [];
  var { sentiment: instagramKeywordSentimentCount } =
    instagramKeyword?.sentiment_count || {};
  var {
    NEG: instagramSrcNeg,
    NEU: instagramSrcNeu,
    POS: instagramSrcPos,
  } = instagramKeywordSentimentCount || {};

  // console.log("instagramKeywordResult", instagramKeywordResult);
  const genTwitterData = [...twitterHashtagResult, ...twitterKeywordResult];
  const genTwitterCount =
    twitterHashtagResult.count + twitterKeywordResult.count;

  var twitlikes = 0;
  var twitcomments = 0;
  var twitRetweets = 0;
  const twitterLikes = twitterHashtagResult.map((item) => {
    return (twitlikes += item.Likes);
  });
  const twitterComments = twitterHashtagResult.map((item) => {
    return (twitcomments += item.Reply_count);
  });
  const twitterRetweets = twitterHashtagResult.map((item) => {
    return (twitRetweets += item.Retweet_count);
  });

  var faceLikes = 0;
  var faceComments = 0;
  var faceShares = 0;
  const facebookLikes = facebookKeywordResult.map((item) => {
    return (faceLikes += item.likes_no);
  });
  const facebookComments = facebookKeywordResult.map((item) => {
    return (faceComments += item.comments_no);
  });
  const facebookShares = facebookKeywordResult.map((item) => {
    return (faceShares += item.shares_no);
  });

  const keywordChain = { q: searchTerm };

  // const handleSearch = async (proKey) => {
  //   // setLoading(true);
  //   // if (keyword !== "") {
  //   if (startDate && endDate === "empty") {
  //     alert("Please select a Start and End Date");
  //   } else {
  //     dispatch(loadStart());
  //     const response = await useAxios.post(`account/set-up/keywords/fetch`, {
  //       keyword: proKey.keyword,
  //       location,
  //       language,
  //       startDate,
  //       endDate,
  //     });
  //     // response.setHeader("Access-Control-Allow-Origin", "*");
  //     const { status } = response;
  //     // console.log(response);

  //     // console.log(status, data);
  //     if (status === 200) {
  //       dispatch(loadStop());
  //       dispatch(
  //         renderNotification({
  //           show: true,
  //           message: "Loaded successfully",
  //           type: status,
  //         })
  //       );
  //       dispatch({ type: "ADD_SENTIMENT", payload: response.data });
  //       setQueryResult(response.data);
  //       // console.log(response.data);
  //     } else {
  //       dispatch(loadStop());
  //       return dispatch(
  //         renderNotification({
  //           show: true,
  //           message: "Keyword Not Gotten",
  //           type: status,
  //         })
  //       );
  //     }
  //   }
  // };

  const posts = [
    ...twitterHashtagResult,
    ...facebookKeywordResult,
    ...blogSearchwordResult,
  ];
  // console.log(posts);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);

  //  Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
  const totalPosts = posts.length;

  const date = new Date();

  const [twitterHashtagResulting, setTwitterHashtagResulting] = useState([]);
  const [facebookKeywordResulting, setFacebookKeywordResulting] = useState([]);
  const [blogSearchwordResulting, setBlogSearchwordResulting] = useState([]);
  const [twitterKeywordResulting, setTwitterKeywordResulting] = useState([]);

  const keywordSearch = async (proKey) => {
    const keyword = proKey.keyword;
    // console.log("Keywporf", keyword);
    if (instagramInfo === null)
      return dispatch(
        renderNotification({
          show: true,
          message: "Please add an instagram account",
        })
      );
    // const location = "nigeria" || req.body.location;
    // const language = "en" || req.body.language;
    // const startDate = req.body.startDate;
    // const endDate = req.body.endDate;

    // if (startDate && endDate === "empty") {
    //   alert("Please select a Start and End Date");
    //   // dispatch(loadStop());
    // } else {
    try {
      dispatch(loadStart());
      const twitterHashtagSearch = await axios.post(
        `${process.env.REACT_APP_BRANDTEON}/twitter/tweet-hashtag?search=${keyword}&location=${location}&since_date=${startDate}&until_date=${endDate}`
      );
      // console.log(twitterHashtagSearch.data);
      const facebookKeywordSearch = await axios.post(
        `${process.env.REACT_APP_BRANDTEON}/facebook/post?search_name=${keyword}`
      );
      // console.log(facebookKeywordSearch.data);
      const blogKeywordSearch = await axios.post(
        `${process.env.REACT_APP_BRANDTEON}/blog?search=${keyword}&location=${location}`
      );
      // console.log(blogKeywordSearch.data);
      const twitterKeywordSearch = await axios.post(
        `${process.env.REACT_APP_BRANDTEON}/twitter/search_word?search=${keyword}&location=${location}&language=${language}&since_date=${startDate}&until_date=${endDate}`
      );
      // console.log(twitterKeywordSearch.data);
      const { instagramPageId, access_token } = instagramInfo;
      const instagramKeywordSearch = await axios.post(
        `${
          process.env.REACT_APP_BRANDTEON
        }/instagram/hashtag?search_name=${keyword
          .split(" ")
          .join(
            ""
          )}&access_token=${access_token}&instagram_id=${instagramPageId}`
      );
      // console.log(instagramKeywordSearch.data);
      if (
        (twitterHashtagSearch.status &&
          facebookKeywordSearch.status &&
          blogKeywordSearch.status &&
          twitterKeywordSearch.status &&
          instagramKeywordSearch.status) === 200
      ) {
        setTwitterHashtagResulting(twitterHashtagSearch.data);
        // dispatch(loadStop());
        await searchHistoryData(keyword);
        dispatch(
          renderNotification({
            show: true,
            message: "Loaded successfully",
          })
        );
        var twitterHashtagSrc = twitterHashtagSearch.data || [];
        // var twitterHashtagSrc = [];
        var facebookKeywordSrc = facebookKeywordSearch.data || [];
        // var facebookKeywordSrc = [];
        var blogKeywordSrc = blogKeywordSearch.data || [];
        // var blogKeywordSrc = [];
        var twitterKeywordSrc = twitterKeywordSearch.data || [];
        // var twitterKeywordSrc = [];
        var instagramKeywordSrc = instagramKeywordSearch.data || [];
        // var instagramKeywordSrc = [];
        var combinedData = [
          twitterHashtagSrc,
          facebookKeywordSrc,
          blogKeywordSrc,
          twitterKeywordSrc,
          instagramKeywordSrc,
        ];
        localStorage.setItem("combinedData", JSON.stringify(combinedData));
        dispatch({ type: "ADD_SENTIMENT", payload: combinedData });
        setQueryResult(combinedData);
        // console.log(combinedData);
      }
    } catch {
      dispatch(loadStop());
      return dispatch(
        renderNotification({
          show: true,
          message: "Keyword Not Gotten",
        })
      );
    }
    // }
  };

  const TotalMentions =
    twitterHashtagResult.length +
    facebookKeywordResult.length +
    blogSearchwordResult.length +
    twitterKeywordResult.length +
    instagramKeywordResult.length;
  const TotalTwitterMentions =
    twitterHashtagResult.length + twitterKeywordResult.length;

  const [value, setValue] = useState("All");

  // console.log(value);

  const handleRadioChange = (event) => {
    setValue(event.target.value);
    // setHelperText(" ");
    // setError(false);
  };

  const compare = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const bandA = a.time;
    const bandB = b.time;
    if (bandA > bandB) {
      return 1;
    } else if (bandA < bandB) {
      return -1;
    }
    return 0;
  };

  const getHistoryData = async () => {
    try {
      dispatch(loadStart());
      const result = await useAxios.get("/account/search-history");
      const { data, success, message } = await result.data;
      if (success === true) {
        data.searches.sort((a, b) => {
          return new Date(b.time) - new Date(a.time);
        });
        sethistories(data.searches);
        dispatch(loadStop());
      } else if (message === "No history found") {
        dispatch(loadStop());
        dispatch(
          renderNotification({
            show: true,
            message: message || "Something went wrong",
            type: 205,
          })
        );
      } else {
        dispatch(loadStop());
        dispatch(
          renderNotification({
            show: true,
            message: message || "Something went wrong",
            type: 205,
          })
        );
      }
    } catch (error) {
      dispatch(loadStop());
      dispatch(
        renderNotification({
          show: true,
          message: error.message || "Error Occured",
          type: 500,
        })
      );
    }
  };

  const searchHistoryData = async (query) => {
    try {
      const result = await useAxios.post("/account/search-history", { query });
      const { data, success, message } = await result.data;
      if (success === true) {
        getHistoryData();
      } else {
        dispatch(loadStop());
        dispatch(
          renderNotification({
            show: true,
            message: message || "Something went wrong",
            type: 205,
          })
        );
      }
    } catch (error) {
      dispatch(loadStop());
      dispatch(
        renderNotification({
          show: true,
          message: error.message || "Error Occured",
          type: 500,
        })
      );
    }
  };

  useEffect(() => {
    getHistoryData();
  }, []);

  // console.log("instagramKeywordResult", instagramKeywordResult);
  // console.log("profileKeywords", profileKeywords);

  return (
    <section className=''>
      {/* <div
        className='m-4'
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          alignContent: "center",
          // width: "70%",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label='From'
            value={startDate}
            onChange={(newValue) => {
              setStartDate(dayjs(newValue).format("YYYY-MM-DD"));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          <DatePicker
            label='To'
            value={endDate}
            onChange={(newValue) => {
              setEndDate(dayjs(newValue).format("YYYY-MM-DD"));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </div> */}
      <div className='m-4'>
        <KeywordDiv>
          <KeywordInnerDiv>
            {profileKeywords?.length < 1 ? (
              <div>Please Create New Keyword</div>
            ) : (
              profileKeywords?.map((proKey, index) => {
                return (
                  <KeywordItem
                    className={
                      clickedKeyword && proKey.keyword === keyword
                        ? "border-none rounded-sm p-2 m-2 clicked"
                        : "border-none rounded-sm p-2 m-2"
                    }
                    key={index}
                    // value={proKey.keyword}
                    onClick={() => {
                      // dispatch({
                      //   type: "SELECTED_KEYWORD",
                      //   payload: proKey.keyword,
                      // });
                      dispatch(searchKeyWord(true));
                      // setQueryResult([]);
                      // setClickedKeyword(true);
                      // setKeyword(proKey.keyword);
                      // setLanguage(proKey.lang);

                      localStorage.setItem("searchedTerm", proKey.keyword);
                      // handleSearch(proKey);
                      // fetchTwitterData();
                      // keywordSearch(proKey);
                    }}
                  >
                    <h1>{proKey.keyword}</h1>
                  </KeywordItem>
                );
              })
            )}
          </KeywordInnerDiv>
        </KeywordDiv>
        {/* <br /> */}
        {/* <Filtering/> */}
        <br />
        <ServicesDiv>
          <ServicesInnerDiv>
            <FormControl component='fieldset' style={{ width: "100%" }}>
              <RadioGroup
                aria-label='position'
                value={value}
                style={{ display: "flex", justifyContent: "space-around" }}
                row
              >
                <FormControlLabel
                  value='All'
                  onChange={handleRadioChange}
                  control={<Radio />}
                  label={
                    <Service>
                      <ServiceIcon>{Services[0].icon}</ServiceIcon>
                      <ServiceName>{Services[0].name}</ServiceName>
                      <ServiceCount>{TotalMentions}</ServiceCount>
                    </Service>
                  }
                  labelPlacement='top'
                />
                <FormControlLabel
                  value='Facebook'
                  onChange={handleRadioChange}
                  control={<Radio />}
                  label={
                    <Service>
                      <ServiceIcon>{Services[1]?.icon}</ServiceIcon>
                      <ServiceName>{Services[1]?.name}</ServiceName>
                      <ServiceCount>
                        {facebookKeywordResult?.length}
                      </ServiceCount>
                    </Service>
                  }
                  labelPlacement='top'
                />
                <FormControlLabel
                  value='Twitter'
                  onChange={handleRadioChange}
                  control={<Radio />}
                  label={
                    <Service>
                      <ServiceIcon>{Services[2].icon}</ServiceIcon>
                      <ServiceName>{Services[2].name}</ServiceName>
                      <ServiceCount>{TotalTwitterMentions}</ServiceCount>
                    </Service>
                  }
                  labelPlacement='top'
                />
                <FormControlLabel
                  value='Blog'
                  onChange={handleRadioChange}
                  control={<Radio />}
                  label={
                    <Service>
                      <ServiceIcon>{Services[3].icon}</ServiceIcon>
                      <ServiceName>{Services[3].name}</ServiceName>
                      <ServiceCount>{blogSearchwordResult.length}</ServiceCount>
                    </Service>
                  }
                  labelPlacement='top'
                />
                <FormControlLabel
                  value='Instagram'
                  onChange={handleRadioChange}
                  control={<Radio />}
                  label={
                    <Service>
                      <ServiceIcon>{Services[4].icon}</ServiceIcon>
                      <ServiceName>{Services[4].name}</ServiceName>
                      <ServiceCount>
                        {instagramKeywordResult.length}
                      </ServiceCount>
                    </Service>
                  }
                  labelPlacement='top'
                />
              </RadioGroup>
            </FormControl>
          </ServicesInnerDiv>
        </ServicesDiv>
        <MentionsList style={{ display: "flex" }}>
          <MentionsListInnerDiv
            style={{ width: "65%", display: "flex", flexDirection: "column" }}
          >
            {value === "All" ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {genTwitterData?.map((twittwSrcWrd, index) => {
                  return (
                    <ItemDiv className='itemDiv' key={index}>
                      <InnerDiv className='innerDiv'>
                        <ProfileDiv className='profile'>
                          <ProfileImage
                            src={
                              twittwSrcWrd.url.user.profileImageUrl ||
                              placeholder
                            }
                            alt='profileimage'
                          />
                          <DateDiv>
                            {dayjs(twittwSrcWrd.Datetime).format("DD.MM.YYYY")}
                          </DateDiv>
                          <TimeDiv>
                            {dayjs(twittwSrcWrd.Datetime).format("h:mm")}
                          </TimeDiv>
                        </ProfileDiv>
                        <Content className='content'>
                          <ContentTop className='contentTop'>
                            <ContentTopLeft className='contentTopLeft'>
                              <ProfileName>
                                {twittwSrcWrd.url.user.displayname}{" "}
                                <span>@{twittwSrcWrd.Username}</span>
                              </ProfileName>
                            </ContentTopLeft>
                            <ContentTopRight className='contentTopRight'>
                              twitter.com
                            </ContentTopRight>
                          </ContentTop>
                          <ContentMiddle className='contentMiddle'>
                            <ContentMiddleText>
                              {twittwSrcWrd.Text}
                            </ContentMiddleText>
                          </ContentMiddle>
                          <ContentBottom className='contentBottom'>
                            {twittwSrcWrd.sentiment === "POS" ? (
                              <ContentBottomLeft className='contentBottomLeft'>
                                <SentimentColorGreen />
                                <h1>Positive</h1>
                              </ContentBottomLeft>
                            ) : twittwSrcWrd.sentiment === "NEU" ? (
                              <ContentBottomLeft className='contentBottomLeft'>
                                <SentimentColorBlue />
                                <h1>Neutral</h1>
                              </ContentBottomLeft>
                            ) : twittwSrcWrd.sentiment === "NEG" ? (
                              <ContentBottomLeft className='contentBottomLeft'>
                                <SentimentColorRed />
                                <h1>Negative</h1>
                              </ContentBottomLeft>
                            ) : null}
                            <div className='contentBottomRight'>
                              <a
                                href={twittwSrcWrd.url.url}
                                target='_blank'
                                rel='noreferrer'
                              >
                                {" "}
                                Visit
                              </a>
                            </div>
                          </ContentBottom>
                        </Content>
                      </InnerDiv>
                    </ItemDiv>
                  );
                })}
                {facebookKeywordResult?.map((facebookKwd, index) => {
                  return (
                    <ItemDiv className='itemDiv' key={index}>
                      <InnerDiv className='innerDiv'>
                        <ProfileDiv className='profile'>
                          <ProfileImage
                            src={facebookKwd.Image_url || placeholder}
                            alt='profileimage'
                          />
                          <div>{facebookKwd.date_time}</div>
                        </ProfileDiv>
                        <Content className='content'>
                          <ContentTop className='contentTop'>
                            <ContentTopLeft className='contentTopLeft'>
                              <ProfileName>
                                {facebookKwd.author_name}{" "}
                              </ProfileName>
                            </ContentTopLeft>
                            <ContentTopRight className='contentTopRight'>
                              facebook.com
                            </ContentTopRight>
                          </ContentTop>
                          <ContentMiddle className='contentMiddle'>
                            <ContentMiddleText>
                              {facebookKwd.posts}
                            </ContentMiddleText>
                          </ContentMiddle>
                          <ContentBottom className='contentBottom'>
                            {facebookKwd.posts_sentiment === "POS" ? (
                              <ContentBottomLeft className='contentBottomLeft'>
                                <SentimentColorGreen />
                                <h1>Positive</h1>
                              </ContentBottomLeft>
                            ) : facebookKwd.posts_sentiment === "NEU" ? (
                              <ContentBottomLeft className='contentBottomLeft'>
                                <SentimentColorBlue />
                                <h1>Neutral</h1>
                              </ContentBottomLeft>
                            ) : facebookKwd.posts_sentiment === "NEG" ? (
                              <ContentBottomLeft className='contentBottomLeft'>
                                <SentimentColorRed />
                                <h1>Negative</h1>
                              </ContentBottomLeft>
                            ) : null}
                            <div className='contentBottomRight'>
                              <a
                                href={facebookKwd.post_link}
                                target='_blank'
                                rel='noreferrer'
                              >
                                {" "}
                                Visit
                              </a>
                            </div>
                          </ContentBottom>
                        </Content>
                      </InnerDiv>
                    </ItemDiv>
                  );
                })}
                {blogSearchwordResult?.map((blogKwd) => {
                  return (
                    <ItemDiv className='itemDiv'>
                      <InnerDiv className='innerDiv'>
                        <ProfileDiv className='profile'>
                          <ProfileImage src={placeholder} alt='profileimage' />
                        </ProfileDiv>
                        <Content className='content'>
                          <ContentTop className='contentTop'>
                            <ContentTopLeft className='contentTopLeft'>
                              <ProfileName>{blogKwd.domain} </ProfileName>
                            </ContentTopLeft>
                            <ContentTopRight className='contentTopRight'>
                              blogs
                            </ContentTopRight>
                          </ContentTop>
                          <ContentMiddle className='contentMiddle'>
                            <ContentMiddleText>
                              {blogKwd.snippet}
                            </ContentMiddleText>
                          </ContentMiddle>
                          <ContentBottom className='contentBottom'>
                            {blogKwd.sentiment === "POS" ? (
                              <ContentBottomLeft className='contentBottomLeft'>
                                <SentimentColorGreen />
                                <h1>Positive</h1>
                              </ContentBottomLeft>
                            ) : blogKwd.sentiment === "NEU" ? (
                              <ContentBottomLeft className='contentBottomLeft'>
                                <SentimentColorBlue />
                                <h1>Neutral</h1>
                              </ContentBottomLeft>
                            ) : blogKwd.sentiment === "NEG" ? (
                              <ContentBottomLeft className='contentBottomLeft'>
                                <SentimentColorRed />
                                <h1>Negative</h1>
                              </ContentBottomLeft>
                            ) : null}
                            <div className='contentBottomRight'>
                              <a
                                href={blogKwd.link}
                                target='_blank'
                                rel='noreferrer'
                              >
                                {" "}
                                Visit
                              </a>
                            </div>
                          </ContentBottom>
                        </Content>
                      </InnerDiv>
                    </ItemDiv>
                  );
                })}
                {instagramKeywordResult?.map((instaKwd, index) => {
                  return (
                    <ItemDiv className='itemDiv' key={index}>
                      <InnerDiv className='innerDiv'>
                        <ProfileDiv className='profile'>
                          <ProfileImage src={placeholder} alt='profileimage' />
                          <DateDiv>
                            {dayjs(instaKwd.timestamp).format("DD-MM-YYYY")}
                          </DateDiv>
                          <TimeDiv>
                            {dayjs(instaKwd.timestamp).format("h:mm")}
                          </TimeDiv>
                        </ProfileDiv>
                        <Content className='content'>
                          <ContentTop className='contentTop'>
                            <ContentTopLeft className='contentTopLeft'>
                              <ProfileName>{instaKwd.id}</ProfileName>
                            </ContentTopLeft>
                            <ContentTopRight className='contentTopRight'>
                              Instagram
                            </ContentTopRight>
                          </ContentTop>
                          <ContentMiddle className='contentMiddle'>
                            <ContentMiddleText className='ContentMiddleText'>
                              {instaKwd.caption}
                            </ContentMiddleText>
                          </ContentMiddle>
                          <ContentBottom className='contentBottom'>
                            {instaKwd.sentiment === "POS" ? (
                              <ContentBottomLeft className='contentBottomLeft'>
                                <SentimentColorGreen />
                                <h1>Positive</h1>
                              </ContentBottomLeft>
                            ) : instaKwd.sentiment === "NEU" ? (
                              <ContentBottomLeft className='contentBottomLeft'>
                                <SentimentColorBlue />
                                <h1>Neutral</h1>
                              </ContentBottomLeft>
                            ) : instaKwd.sentiment === "NEG" ? (
                              <ContentBottomLeft className='contentBottomLeft'>
                                <SentimentColorRed />
                                <h1>Negative</h1>
                              </ContentBottomLeft>
                            ) : null}
                            <div className='contentBottomRight'>
                              <a
                                href={instaKwd.permalink}
                                target='_blank'
                                rel='noreferrer'
                              >
                                {" "}
                                Visit
                              </a>
                            </div>
                          </ContentBottom>
                        </Content>
                      </InnerDiv>
                    </ItemDiv>
                  );
                })}
              </div>
            ) : value === "Facebook" ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {facebookKeywordResult?.map((facebookKwd, index) => {
                  return (
                    <ItemDiv className='itemDiv' key={index}>
                      <InnerDiv className='innerDiv'>
                        <ProfileDiv className='profile'>
                          <ProfileImage
                            src={facebookKwd.Image_url || placeholder}
                            alt='profileimage'
                          />
                          <div>{facebookKwd.date_time}</div>
                        </ProfileDiv>
                        <Content className='content'>
                          <ContentTop className='contentTop'>
                            <ContentTopLeft className='contentTopLeft'>
                              <ProfileName>
                                {facebookKwd.author_name}{" "}
                              </ProfileName>
                            </ContentTopLeft>
                            <ContentTopRight className='contentTopRight'>
                              Facebook
                            </ContentTopRight>
                          </ContentTop>
                          <ContentMiddle className='contentMiddle'>
                            <ContentMiddleText>
                              {facebookKwd.posts}
                            </ContentMiddleText>
                          </ContentMiddle>
                          <ContentBottom className='contentBottom'>
                            {facebookKwd.posts_sentiment === "POS" ? (
                              <ContentBottomLeft className='contentBottomLeft'>
                                <SentimentColorGreen />
                                <h1>Positive</h1>
                              </ContentBottomLeft>
                            ) : facebookKwd.posts_sentiment === "NEU" ? (
                              <ContentBottomLeft className='contentBottomLeft'>
                                <SentimentColorBlue />
                                <h1>Neutral</h1>
                              </ContentBottomLeft>
                            ) : facebookKwd.posts_sentiment === "NEG" ? (
                              <ContentBottomLeft className='contentBottomLeft'>
                                <SentimentColorRed />
                                <h1>Negative</h1>
                              </ContentBottomLeft>
                            ) : null}
                            <div className='contentBottomRight'>
                              <a
                                href={facebookKwd.post_link}
                                target='_blank'
                                rel='noreferrer'
                              >
                                {" "}
                                Visit
                              </a>
                            </div>
                          </ContentBottom>
                        </Content>
                      </InnerDiv>
                    </ItemDiv>
                  );
                })}
              </div>
            ) : value === "Twitter" ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {genTwitterData?.map((twittwSrcWrd, index) => {
                  return (
                    <ItemDiv className='itemDiv' key={index}>
                      <InnerDiv className='innerDiv'>
                        <ProfileDiv className='profile'>
                          <ProfileImage
                            src={
                              twittwSrcWrd.url.user.profileImageUrl ||
                              placeholder
                            }
                            alt='profileimage'
                          />
                          <DateDiv>
                            {dayjs(twittwSrcWrd.Datetime).format("DD.MM.YYYY")}
                          </DateDiv>
                          <TimeDiv>
                            {dayjs(twittwSrcWrd.Datetime).format("h:mm")}
                          </TimeDiv>
                        </ProfileDiv>
                        <Content className='content'>
                          <ContentTop className='contentTop'>
                            <ContentTopLeft className='contentTopLeft'>
                              <ProfileName>
                                {twittwSrcWrd.url.user.displayname}{" "}
                                <span>@{twittwSrcWrd.Username}</span>
                              </ProfileName>
                            </ContentTopLeft>
                            <ContentTopRight className='contentTopRight'>
                              Twitter
                            </ContentTopRight>
                          </ContentTop>
                          <ContentMiddle className='contentMiddle'>
                            <ContentMiddleText>
                              {twittwSrcWrd.Text}
                            </ContentMiddleText>
                          </ContentMiddle>
                          <ContentBottom className='contentBottom'>
                            {twittwSrcWrd.sentiment === "POS" ? (
                              <ContentBottomLeft className='contentBottomLeft'>
                                <SentimentColorGreen />
                                <h1>Positive</h1>
                              </ContentBottomLeft>
                            ) : twittwSrcWrd.sentiment === "NEU" ? (
                              <ContentBottomLeft className='contentBottomLeft'>
                                <SentimentColorBlue />
                                <h1>Neutral</h1>
                              </ContentBottomLeft>
                            ) : twittwSrcWrd.sentiment === "NEG" ? (
                              <ContentBottomLeft className='contentBottomLeft'>
                                <SentimentColorRed />
                                <h1>Negative</h1>
                              </ContentBottomLeft>
                            ) : null}
                            <div className='contentBottomRight'>
                              <a
                                href={twittwSrcWrd.url.url}
                                target='_blank'
                                rel='noreferrer'
                              >
                                {" "}
                                Visit
                              </a>
                            </div>
                          </ContentBottom>
                        </Content>
                      </InnerDiv>
                    </ItemDiv>
                  );
                })}
              </div>
            ) : value === "Blog" ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {blogSearchwordResult?.map((blogKwd) => {
                  return (
                    <ItemDiv className='itemDiv'>
                      <InnerDiv className='innerDiv'>
                        <ProfileDiv className='profile'>
                          <ProfileImage src={placeholder} alt='profileimage' />
                        </ProfileDiv>
                        <Content className='content'>
                          <ContentTop className='contentTop'>
                            <ContentTopLeft className='contentTopLeft'>
                              <ProfileName>{blogKwd.domain} </ProfileName>
                            </ContentTopLeft>
                            <ContentTopRight className='contentTopRight'>
                              Blogs
                            </ContentTopRight>
                          </ContentTop>
                          <ContentMiddle className='contentMiddle'>
                            <ContentMiddleText>
                              {blogKwd.snippet}
                            </ContentMiddleText>
                          </ContentMiddle>
                          <ContentBottom className='contentBottom'>
                            {blogKwd.sentiment === "POS" ? (
                              <ContentBottomLeft className='contentBottomLeft'>
                                <SentimentColorGreen />
                                <h1>Positive</h1>
                              </ContentBottomLeft>
                            ) : blogKwd.sentiment === "NEU" ? (
                              <ContentBottomLeft className='contentBottomLeft'>
                                <SentimentColorBlue />
                                <h1>Neutral</h1>
                              </ContentBottomLeft>
                            ) : blogKwd.sentiment === "NEG" ? (
                              <ContentBottomLeft className='contentBottomLeft'>
                                <SentimentColorRed />
                                <h1>Negative</h1>
                              </ContentBottomLeft>
                            ) : null}
                            <div className='contentBottomRight'>
                              <a
                                href={blogKwd.link}
                                target='_blank'
                                rel='noreferrer'
                              >
                                {" "}
                                Visit
                              </a>
                            </div>
                          </ContentBottom>
                        </Content>
                      </InnerDiv>
                    </ItemDiv>
                  );
                })}
              </div>
            ) : value === "Instagram" ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {instagramKeywordResult?.map((instaKwd, index) => {
                  return (
                    <ItemDiv className='itemDiv' key={index}>
                      <InnerDiv className='innerDiv'>
                        <ProfileDiv className='profile'>
                          <ProfileImage src={placeholder} alt='profileimage' />
                          <DateDiv>
                            {dayjs(instaKwd.timestamp).format("DD-MM-YYYY")}
                          </DateDiv>
                          <TimeDiv>
                            {dayjs(instaKwd.timestamp).format("h:mm")}
                            {/* {dayjs(instaKwd.Datetime).format("h:mm")} */}
                          </TimeDiv>
                        </ProfileDiv>
                        <Content className='content'>
                          <ContentTop className='contentTop'>
                            <ContentTopLeft className='contentTopLeft'>
                              <ProfileName>
                                {instaKwd.id}
                                {/* <span>@{instaKwd.Username}</span> */}
                              </ProfileName>
                            </ContentTopLeft>
                            <ContentTopRight className='contentTopRight'>
                              Instagram
                            </ContentTopRight>
                          </ContentTop>
                          <ContentMiddle className='contentMiddle'>
                            <ContentMiddleText>
                              {instaKwd.caption}
                            </ContentMiddleText>
                          </ContentMiddle>
                          <ContentBottom className='contentBottom'>
                            {instaKwd.sentiment === "POS" ? (
                              <ContentBottomLeft className='contentBottomLeft'>
                                <SentimentColorGreen />
                                <h1>Positive</h1>
                              </ContentBottomLeft>
                            ) : instaKwd.sentiment === "NEU" ? (
                              <ContentBottomLeft className='contentBottomLeft'>
                                <SentimentColorBlue />
                                <h1>Neutral</h1>
                              </ContentBottomLeft>
                            ) : instaKwd.sentiment === "NEG" ? (
                              <ContentBottomLeft className='contentBottomLeft'>
                                <SentimentColorRed />
                                <h1>Negative</h1>
                              </ContentBottomLeft>
                            ) : null}
                            <div className='contentBottomRight'>
                              <a
                                href={instaKwd.permalink}
                                target='_blank'
                                rel='noreferrer'
                              >
                                {" "}
                                Visit
                              </a>
                            </div>
                          </ContentBottom>
                        </Content>
                      </InnerDiv>
                    </ItemDiv>
                  );
                })}
              </div>
            ) : null}
          </MentionsListInnerDiv>
          <div style={{ width: "40%" }}>
            <div>
              <div className='ml-10 bg-white rounded-md flex-1 pt-7 pb-8 px-6 '>
                <p className='text-xl font-bold capitalize'>search history</p>
                {histories.map((item, i) => (
                  <ClientDetail key={i} item={item} />
                ))}
              </div>
            </div>
          </div>
        </MentionsList>
        <SearchKeyword />
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state?.profile?.userProfile,
    keywordOne: state?.sentiment?.selectedKeyWord,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
