import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { WebsiteDisplay } from './websiteDisplay';
import DataTable from 'react-data-table-component';
import FilterComponent from './FilterComponent';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import {
  ButtonGroup,
  Button,
  Tooltip,
  Grid,
  AppBar,
  Tabs,
  Tab,
  Typography,
} from '@mui/material';
import facebook from '../../../asset/img/icons8-facebook-logo-144 1.svg';
import instagram from '../../../asset/img/icons8-instagram-logo-144 3.svg';
import linkedin from '../../../asset/img/icons8-linkedin-96 1.svg';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export const Index = (props) => {
  // tab vars
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const profileColumns = [
    {
      name: 'Influencer',
      selector: (row) => row.influencer,
    },
    {
      name: 'Source',
      selector: (row) => row.source,
      cell: (row) => (
        <img
          src={row.source}
          alt="source"
          style={{
            width: '30px',
          }}
        />
      ),
    },

    {
      name: 'Reach',
      selector: (row) => row.reach,
    },
    {
      name: 'Sentiment',
      selector: (row) => row.sentiment,
      cell: (row) => (
        <>
          <ButtonGroup
            size="small"
            variant="contained"
            sx={{ marginRight: '100px' }}
          >
            <Tooltip title="positive comments" placement="top">
              <Button color="info" sx={{ fontSize: '10px' }}>
                {row.sentiment.split(':')[0]}
              </Button>
            </Tooltip>
            <Tooltip title="neutral comments" placement="top">
              <Button color="warning" sx={{ fontSize: '8px' }}>
                {row.sentiment.split(':')[1]}
              </Button>
            </Tooltip>
            <Tooltip title="negative comments" placement="top">
              <Button color="error" sx={{ fontSize: '8px' }}>
                {row.sentiment.split(':')[2]}
              </Button>
            </Tooltip>
          </ButtonGroup>
        </>
      ),
    },
    {
      name: 'Share of Voice',
      selector: (row) => row.share_of_voice,
    },
    {
      name: 'Engagement',
      selector: (row) => row.engagement,
    },
  ];

  const profileData = [
    {
      id: 1,
      influencer: 'Biju Dantu',
      source: facebook,
      reach: '3,000,045',
      sentiment: '60:25:5',
      share_of_voice: '17.08%',
      engagement: '2,034,501',
    },
    {
      id: 2,
      influencer: 'Udeme Simon',
      source: instagram,
      reach: '1,000,045',
      sentiment: '70:10:20',
      share_of_voice: '73.02%',
      engagement: '2,034,501',
    },
    {
      id: 3,
      influencer: 'Olamide Lagbaja',
      source: linkedin,
      reach: '3,000,202',
      sentiment: '20:50:30',
      share_of_voice: '61.77%',
      engagement: '1,234,561',
    },
    {
      id: 4,
      influencer: 'Biju Dantu',
      source: facebook,
      reach: '3,000,045',
      sentiment: '60:25:5',
      share_of_voice: '17.08%',
      engagement: '2,034,501',
    },
    {
      id: 5,
      influencer: 'Udeme Simon',
      source: instagram,
      reach: '1,000,045',
      sentiment: '70:10:20',
      share_of_voice: '73.02%',
      engagement: '2,034,501',
    },
    {
      id: 6,
      influencer: 'Olamide Lagbaja',
      source: linkedin,
      reach: '3,000,202',
      sentiment: '20:50:30',
      share_of_voice: '61.77%',
      engagement: '1,234,561',
    },
    {
      id: 7,
      influencer: 'Biju Dantu',
      source: facebook,
      reach: '3,000,045',
      sentiment: '60:25:5',
      share_of_voice: '17.08%',
      engagement: '2,034,501',
    },
    {
      id: 8,
      influencer: 'Udeme Simon',
      source: instagram,
      reach: '1,000,045',
      sentiment: '70:10:20',
      share_of_voice: '73.02%',
      engagement: '2,034,501',
    },
    {
      id: 9,
      influencer: 'Olamide Lagbaja',
      source: linkedin,
      reach: '3,000,202',
      sentiment: '20:50:30',
      share_of_voice: '61.77%',
      engagement: '1,234,561',
    },
    {
      id: 10,
      influencer: 'Biju Dantu',
      source: facebook,
      reach: '3,000,045',
      sentiment: '60:25:5',
      share_of_voice: '17.08%',
      engagement: '2,034,501',
    },
    {
      id:11,
      influencer: 'Udeme Simon',
      source: instagram,
      reach: '1,000,045',
      sentiment: '70:10:20',
      share_of_voice: '73.02%',
      engagement: '2,034,501',
    },
    {
      id: 12,
      influencer: 'Olamide Lagbaja',
      source: linkedin,
      reach: '3,000,202',
      sentiment: '20:50:30',
      share_of_voice: '61.77%',
      engagement: '1,234,561',
    },
    {
      id: 13,
      influencer: 'Biju Dantu',
      source: facebook,
      reach: '3,000,045',
      sentiment: '60:25:5',
      share_of_voice: '17.08%',
      engagement: '2,034,501',
    },
    {
      id: 14,
      influencer: 'Udeme Simon',
      source: instagram,
      reach: '1,000,045',
      sentiment: '70:10:20',
      share_of_voice: '73.02%',
      engagement: '2,034,501',
    },
    {
      id: 15,
      influencer: 'Olamide Lagbaja',
      source: linkedin,
      reach: '3,000,202',
      sentiment: '20:50:30',
      share_of_voice: '61.77%',
      engagement: '1,234,561',
    },
  ];

  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = profileData.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <Box sx={{ bgcolor: 'background.paper', flexGrow: 1 }}>
      <h3>Influencers</h3>
      <Grid container spacing={2} xs={12} sx={{ mt: 5 }}>
        <Grid item xs={12}>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="Top Profiles" {...a11yProps(0)} />
              <Tab label="Top Websites" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              {/* <h3> Top Influencers</h3> */}
              <DataTable
                columns={profileColumns}
                data={filteredItems}
                defaultSortField="name"
                striped
                pagination
                highlightOnHover
                subHeader
                subHeaderComponent={subHeaderComponent}
                responsive
                subHeaderWrap
              />
            </TabPanel>

            <TabPanel value={value} index={1} dir={theme.direction}>
              <WebsiteDisplay />
            </TabPanel>
            {/* <TabPanel value={value} index={2} dir={theme.direction}>
          Item Three
        </TabPanel> */}
          </SwipeableViews>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
