import React from "react";
import dayjs from "dayjs";

function ClientDetail({ item }) {
  const time = new Date(item.time).getDate();
  return (
    <div>
      <div className='flex items-center justify-between my-3 '>
        <p className='text-sm text-darkOrange font-bold'>
          {item.query.charAt(0).toUpperCase() + item.query.slice(1)}
        </p>
        <p className='text-sm text-textColor font-bold  '>{`${dayjs(
          item.time
        ).format("hh:mm A DD-MMM")}`}</p>
      </div>
    </div>
  );
}

export default ClientDetail;
