import React from "react";
// import ActivityChart from './BarChart/ActivityChart';
// import BarChart from './BarChart/BarChart';
import FirstComparedItem from "./FirstComparedItem";
import SecondComparedItem from "./SecondComparedItem";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",

  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //   width: 900,
  //   maxWidth: "90vw",
  // height: 1000,
  bgcolor: "background.paper",
  boxShadow: 15,
  borderRadius: "5px",
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  overflowY: "scroll",
};

function CompareModal({
  handleToggle,
  keywordOneData,
  keywordTwoData,
  open,
  keywordOne,
  keywordTwo,
  // post,
  // firstEng,
  // firstMention,
  // firstImpress,
  // secondEng,
  // secondImpress,
  // secondMention,
  // noOfFirstMention,
  // noOfSecondMention,
  // TotalMentionsKeyOne,
  // TotalMentionsKeyTwo,
  // TotalPositiveSentimentKeyOne,
  // TotalNeutralSentimentKeyOne,
  // TotalNegativeSentimentKeyOne,
  // TotalPositiveSentimentKeyTwo,
  // TotalNeutralSentimentKeyTwo,
  // TotalNegativeSentimentKeyTwo,
  // TotalTwitterMentionsKeyOne,
  // TotalFacebookMentionsKeyOne,
  // TotalTwitterMentionsKeyTwo,
  // TotalFacebookMentionsKeyTwo,
  // TotalEngagementKeyOne,
  // TotalEngagementKeyTwo,
}) {
  // const reachDataLineChart = [0, 10, 20, 10, 30, 65, 30, 40, 20];
  // const dougnutSentimentData = [75, 25];
  // const reachLabelLineChart = [
  //   '6am-9am',
  //   '9am-12pm',
  //   '12pm-3pm',
  //   '3pm-6pm',
  //   '6pm-9pm',
  //   '9pm-12am',
  //   '12am-3am',
  //   '3am-6am',
  // ];

  // const dougnutSentimentBg = [
  //   'rgb(56, 239, 195)',
  //   'rgb(234, 115, 143)',
  //   'rgb(242, 201, 76)',
  // ];

  // const sentimentLabel1 = 'Indomie';
  // const sentimentLabel2 = 'Spaghetti';

  return (
    <div className=''>
      <Modal
        open={open}
        onClose={handleToggle}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          {/* <div className=" flex items-center justify-between ">
              <ActivityChart
                label={reachLabelLineChart}
                data={reachDataLineChart}
                title="Reach"
              />

              <BarChart
                data={dougnutSentimentData}
                background_color={dougnutSentimentBg}
                title="Share of Sentiment"
                label1={sentimentLabel1}
                label2={sentimentLabel2}
              />
            </div> */}

          {/* indomie media source  */}
          <FirstComparedItem
            keywordOneData={keywordOneData}
            keywordOne={keywordOne || "Keyword 1"}
            // firstEng={firstEng}
            // firstMention={firstMention}
            // firstImpress={firstImpress}
            // noOfFirstMention={noOfFirstMention}
            // TotalMentionsKeyOne={TotalMentionsKeyOne || "No Data"}
            // TotalPositiveSentimentKeyOne={
            //   TotalPositiveSentimentKeyOne || "No Data"
            // }
            // TotalNeutralSentimentKeyOne={
            //   TotalNeutralSentimentKeyOne || "No Data"
            // }
            // TotalNegativeSentimentKeyOne={
            //   TotalNegativeSentimentKeyOne || "No Data"
            // }
            // TotalTwitterMentionsKeyOne={TotalTwitterMentionsKeyOne || 0}
            // TotalFacebookMentionsKeyOne={TotalFacebookMentionsKeyOne || 0}
          />

          {/* spaghetti media source  */}
          <SecondComparedItem
            keywordTwoData={keywordTwoData}
            keywordTwo={keywordTwo || "Keyword 2"}
            // secondEng={secondEng}
            // secondMention={secondMention}
            // secondImpress={secondImpress}
            // noOfSecondMention={noOfSecondMention}
            // TotalMentionsKeyTwo={TotalMentionsKeyTwo || "No Data"}
            // TotalPositiveSentimentKeyTwo={
            //   TotalPositiveSentimentKeyTwo || "No Data"
            // }
            // TotalNeutralSentimentKeyTwo={
            //   TotalNeutralSentimentKeyTwo || "No Data"
            // }
            // TotalNegativeSentimentKeyTwo={
            //   TotalNegativeSentimentKeyTwo || "No Data"
            // }
            // TotalTwitterMentionsKeyTwo={TotalTwitterMentionsKeyTwo || 0}
            // TotalFacebookMentionsKeyTwo={TotalFacebookMentionsKeyTwo || 0}
          />
        </Box>
      </Modal>
    </div>
  );
}

export default CompareModal;
