import React from 'react'

function Index() {
    return (
        <div>
            Page not Found
        </div>
    )
}

export default Index
