// import {getDecodedToken, getValidToken} from "../../utility/axios-token-manager/token"

const initialState = {
  insights: {},
  media: "",
  pageInfo: {},
  mediaInsightIG: {
    modal: false,
    insights: null,
    comments: null,
    postId: null,
    pageId: null,
  },
};
const setFacebookInsightPage = (state, data) => {
  // page_impressions_unique page_engaged_users day week days_28;
  let impressions = data.data.filter(
    (item) => item.name === "page_impressions_unique"
  );
  let engagements = data.data.filter(
    (item) => item.name === "page_engaged_users"
  );

  return {
    ...state,
    insights: { impressions, engagements },
    media: "facebook",
    pageInfo: data.pageInfo,
  };
};
const setIGInsightPage = (state, data) => {
  return {
    ...state,
    insights: { ...data },
    media: "instagram",
    pageInfo: data.pageInfo,
  };
};
const setIGInsightMedia = (state, data) => {
  if (data)
    return {
      ...state,
      mediaInsightIG: {
        modal: true,
        insights: data.metrics,
        comments: data.comments,
        postInfo: data.postInfo,
      },
    };
  return {
    ...state,
    mediaInsightIG: {
      modal: false,
      insights: null,
      comments: null,
      postInfo: null,
    },
  };
};

const socialReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_INSIGHT":
      if (action.payload.media === "facebook") {
        return setFacebookInsightPage(state, action.payload.data);
      }
      if (action.payload.media === "instagram") {
        return setIGInsightPage(state, action.payload.data);
      } else {
        return state;
      }
    case "IG_MEDIA_INSIGHT":
      return setIGInsightMedia(state, action.payload);
    default:
      return state;
  }
};

export default socialReducer;
