// export const data = [
//     {
//         socialMediaUrl: `${require('../../images/twitter.png').default}`,
//         imgUrl:`${require('../../images/history.svg').default}`,
//         caption:"human are the only creatures who cut down trees and turn...",
//         date:"10/14/2021",
//         activity:"view activity",
//         socialHandle:"Twitter"
//     },

//     {
//         socialMediaUrl: `${require('../../images/instagram.png').default}`,
//         imgUrl:`${require('../../images/history.svg').default}`,
//         caption:"human are the only creatures who cut down trees and turn...",
//         date:"10/14/2021",
//         activity:"view activity",
//         socialHandle:"instagram"

//     },

//     {
//         socialMediaUrl: `${require('../../images/youtube.png').default}`,
//         imgUrl:`${require('../../images/video.png').default}`,
//         caption:"human are the only creatures who cut down trees and turn...",
//         date:"10/14/2021",
//         activity:"view activity",
//         socialHandle:"Youtube"

//     },

//     {
//         socialMediaUrl: `${require('../../images/tiktok.png').default}`,
//         imgUrl:`${require('../../images/video.png').default}`,
//         caption:"human are the only creatures who cut down trees and turn...",
//         date:"10/14/2021",
//         activity:"view activity",
//         socialHandle:"Tiktok"

//     },

//     {
//         socialMediaUrl: `${require('../../images/instagram.png').default}`,
//         imgUrl:`${require('../../images/video.png').default}`,
//         caption:"human are the only creatures who cut down trees and turn...",
//         date:"10/14/2021",
//         activity:"view activity",
//         socialHandle:"Instagram"

//     },
// ]

export const dashboardPosts = [
  {
    avatar: `${require("../../../asset/img/avatar.png").default}`,
    username: "adebisi the headmaster",
    handle: "@busybody",
    socialMediaIcon: `${require("../../../asset/img/twitter.png").default}`,
    postedImage: "",
    caption:
      "This people should bring back indomie pepper soup oo!! #Peppersoupislife",
    date: "09.04.2021",
    time: "12:31Am",
    activity: "view activity",
    socialHandle: "twitter.com",
    influencerScore: "7/10",
    influencerStrength: 80,
  },
  {
    avatar: `${require("../../../asset/img/avatar.png").default}`,
    username: "adebisi the headmaster",
    handle: "@busybody",
    socialMediaIcon: `${require("../../../asset/img/twitter.png").default}`,
    postedImage: `${require("../../../asset/img/noodles.png").default}`,
    caption: "You guys, the indomie i just made ehn!!",
    date: "09.04.2021",
    time: "12:31Am",
    activity: "view activity",
    socialHandle: "twitter.com",
    influencerScore: "7/10",
    influencerStrength: 80,
  },
  {
    avatar: `${require("../../../asset/img/avatar.png").default}`,
    username: "adebisi the headmaster",
    handle: "@busybody",
    socialMediaIcon: `${require("../../../asset/img/twitter.png").default}`,
    postedImage: "",
    caption:
      "This people should bring back indomie pepper soup oo!! #Peppersoupislife",
    date: "09.04.2021",
    time: "12:31Am",
    activity: "view activity",
    socialHandle: "twitter.com",
    influencerScore: "7/10",
    influencerStrength: 80,
  },

  {
    avatar: `${require("../../../asset/img/avatar.png").default}`,
    username: "adebisi the headmaster",
    handle: "@busybody",
    socialMediaIcon: `${require("../../../asset/img/twitter.png").default}`,
    postedImage: "",
    caption:
      "This people should bring back indomie pepper soup oo!! #Peppersoupislife",
    date: "09.04.2021",
    time: "12:31Am",
    activity: "view activity",
    socialHandle: "twitter.com",
    influencerScore: "7/10",
    influencerStrength: 80,
  },

  {
    avatar: `${require("../../../asset/img/avatar.png").default}`,
    username: "adebisi the headmaster",
    handle: "@busybody",
    socialMediaIcon: `${require("../../../asset/img/twitter.png").default}`,
    postedImage: "",
    caption:
      "This people should bring back indomie pepper soup oo!! #Peppersoupislife",
    date: "09.04.2021",
    time: "12:31Am",
    activity: "view activity",
    socialHandle: "twitter.com",
    influencerScore: "7/10",
    influencerStrength: 80,
  },
];

export const client = [
  {
    company: "Indomie",
    time: "10:28 PM",
  },
  {
    company: "Super eagles",
    time: "Yesterday",
  },
  {
    company: "Mtn Nigeria",
    time: "2 days ago",
  },
  {
    company: "Coca cola",
    time: "4 days ago",
  },
  {
    company: "Atiku",
    time: "A week ago",
  },
  {
    company: "Indomie",
    time: "10:28 PM",
  },
  {
    company: "Super eagles",
    time: "Yesterday",
  },
  {
    company: "Mtn Nigeria",
    time: "2 days ago",
  },
  {
    company: "Coca cola",
    time: "4 days ago",
  },
  {
    company: "Atiku",
    time: "A week ago",
  },
];

export const markedItemData = [
  {
    website: "http://www.ayobi.blogspot.com",
    title: "How Indomie instant noodles ecame a Nigerian staple....",
    body: "The generic name for the product is noodle. However, this product was the first brand in Nigerai in 1995. Indomie is a product of the ..",
  },
  {
    website: "http://www.ayobi.blogspot.com",
    title: "How Indomie instant noodles ecame a Nigerian staple....",
    body: "The generic name for the product is noodle. However, this product was the first brand in Nigerai in 1995. Indomie is a product of the ..",
  },
  {
    website: "http://www.ayobi.blogspot.com",
    title: "How Indomie instant noodles ecame a Nigerian staple....",
    body: "The generic name for the product is noodle. However, this product was the first brand in Nigerai in 1995. Indomie is a product of the ..",
  },
  {
    website: "http://www.ayobi.blogspot.com",
    title: "How Indomie instant noodles ecame a Nigerian staple....",
    body: "The generic name for the product is noodle. However, this product was the first brand in Nigerai in 1995. Indomie is a product of the ..",
  },
];