import styled from "styled-components";

const ContentPadding = styled.div`
  padding: 10px;
`;

const HeadText = styled.h1`
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  color: #171717;
`;

export { ContentPadding, HeadText };
