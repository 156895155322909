import React, { useState, Component, Fragment, useRef } from "react";
import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Paper,
} from "@mui/material";
import { connect, useDispatch, useSelector } from "react-redux";
import testPhoto from "../../../../asset/img/feather.svg";
import testPhoto2 from "../../../../asset/img/fi_smile.svg";
import testPhoto3 from "../../../../asset/img/brandteon_black_bg.png";
import {
  SearchwordTitleDiv,
  KeywordTitle,
  Charts,
  SentimentCharts,
  FacebookSentiment,
  FacebookEngagement,
  BlogSentiment,
  DataDiv,
  MentionShare,
  MentionShareInner,
  TotalFacebookShares,
  TotalFacebookLikes,
  TotalFacebookComments,
  BlogMentionsShare,
  ShareTitle,
  ShareContent,
  SentimentTitle,
  Engagements,
  EngagementsTitle,
} from "./style/facebookReportStyle";
import { Bar, Pie, Line, Doughnut } from "react-chartjs-2";
// import { Chart } from "chart.js/auto";
import AnimatedNumber from "react-animated-number";
import SocialEngagements from "../../mentions/charts/socialEngagements";
import ButtonComponent from "../../../../Button/Button";
import Chart from "react-apexcharts";
import dayjs from "dayjs";
import ReactToPrint from "react-to-print";

export const AllReports = (props) => {
  // const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const sentiment = selector.sentiment;
  const sentimentData = sentiment.sentimentData;
  const sentimentKeyword = sentiment.selectedKeyWord;

  const currentDate = new Date();
  const today = currentDate.getDate();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();
  const newDate = new Date(currentYear, 0, 0).getDate();
  var now = dayjs().format("DD MMM YYYY");

  // twitterHashtagData
  const twitterHashtag = sentimentData[0] || [];
  var twitterHashtagResult = twitterHashtag?.result || [];
  var twitterHashtagTotalEngagement = twitterHashtag?.Engagement_total || [];
  var twitterHashtagTotalImpression = twitterHashtag?.Impression_total || [];
  var twitterHashtagTotalSentiment = twitterHashtag?.Total_sentiment || [];
  var twitterHashtagEngagementAnalysis =
    twitterHashtag?.engagement_analysis || [];
  var { sentiment: twitterHashtagSentimentCount } =
    twitterHashtag?.sentiment_count || {};
  var {
    NEG: twitterNeg,
    NEU: twitterNeu,
    POS: twitterPos,
  } = twitterHashtagSentimentCount || {};
  var twitlikes = 0;
  var twitcomments = 0;
  var twitRetweets = 0;
  const twitterLikes = twitterHashtagResult.map((item) => {
    return (twitlikes += item.Likes);
  });
  const twitterComments = twitterHashtagResult.map((item) => {
    return (twitcomments += item.Reply_count);
  });
  const twitterRetweets = twitterHashtagResult.map((item) => {
    return (twitRetweets += item.Retweet_count);
  });

  // facebookSearchData
  const facebookKeyword = sentimentData[1] || [];
  const facebookKeywordResult = facebookKeyword?.result || [];
  const facebookKeywordTotalSentiment = facebookKeyword?.Total_sentiment || [];
  const facebookKeywordTotalEngagement =
    facebookKeyword?.Total_engagement || [];
  const { Total_engagement: facebookTotalEngagement } =
    facebookKeywordTotalEngagement[0] || {};
  const facebookKeywordSentimentCount = facebookKeyword?.sentiment_count || {};
  const facebookKeywordSentimentCountPosts =
    facebookKeywordSentimentCount.posts_sentiment || {};
  const {
    NEG: FacebookNeg,
    NEU: FacebookNeu,
    POS: FacebookPos,
  } = facebookKeywordSentimentCountPosts || {};
  var faceLikes = 0;
  var faceComments = 0;
  var faceShares = 0;
  const facebookLikes = facebookKeywordResult.map((item) => {
    return (faceLikes += item.likes_no);
  });
  const facebookComments = facebookKeywordResult.map((item) => {
    return (faceComments += item.comments_no);
  });
  const facebookShares = facebookKeywordResult.map((item) => {
    return (faceShares += item.shares_no);
  });
  const allFacebookData = faceLikes + faceComments + faceShares;

  const FacebookTotalSentiments = FacebookNeg + FacebookNeu + FacebookPos;
  const FacePosPercentage = (FacebookPos / FacebookTotalSentiments) * 100;
  const FaceNeuPercentage = (FacebookNeu / FacebookTotalSentiments) * 100;
  const FaceNegPercentage = (FacebookNeg / FacebookTotalSentiments) * 100;

  const facebookSentDoughSeries = [
    FacebookPos || 2,
    FacebookNeu || 5,
    FacebookNeg || 8,
  ];
  const facebookSentDoughOptions = {
    chart: {
      width: 280,
      type: "donut",
    },
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
            },
          },
        },
      },
    },
    labels: ["Positive", "Neutral", "Negative"],
    dataLabels: {
      dropShadow: {
        blur: 3,
        opacity: 0.8,
      },
    },
    states: {
      hover: {
        filter: {
          type: "lighten",
          value: 0.15,
        },
      },
    },
    theme: {
      palette: "palette2",
    },
    title: {
      text: "Facebook Sentiment",
      align: "center",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    colors: ["#38EFC3", "#F2C94C", "#EA738F"],
  };

  const totalFacebookEngagementSeries = [
    faceLikes || 2,
    faceComments || 2,
    faceShares || 5,
  ];
  const totalFacebookEngagementOptions = {
    chart: {
      width: 280,
      type: "donut",
    },
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
            },
          },
          // expandOnClick: true,
        },
      },
    },
    labels: ["Likes", "Comments", "Shares"],
    dataLabels: {
      dropShadow: {
        blur: 3,
        opacity: 0.8,
      },
    },
    states: {
      hover: {
        filter: {
          type: "lighten",
          value: 0.15,
        },
      },
    },
    theme: {
      palette: "palette2",
    },
    title: {
      text: "Facebook Engagements",
      align: "center",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    colors: ["#b7405c", "#5da2d5", "#38EFC3"],
  };

  console.log("facebookKeyword", facebookKeyword);

  const facebookDateSort = facebookKeywordResult?.sort((a, b) => {
    return new Date(a.date_time) - new Date(b.date_time);
  });

  const facebookEngagementSeriesTwo = [
    {
      name: "Total Engagement",
      data: facebookDateSort.map((item) => [item.date_time, item.Engagements]),
    },
  ];
  const facebookEngagementOptionsTwo = {
    chart: {
      id: "area-datetime",
      type: "area",
      height: 350,
      zoom: {
        autoScaleYaxis: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: "Facebook Engagements",
      align: "left",
      offsetX: 14,
    },
    markers: {
      size: 0,
      style: "hollow",
    },
    xaxis: {
      type: "datetime",
      min: now,
      tickAmount: 6,
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy",
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
  };

  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  React.useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, text]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <ButtonComponent>Print</ButtonComponent>;
  }, []);

  return (
    <Box>
      <div>
        <Paper elevation={2} sx={{ p: 1, mt: 2 }}>
          <SearchwordTitleDiv
            className='sentimentTitle'
            style={{
              display: "flex",
              padding: "15px",
              justifyContent: "center",
            }}
          >
            <KeywordTitle>
              Facebook Analysis based on{" "}
              <span style={{ color: "#ff9326" }}>
                {sentimentKeyword || ""}{" "}
              </span>
              keyword!
            </KeywordTitle>
          </SearchwordTitleDiv>
          <Charts className='charts'>
            <MentionShare className='mentionsShare'>
              <MentionShareInner
                className='mentionShareInner'
                style={{ display: "flex", flexDirection: "row" }}
              >
                <TotalFacebookShares className='TotalFacebookShares'>
                  <ShareTitle>Total Shares</ShareTitle>
                  <ShareContent
                    // component='text'
                    value={faceShares}
                    duration={300}
                    formatValue={(n) => n.toFixed(0)}
                  />
                </TotalFacebookShares>
                <TotalFacebookLikes className='TotalFacebookLikes'>
                  <ShareTitle>Total Likes</ShareTitle>
                  <ShareContent
                    // component='text'
                    value={faceLikes}
                    duration={300}
                    formatValue={(n) => n.toFixed(0)}
                  />
                </TotalFacebookLikes>
                <TotalFacebookComments className='TotalFacebookComments'>
                  <ShareTitle>Total Comments</ShareTitle>
                  <ShareContent
                    // component='text'
                    value={faceComments}
                    duration={300}
                    formatValue={(n) => n.toFixed(0)}
                  />
                </TotalFacebookComments>
              </MentionShareInner>
            </MentionShare>
            <div
              className='charts-inner'
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "20px",
                alignItems: "center",
                width: "100%",
              }}
            >
              <SentimentCharts className='sentimentCharts'>
                <FacebookSentiment className='facebookSentiment'>
                  <Chart
                    options={facebookSentDoughOptions}
                    series={facebookSentDoughSeries}
                    type='donut'
                    width='350'
                  />
                </FacebookSentiment>
                <FacebookEngagement className='FacebookEngagement'>
                  <Chart
                    series={totalFacebookEngagementSeries}
                    options={totalFacebookEngagementOptions}
                    type='donut'
                    width='350'
                  />
                </FacebookEngagement>
              </SentimentCharts>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <div>
                  <Chart
                    series={facebookEngagementSeriesTwo}
                    options={facebookEngagementOptionsTwo}
                    type='area'
                    width='500'
                  />
                </div>
              </div>
            </div>
          </Charts>
        </Paper>
      </div>
    </Box>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AllReports);
