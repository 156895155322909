
const initialState={
    addKeyWord:false,
    searchKeyWord:false,
    connectIG:false
}




const loadingReducer = (state = initialState, action) => {
    switch (action.type) {
      case "ADD-KEYWORD":
        return {
          ...state,addKeyWord:action.payload
        };
      case "SEARCH-KEYWORD":
        return {
          ...state,searchKeyWord:action.payload
        };
      case "CONNECT-IG":
        return {...state, connectIG:action.payload};
      default:
        return state;
    } 
  };
  
  export default loadingReducer;