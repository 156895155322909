import React, { useState, Component, Fragment, useRef } from "react";
import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Paper,
} from "@mui/material";
import { connect, useDispatch, useSelector } from "react-redux";
import testPhoto from "../../../../asset/img/feather.svg";
import testPhoto2 from "../../../../asset/img/fi_smile.svg";
import testPhoto3 from "../../../../asset/img/brandteon_black_bg.png";
import {
  SearchwordTitleDiv,
  KeywordTitle,
  Charts,
  SentimentCharts,
  FacebookSentiment,
  TwitterSentiment,
  BlogSentiment,
  DataDiv,
  MentionShare,
  MentionShareInner,
  TotalMentionsShare,
  FacebookMentionsShare,
  TwitterMentionsShare,
  BlogMentionsShare,
  ShareTitle,
  ShareContent,
  SentimentTitle,
  Engagements,
  EngagementsTitle,
} from "./style/twitterReportStyle";
import { Bar, Pie, Line, Doughnut } from "react-chartjs-2";
// import { Chart } from "chart.js/auto";
import AnimatedNumber from "react-animated-number";
import SocialEngagements from "../../mentions/charts/socialEngagements";
import ButtonComponent from "../../../../Button/Button";
import Chart from "react-apexcharts";
import dayjs from "dayjs";
import ReactToPrint from "react-to-print";

export const InstagramSummaryReport = (props) => {
  // const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const sentiment = selector.sentiment;
  const sentimentData = sentiment.sentimentData;
  const sentimentKeyword = sentiment.selectedKeyWord;

  // const currentDate = new Date();
  // const today = currentDate.getDate();
  // const currentMonth = currentDate.getMonth() + 1;
  // const currentYear = currentDate.getFullYear();
  // const newDate = new Date(currentYear, 0, 0).getDate();
  var now = dayjs().format("DD MMM YYYY");

  // instagramSearchData
  const instagramKeyword = sentimentData[4] || [];
  var instagramKeywordResult = instagramKeyword?.result || [];
  var instagramKeywordTotalEngagement =
    instagramKeyword?.Total_engagement || [];
  var instagramKetwordTotalSentiment = instagramKeyword?.Total_sentiment || [];
  var { sentiment: instagramKeywordSentimentCount } =
    instagramKeyword?.sentiment_count || {};
  var {
    NEG: instagramSrcNeg,
    NEU: instagramSrcNeu,
    POS: instagramSrcPos,
  } = instagramKeywordSentimentCount || {};
  var instaLikes = 0;
  var instaComments = 0;
  var instaShares = 0;
  const instagramSrcLikes = instagramKeywordResult.map((item) => {
    return (instaLikes += item.like_count);
  });
  const instagramSrcComments = instagramKeywordResult.map((item) => {
    return (instaComments += item.comments_count);
  });
  const allInstagramData = instaLikes + instaComments;

  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  const instagramSentDoughSeries = [
    instagramSrcPos || 4,
    instagramSrcNeu || 6,
    instagramSrcNeg || 3,
  ];
  const instagramSentDoughOptions = {
    chart: {
      width: 280,
      type: "donut",
    },
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
            },
          },
          // expandOnClick: true,
        },
      },
    },
    labels: ["Positive", "Neutral", "Negative"],
    dataLabels: {
      dropShadow: {
        blur: 3,
        opacity: 0.8,
      },
    },
    states: {
      hover: {
        filter: {
          type: "lighten",
          value: 0.15,
        },
      },
    },
    theme: {
      palette: "palette2",
    },
    title: {
      text: "Instagram Sentiment",
      align: "center",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    colors: ["#38EFC3", "#F2C94C", "#EA738F"],
  };
  const totalInstagramEngagement =
    instagramKeyword?.Total_engagement[0]?.Total_engagement;

  const instagramDateSort = instagramKeywordResult?.sort((a, b) => {
    return new Date(a.timestamp) - new Date(b.timestamp);
  });

  // console.log("instagramKeyword", instagramKeyword);
  // console.log("totalInstagramEngagement", totalInstagramEngagement);
  // console.log("instagramDateSort", instagramDateSort);

  const instagramEngagementSeriesTwo = [
    {
      name: "Total Engagement",
      data: instagramDateSort.map((item) => [item.timestamp, item.engagement]),
    },
  ];
  const instagramEngagementOptionsTwo = {
    chart: {
      id: "area-datetime",
      type: "area",
      height: 350,
      zoom: {
        autoScaleYaxis: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: "Instagram Engagements",
      align: "left",
      offsetX: 14,
    },
    markers: {
      size: 0,
      style: "hollow",
    },
    xaxis: {
      type: "datetime",
      min: now,
      tickAmount: 6,
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy",
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
  };

  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  const handleAfterPrint = React.useCallback(() => {
    // console.log("`onAfterPrint` called");
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    // console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    // console.log("`onBeforeGetContent` called");
    setLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  React.useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, text]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <ButtonComponent>Print</ButtonComponent>;
  }, []);

  return (
    <Box>
      {/* <div> */}
      <Paper elevation={2} sx={{ p: 1, mt: 2 }}>
        <SearchwordTitleDiv
          className='sentimentTitle'
          style={{
            display: "flex",
            padding: "15px",
            justifyContent: "center",
          }}
        >
          <KeywordTitle>
            Instagram Analysis based on{" "}
            <span style={{ color: "#ff9326" }}>{sentimentKeyword || ""} </span>
            keyword!
          </KeywordTitle>
        </SearchwordTitleDiv>
        <Charts className='charts'>
          <MentionShare className='mentionsShare'>
            <MentionShareInner
              className='mentionShareInner'
              style={{ display: "flex", flexDirection: "row" }}
            >
              <TotalMentionsShare className='totalTwitterLikes'>
                <ShareTitle>Total Engagements</ShareTitle>
                <ShareContent
                  // component='text'
                  value={totalInstagramEngagement}
                  duration={300}
                  formatValue={(n) => n.toFixed(0)}
                />
              </TotalMentionsShare>
              <TotalMentionsShare className='totalTwitterLikes'>
                <ShareTitle>Total Likes</ShareTitle>
                <ShareContent
                  // component='text'
                  value={instaLikes}
                  duration={300}
                  formatValue={(n) => n.toFixed(0)}
                />
              </TotalMentionsShare>
              <FacebookMentionsShare className='totalTwitterComments'>
                <ShareTitle>Total Comments</ShareTitle>
                <ShareContent
                  // component='text'
                  value={instaComments}
                  duration={300}
                  formatValue={(n) => n.toFixed(0)}
                />
              </FacebookMentionsShare>
              {/* <TwitterMentionsShare className='totalTwitterRetweets'>
                <ShareTitle>Total Retweets</ShareTitle>
                <ShareContent
                  component='text'
                  value={totalTwitterRetweets}
                  duration={300}
                  formatValue={(n) => n.toFixed(0)}
                />
              </TwitterMentionsShare> */}
            </MentionShareInner>
          </MentionShare>
          <div
            className='charts-inner'
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "20px",
              alignItems: "center",
              width: "100%",
            }}
          >
            <SentimentCharts className='sentimentCharts'>
              {/* <TwitterSentiment className='twitterSentiment'>
                <Chart
                  options={twitterSentDoughOptions}
                  series={twitterSentDoughSeries}
                  type='donut'
                  width='350'
                />
              </TwitterSentiment> */}
              <BlogSentiment className='blogSentiment'>
                <Chart
                  series={instagramSentDoughSeries}
                  options={instagramSentDoughOptions}
                  type='donut'
                  width='350'
                />
              </BlogSentiment>
              {/* <BlogSentiment className='blogSentiment'>
                <Chart
                  series={instagramEngagementSeriesTwo}
                  options={instagramEngagementOptionsTwo}
                  type='area'
                  width='500'
                />
              </BlogSentiment> */}
            </SentimentCharts>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <div>
                <Chart
                  series={instagramEngagementSeriesTwo}
                  options={instagramEngagementOptionsTwo}
                  type='area'
                  width='500'
                />
              </div>
            </div>
          </div>
        </Charts>
      </Paper>
      {/* </div> */}
    </Box>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstagramSummaryReport);
