import React from 'react'
import { connect } from 'react-redux'
import SocialProfiles from './sections/SocialProfiles'

export const Index = (props) => {
    return (
        <div>
            <SocialProfiles />
        </div>
    )
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
