import { combineReducers } from "redux";
import loadingReducer from "./loading";
import auth from "./auth";
import profile from "./profile";
import notification from "./notification";
import modals from "./modals";
import socials from "./socials";
import sentiment from "./sentimentReducer";
import insightPosts from './insightPosts';
import nonSocialReducer from './nonSocial';
import userDataReducer from './userData';
import faceBookReducer from './faceBookReducer';
import instagrampageReducer from './instagrampageReducer';

const rootReducer = combineReducers({
  loading: loadingReducer,
  auth: auth,
  profile,
  notification,
  modals,
  socials,
  sentiment,
  insightPosts,
  nonSocialReducer,
  dashboard: userDataReducer,
  faceBookUser: faceBookReducer,
  instaPage: instagrampageReducer,
});

export default rootReducer;
