import React, { useState } from "react";
import BarChart from "./BarChart/BarChart";
import { Bar, Pie, Line, Doughnut } from "react-chartjs-2";
// import { Chart } from "chart.js/auto";
import Chart from "react-apexcharts";

function FirstComparedItem({
  keywordOne,
  keywordOneData,
  // firstMention,
  // firstImpress,
  // firstEng,
  // noOfFirstMention,
  // TotalMentionsKeyOne,
  // TotalPositiveSentimentKeyOne,
  // TotalNeutralSentimentKeyOne,
  // TotalNegativeSentimentKeyOne,
  // TotalTwitterMentionsKeyOne,
  // TotalFacebookMentionsKeyOne
}) {
  const twitterHashtagKeyOne = keywordOneData[0] || [];
  var twitterHashtagResultKeyOne = twitterHashtagKeyOne?.result || [];
  var twitterHashtagTotalEngagementKeyOne =
    twitterHashtagKeyOne?.Engagement_total || [];
  var twitterHashtagTotalImpressionKeyOne =
    twitterHashtagKeyOne?.Impression_total || [];
  var twitterHashtagTotalSentimentKeyOne =
    twitterHashtagKeyOne?.Total_sentiment || [];
  var twitterHashtagEngagementAnalysisKeyOne =
    twitterHashtagKeyOne?.engagement_analysis || [];
  var { sentiment: twitterHashtagSentimentCountKeyOne } =
    twitterHashtagKeyOne?.sentiment_count || {};
  var {
    NEG: twitterNegKeyOne,
    NEU: twitterNeuKeyOne,
    POS: twitterPosKeyOne,
  } = twitterHashtagSentimentCountKeyOne || {};
  var twitlikesKeyOne = 0;
  var twitcommentsKeyOne = 0;
  var twitRetweetsKeyOne = 0;
  const twitterLikesKeyOne = twitterHashtagResultKeyOne.map((item) => {
    return (twitlikesKeyOne += item.Likes);
  });
  const twitterCommentsKeyOne = twitterHashtagResultKeyOne.map((item) => {
    return (twitcommentsKeyOne += item.Reply_count);
  });
  const twitterRetweetsKeyOne = twitterHashtagResultKeyOne.map((item) => {
    return (twitRetweetsKeyOne += item.Retweet_count);
  });

  // twitterSearchData
  const twitterKeywordKeyOne = keywordOneData[2] || [];
  var twitterKeywordResultKeyOne = twitterKeywordKeyOne?.result || [];
  var twitterKeywordTotalEngagementKeyOne =
    twitterKeywordKeyOne?.Engagement_total || [];
  var twitterKeywordTotalImpressionKeyOne =
    twitterKeywordKeyOne?.Impression_total || [];
  var twitterKeywordTotalSentimentKeyOne =
    twitterKeywordKeyOne?.Total_sentiment || [];
  var twitterKeywordEngagementAnalysisKeyOne =
    twitterKeywordKeyOne?.engagement_analysis || [];
  var { sentiment: twitterKeywordSentimentCountKeyOne } =
    twitterKeywordKeyOne?.sentiment_count || {};
  var {
    NEG: twitterSrcNegKeyOne,
    NEU: twitterSrcNeuKeyOne,
    POS: twitterSrcPosKeyOne,
  } = twitterHashtagSentimentCountKeyOne || {};

  const genTwitterDataKeyOne = [
    ...twitterHashtagResultKeyOne,
    ...twitterKeywordResultKeyOne,
  ];

  // facebookSearchData
  const facebookKeywordKeyOne = keywordOneData[1] || [];
  const facebookKeywordResultKeyOne = facebookKeywordKeyOne?.result || [];
  const facebookKeywordTotalSentimentKeyOne =
    facebookKeywordKeyOne?.Total_sentiment || [];
  const facebookKeywordTotalEngagementKeyOne =
    facebookKeywordKeyOne?.Total_engagement || [];
  const { Total_engagement: facebookTotalEngagementKeyOne } =
    facebookKeywordTotalEngagementKeyOne[0] || {};
  const facebookKeywordSentimentCountKeyOne =
    facebookKeywordKeyOne?.sentiment_count || {};
  const facebookKeywordSentimentCountPostsKeyOne =
    facebookKeywordSentimentCountKeyOne.posts_sentiment || {};
  const {
    NEG: FacebookNegKeyOne,
    NEU: FacebookNeuKeyOne,
    POS: FacebookPosKeyOne,
  } = facebookKeywordSentimentCountPostsKeyOne || {};
  var faceLikesKeyOne = 0;
  var faceCommentsKeyOne = 0;
  var faceSharesKeyOne = 0;
  const facebookLikesKeyOne = facebookKeywordResultKeyOne.map((item) => {
    return (faceLikesKeyOne += item.likes_no);
  });
  const facebookCommentsKeyOne = facebookKeywordResultKeyOne.map((item) => {
    return (faceCommentsKeyOne += item.comments_no);
  });
  const facebookSharesKeyOne = facebookKeywordResultKeyOne.map((item) => {
    return (faceSharesKeyOne += item.shares_no);
  });

  // blogSearchData
  const blogSearchwordKeyOne = keywordOneData[3] || [];
  const blogSearchwordResultKeyOne = blogSearchwordKeyOne?.result || [];
  const blogSearchwordSentimentCountKeyOne =
    blogSearchwordKeyOne?.sentiment_count || {};
  const blogSearchwordSentimentCountPostsKeyOne =
    blogSearchwordSentimentCountKeyOne.sentiment || {};
  const {
    NEG: BlogNegKeyOne,
    NEU: BlogNeuKeyOne,
    POS: BlogPosKeyOne,
  } = blogSearchwordSentimentCountPostsKeyOne || {};

  const TotalMentionsKeyOne =
    twitterHashtagResultKeyOne.length +
    facebookKeywordResultKeyOne.length +
    // blogSearchwordResultKeyOne.length +
    twitterKeywordResultKeyOne.length;

  // console.log("TotalMentionsKeyOne", TotalMentionsKeyOne);

  const TotalPositiveSentimentKeyOne =
    twitterPosKeyOne + twitterSrcPosKeyOne + FacebookPosKeyOne;

  const TotalNegativeSentimentKeyOne =
    twitterNegKeyOne + twitterSrcNegKeyOne + FacebookNegKeyOne;

  const TotalNeutralSentimentKeyOne =
    twitterNeuKeyOne + twitterSrcNeuKeyOne + FacebookNeuKeyOne;

  const TotalTwitterMentionsKeyOne =
    twitterHashtagResultKeyOne.length + twitterKeywordResultKeyOne.length || 0;

  const TotalFacebookMentionsKeyOne = facebookKeywordResultKeyOne.length || 0;

  const TotalBlogMentionsKeyOne = blogSearchwordResultKeyOne.length || 0;

  const TotalEngagementKeyOne =
    twitterHashtagTotalEngagementKeyOne +
    twitterKeywordTotalEngagementKeyOne +
    facebookKeywordTotalEngagementKeyOne;

  const TotalEngagementKeyTwo =
    twitterHashtagTotalEngagementKeyOne +
    twitterKeywordTotalEngagementKeyOne +
    facebookKeywordTotalEngagementKeyOne;

  // console.log(TotalMentionsKeyOne);
  // console.log(TotalPositiveSentimentKeyOne);
  // console.log(TotalNegativeSentimentKeyOne);
  // console.log(TotalNeutralSentimentKeyOne);
  // console.log(TotalTwitterMentionsKeyOne);
  // console.log(TotalFacebookMentionsKeyOne);
  // console.log(TotalBlogMentionsKeyOne);

  const FacebookTotalSentimentsKeyOne =
    FacebookNegKeyOne + FacebookNeuKeyOne + FacebookPosKeyOne;
  const FacePosPercentageKeyOne =
    (FacebookPosKeyOne / FacebookTotalSentimentsKeyOne) * 100;
  const FaceNeuPercentageKeyOne =
    (FacebookNeuKeyOne / FacebookTotalSentimentsKeyOne) * 100;
  const FaceNegPercentageKeyOne =
    (FacebookNegKeyOne / FacebookTotalSentimentsKeyOne) * 100;

  const TwitterTotalSentimentsKeyOne =
    twitterNegKeyOne +
    twitterSrcNegKeyOne +
    twitterSrcNeuKeyOne +
    twitterNeuKeyOne +
    twitterPosKeyOne +
    twitterSrcPosKeyOne;
  const TwitterPosPercentageKeyOne =
    ((twitterPosKeyOne + twitterSrcPosKeyOne) / TwitterTotalSentimentsKeyOne) *
    100;
  const TwitterNeuPercentageKeyOne =
    ((twitterNeuKeyOne + twitterSrcNeuKeyOne) / TwitterTotalSentimentsKeyOne) *
    100;
  const TwitterNegPercentageKeyOne =
    ((twitterNegKeyOne + twitterSrcNegKeyOne) / TwitterTotalSentimentsKeyOne) *
    100;

  const BlogTotalSentimentsKeyOne =
    BlogNegKeyOne + BlogNeuKeyOne + BlogPosKeyOne;
  const BlogPosPercentageKeyOne =
    (BlogPosKeyOne / BlogTotalSentimentsKeyOne) * 100;
  const BlogNeuPercentageKeyOne =
    (BlogNeuKeyOne / BlogTotalSentimentsKeyOne) * 100;
  const BlogNegPercentageKeyOne =
    (BlogNegKeyOne / BlogTotalSentimentsKeyOne) * 100;

  const dougnutMediaBg = [
    "#ff9326",
    "#ff0033",
    // "#38efc3",
    // "#ea738f",
    // "#34a853",
    // "#003087",
    // "#ff0033",
    // "#9911aa",
    // "#00000",
  ];

  // const dougnutMediaData = [firstEng, firstImpress];

  const [barData, setBarData] = useState({
    labels: ["Facebook", "Twitter"],
    datasets: [
      {
        label: `Media Share for ${keywordOne}`,
        data: [TotalFacebookMentionsKeyOne, TotalTwitterMentionsKeyOne],
        backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(255, 159, 64, 0.2)"],
        borderColor: ["rgb(255, 99, 132)", "rgb(255, 159, 64)"],
        borderWidth: 1,
      },
    ],
  });

  function handleHover(evt, item, legend) {
    legend.chart.data.datasets[0].backgroundColor.forEach(
      (color, index, colors) => {
        colors[index] =
          index === item.index || color.length === 9 ? color : color + "4D";
      }
    );
    legend.chart.update();
  }

  function handleLeave(evt, item, legend) {
    legend.chart.data.datasets[0].backgroundColor.forEach(
      (color, index, colors) => {
        colors[index] = color.length === 9 ? color.slice(0, -2) : color;
      }
    );
    legend.chart.update();
  }

  const barOptions = {
    plugins: {
      title: {
        // display: true,
        text: "Social Media Engagement Analysis",
      },
      legend: {
        position: "bottom",
        onHover: handleHover,
        onLeave: handleLeave,
      },
    },
    // responsive: true,
    // scales: {
    //   x: {
    //     stacked: true,
    //   },
    //   y: {
    //     stacked: true,
    //   },
    // },
  };

  // if (!firstImpress) {
  //   <p className='text-center'>loading...</p>;
  // }

  const series = [
    {
      name: "Net Profit",
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
    },
    {
      name: "Revenue",
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
    },
    {
      name: "Free Cash Flow",
      data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
    },
  ];

  const options = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
      ],
    },
    yaxis: {
      title: {
        text: "$ (thousands)",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$ " + val + " thousands";
        },
      },
    },
  };

  return (
    <div>
      <div
        className='flex justify-between border shadow-lg m-4 px-5 py-4 rounded-lg'
        style={{
          display: "flex",
        }}
      >
        {/* indomie  */}
        <div className='w-48'>
          <p className='text-lg font-bold mb-3 capitalize'>{keywordOne}</p>

          <div className=''>
            <p className='text-sm'>Total Mentions</p>
            <p className='text-lg space-x-1'>
              {TotalMentionsKeyOne || "No Data"}
            </p>
          </div>

          <div className='mt-2'>
            <p className='text-sm'>Positive Mentions</p>
            <p className='text-lg space-x-1 text-darkerGreen'>
              {TotalPositiveSentimentKeyOne || "No Data"}
            </p>
          </div>

          <div className='mt-2'>
            <p className='text-sm'>Neutral Mentions</p>
            <p className='text-lg space-x-1 text-darkYellow'>
              {TotalNeutralSentimentKeyOne || "No Data"}
            </p>
          </div>

          <div className='mt-2'>
            <p className='text-sm'>Negative Mentions</p>
            <p className='text-lg space-x-1 text-darkPink'>
              {TotalNegativeSentimentKeyOne || "No Data"}
            </p>
          </div>
        </div>

        {/* media source  */}
        <div className='mx-10 w-95'>
          <p className='text-lg font-bold'>Media source</p>
          <div
            style={{
              display: "flex",
              // width: "100%",
              // height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <Chart series={series} options={options} type='bar' width='350' /> */}
            <Bar data={barData} options={barOptions} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FirstComparedItem;
