import {DASHBOARD_POST} from '../types'

const initialState = {
    dashboardPost : []
}

const userDataReducer =(state = initialState, action)=>{
    switch (action.type) {
        case DASHBOARD_POST:
            return{
                ...state,
                dashboardPost : action.payload
            }
         
    
        default:
            return state;
    }
}

export default userDataReducer 