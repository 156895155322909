import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { connect, useDispatch } from "react-redux";
import { addKeyWord } from "../../../../redux/actions/modals";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TextField } from "@mui/material";
import languages from "../../../../utility/landuages";
import { ButtonCta } from "../../component/buttons/ButtonComponent";
import useAxios from "../../../../utility/axios-token-manager/init";
import { renderNotification } from "../../../../redux/actions/notification";
import { loadStart, loadStop } from "../../../../redux/actions/loading";
// import {}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxWidth: "90vw",
  bgcolor: "background.paper",
  boxShadow: 15,
  borderRadius: "5px",
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

function AddKeywordModal({ open }) {
  //   const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const [addLang, setAddLang] = useState(false);
  const [display, setDisplay] = useState(true);
  const [lang, setLang] = useState("");
  const [keyword, setKeyword] = useState("");
  const handleLanguage = (event) => {
    setLang(event.target.value);
  };

  const handleSubmitKeyword = async () => {
    if (!keyword.trim() || keyword.trim().length < 3) {
      return dispatch(
        renderNotification({
          show: true,
          message: "Please enter atleast 3 character keyword",
          type: 400,
        })
      );
    }
    const info = { keyword: keyword.trim(), lang: lang };
    // console.log(info);
    try {
      dispatch(loadStart());
      const response = await useAxios.post("account/set-up/keyword/add", info);
      dispatch(loadStop());
      const { status, data } = response;
      if (status === 201) {
        window.location.reload(false);
        return dispatch(
          renderNotification({
            show: true,
            message: data.message,
            type: status,
          })
        );
      }
      // console.log(response);
    } catch (error) {
      // console.log(error)
      dispatch(loadStop());
      const { status, data } = error.response;
      // console.log(status, data);
      dispatch(
        renderNotification({
          show: true,
          message: data.message,
          type: status,
        })
      );
    }
  };

  const handleClose = () => {
    //
    setLang("");
    dispatch(addKeyWord(false));
  };

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      {display ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={style}>
            <Typography id='modal-modal-title' variant='h6' component='h2'>
              Enter a Keyword
            </Typography>
            <Typography id='modal-modal-description'>
              What would you like to search for, a business, an hashtag or your
              competitors?
            </Typography>
            <span
              onClick={() => handleClose()}
              style={{
                position: "absolute",
                top: "20px",
                right: "20px",
                cursor: "pointer",
              }}
            >
              X
            </span>
            <br />
            <div
              style={{
                width: "100%",
              }}
            >
              <TextField
                label='Enter Keyword'
                placeholder='e.g Aliko'
                fullWidth
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
            </div>
            <div
              style={{
                width: "100%",
                paddingTop: "20px",
              }}
            >
              <FormGroup>
                <FormControlLabel
                  control={<Switch checked={addLang} color='primary' />}
                  label='Language'
                  onChange={(e) => setAddLang(e.target.checked)}
                />
              </FormGroup>
              <div style={{ marginTop: "10px" }} />
              {addLang && (
                <FormControl sx={{ minWidth: 120, width: "100%" }}>
                  <Select
                    value={lang}
                    onChange={handleLanguage}
                    displayEmpty
                    label='Language'
                    inputProps={{ "aria-label": "Without label" }}
                    fullWidth
                    variant='outlined'
                    native
                  >
                    <option value=''>All languages</option>
                    {languages.map((lan, i) => (
                      <option key={i} value={lan.code}>
                        {lan.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
            </div>
            <br />
            {/* <br /> */}
            {/* <br /> */}
            <ButtonCta
              // disabled={display}
              className='btn-yellow-bg'
              color='inherit'
              text='Add Keyword'
              onClick={handleSubmitKeyword}
              // disabled={lang.trim().length < 2}
            />
          </Box>
        </Modal>
      ) : (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={style}>
            <Typography id='modal-modal-title' variant='h6' component='h2'>
              Enter a Keyword
            </Typography>
            <Typography id='modal-modal-description'>
              What would you like to search for, a business, an hashtag or your
              competitors?
            </Typography>
            <span
              onClick={() => handleClose()}
              style={{
                position: "absolute",
                top: "20px",
                right: "20px",
                cursor: "pointer",
              }}
            >
              X
            </span>
            <br />
            <div
              style={{
                width: "100%",
              }}
            >
              <TextField
                label='Enter Keyword'
                placeholder='e.g Aliko'
                fullWidth
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
            </div>
            <div
              style={{
                width: "100%",
                paddingTop: "20px",
              }}
            >
              <FormGroup>
                <FormControlLabel
                  control={<Switch checked={addLang} color='primary' />}
                  label='Language'
                  onChange={(e) => setAddLang(e.target.checked)}
                />
              </FormGroup>
              <div style={{ marginTop: "10px" }} />
              {addLang && (
                <FormControl sx={{ minWidth: 120, width: "100%" }}>
                  <Select
                    value={lang}
                    onChange={handleLanguage}
                    displayEmpty
                    label='Language'
                    inputProps={{ "aria-label": "Without label" }}
                    fullWidth
                    variant='outlined'
                    native
                  >
                    <option value=''>All languages</option>
                    {languages.map((lan, i) => (
                      <option key={i} value={lan.code}>
                        {lan.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
            </div>
            <br />
            {/* <br /> */}
            {/* <br /> */}
            <ButtonCta
              disabled
              className='btn-yellow-rd'
              color='white'
              text='Keyword Limit Reached'
              // onClick={handleSubmitKeyword}
              // disabled={lang.trim().length < 2}
            />
          </Box>
        </Modal>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    open: state.modals.addKeyWord,
  };
};

export default connect(mapStateToProps)(AddKeywordModal);
