const initialState = {
  sentimentData: [],
  selectedKeyWord: "",
  keywordOne: "",
  keywordOneData: [],
  keywordTwo: "",
  keywordTwoData: [],
  compareData: [],
};

const dataSentiment = (state, data) => {
  return {
    ...state,
    sentimentData: data,
  };
};

const selectedKeyword = (state, data) => {
  return {
    ...state,
    selectedKeyWord: data,
  };
};

const keywordOne = (state, data) => {
  return {
    ...state,
    keywordOne: data,
  };
};

const keywordOneData = (state, data) => {
  return {
    ...state,
    keywordOneData: data,
  };
};

const keywordTwo = (state, data) => {
  return {
    ...state,
    keywordTwo: data,
  };
};

const keywordTwoData = (state, data) => {
  return {
    ...state,
    keywordTwoData: data,
  };
};

const compareKeywords = (state, data) => {
  return {
    ...state,
    compareData: data,
  };
};

const sentimentReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_SENTIMENT":
      return dataSentiment(state, action.payload);
    case "SELECTED_KEYWORD":
      return selectedKeyword(state, action.payload);
    case "KEYWORD_ONE":
      return keywordOne(state, action.payload);
    case "KEYWORD_ONE_DATA":
      return keywordOneData(state, action.payload);
    case "KEYWORD_TWO":
      return keywordTwo(state, action.payload);
    case "KEYWORD_TWO_DATA":
      return keywordTwoData(state, action.payload);
    case "COMPARE_KEYWORDS":
      return compareKeywords(state, action.payload);
    default:
      return state;
  }
};

export default sentimentReducer;
