import React, { useEffect, useState } from "react";
import "./App.css";
import { connect, useDispatch, useSelector } from "react-redux";
import { Switch, Route } from "react-router-dom";
import ApiLoader from "./utility/ApiLoader";
// import { connect } from "react-redux";
// import { NotificationContainer } from "react-notifications";
// LAZY LOAD PAGES
// import * as Pages from "./components/routes/page";
import Pages from "./components/routes";
import Notification from "./utility/Notification";
import AddKeyword from "./components/asset/component/modals/AddKeywords";
// import SearchKeyword from "./components/asset/component/modals/SearchKeyword";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import PrivacyPolicy from "./components/pages/policy/Privacy";
import ConditionOfService from "./components/pages/policy/ConditionService";
import CookieConsent from "react-cookie-consent";
import { getUserProfile } from "./redux/actions/profile";

function PrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authed === true ? (
          <Component {...props} />
        ) : (
          window.location.assign("/accounts?q=login")
        )
      }
    />
  );
}

function App({ token, isLogged, auth, onboarded }) {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const sentiment = selector?.sentiment;
  const sentimentData = sentiment?.sentimentData;
  const sentimentKeyword = sentiment?.selectedKeyWord;

  // React.useEffect(() => {
  //   dispatch(getUserProfile());
  // }, [dispatch]);

  useEffect(() => {
    const crawledItems = JSON.parse(localStorage?.getItem("combinedData"));
    const searchedTerm = localStorage?.getItem("searchedTerm");
    if (sentimentData?.length === 0) {
      dispatch({ type: "ADD_SENTIMENT", payload: crawledItems });
      dispatch({ type: "SELECTED_KEYWORD", payload: searchedTerm });
    }
    // let combinedData = localStorage.getItem("combinedData", JSON.stringify(data));
    // genData.push(combinedData)
    // dispatch({ type: "ADD_SENTIMENT", payload: combinedData });
    // setQueryResult(combinedData);
  }, [sentimentData, dispatch]);

  // const dispatch = useDispatch();
  // const [crawledItems, setCrawledItems] = useState([]);
  // useEffect(() => {
  //   const crawledItems = JSON.parse(localStorage.getItem("crawledItems"));
  //   if (crawledItems) {
  //     dispatch({ type: "ADD_SENTIMENT", payload: crawledItems });
  //     setCrawledItems(crawledItems);
  //   }
  // }, []);
  return (
    <div style={{ position: "relative" }}>
      <Notification />
      <Switch>
        <Route exact path="/" component={Pages.HomePage}></Route>
        <Route exact path="/accounts" component={Pages.AuthPage}></Route>
        <PrivateRoute
          exact
          authed={isLogged && token.length > 0}
          path="/console"
          component={
            onboarded ? Pages.DashboardLandingPage : Pages.OnboadingPage
          }
        />
        <PrivateRoute
          exact
          authed={isLogged && token.length > 0}
          path="/social-post"
          component={onboarded ? Pages.CreatePostPage : Pages.OnboadingPage}
        />
        <PrivateRoute
          exact
          authed={isLogged && token.length > 0}
          path="/mentions"
          component={onboarded ? Pages.MentionsPage : Pages.OnboadingPage}
        />
        <PrivateRoute
          exact
          authed={isLogged && token.length > 0}
          path="/emailreset"
          component={onboarded ? Pages.EmailPage : Pages.OnboadingPage}
        />
        <PrivateRoute
          exact
          authed={isLogged && token.length > 0}
          path="/passwordreset"
          component={onboarded ? Pages.PasswordPage : Pages.OnboadingPage}
        />
        {/* <PrivateRoute
          exact
          authed={isLogged && token.length > 0}
          path="/compare"
          component={onboarded ? Pages.CompareSearchItemsPage : Pages.OnboadingPage}
        /> */}
        <PrivateRoute
          exact
          authed={isLogged && token.length > 0}
          path="/analysis"
          component={onboarded ? Pages.AnalysisPage : Pages.OnboadingPage}
        />
        <PrivateRoute
          exact
          authed={isLogged && token.length > 0}
          path="/reports"
          component={onboarded ? Pages.ReportPage : Pages.OnboadingPage}
        />
        <Route
          exact
          path="/subplan"
          component={Pages.SubPlanLandingPage}
        ></Route>
        <Route
          exact
          path="/planupdate"
          component={Pages.SubPlanUpdateLandingPage}
        ></Route>
        <Route exact path="/user" component={Pages.UserLandingPage}></Route>
        <Route
          exact
          path="/addaccount"
          component={Pages.AddAccountPage}
        ></Route>

        {/*
        <Route
          exact
          path="/console"
          component={Pages.DashboardLandingPage}
        ></Route>
        <Route
          exact
          path="/social-post"
          component={Pages.CreatePostPage}
        ></Route> */}
        {/* <Route exact path="/mentions" component={Pages.MentionsPage}></Route> */}
        {/* <Route exact path="/compare" component={Pages.ComparePage}></Route> */}
        {/* <Route
          exact
          path="/compare-search"
          component={Pages.CompareSearchItemsPage}
        ></Route> */}
        {/* <Route exact path="/analysis" component={Pages.AnalysisPage}></Route> */}
        {/* <Route exact path="/reports" component={Pages.ReportPage}></Route> */}
        {/* <PrivateRoute
          exact
          authed={isLogged && token.length > 0}
          path="/console"
          component={onboarded ?  Pages.DashboardLandingPage : Pages.OnboadingPage}
        /> */}
        {/* <PrivateRoute
          exact
          authed={isLogged && token.length > 0}
          path="/social-post"
          component={onboarded ?  Pages.CreatePostPage : Pages.OnboadingPage}
        /> */}
        {/* <PrivateRoute
          exact
          authed={isLogged && token.length > 0}
          path="/social-post/history/:media/:post"
          component={onboarded ?  Pages.HistoryPostPage : Pages.OnboadingPage}
        /> */}
        <PrivateRoute
          exact
          authed={isLogged && token.length > 0}
          path="/mentions"
          component={onboarded ? Pages.MentionsPage : Pages.OnboadingPage}
        />
        <PrivateRoute
          exact
          authed={isLogged && token.length > 0}
          path="/compare"
          component={onboarded ? Pages.ComparePage : Pages.OnboadingPage}
        />
        <PrivateRoute
          exact
          authed={isLogged && token.length > 0}
          path="/analysis"
          component={onboarded ? Pages.AnalysisPage : Pages.OnboadingPage}
        />
        <PrivateRoute
          exact
          authed={isLogged && token.length > 0}
          path="/influencers"
          component={onboarded ? Pages.InfluencersPage : Pages.OnboadingPage}
        />
        <PrivateRoute
          exact
          authed={isLogged && token.length > 0}
          path="/maps"
          component={onboarded ? Pages.MapPage : Pages.OnboadingPage}
        />
        <PrivateRoute
          exact
          authed={isLogged && token.length > 0}
          path="/reports"
          component={onboarded ? Pages.ReportPage : Pages.OnboadingPage}
        />
        <PrivateRoute
          exact
          authed={isLogged && token.length > 0}
          path="/alerts"
          component={onboarded ? Pages.AlertsPage : Pages.OnboadingPage}
        />
        <PrivateRoute
          exact
          authed={isLogged && token.length > 0}
          path="/profiles"
          component={onboarded ? Pages.ProfilePage : Pages.OnboadingPage}
        />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/terms-condition" component={ConditionOfService} />
        <Route exact path="/linkedin" component={LinkedInPopUp} />
        <Route exact path='/privacy' component={PrivacyPolicy} />
        <Route exact path='/terms-condition' component={ConditionOfService} />
        <Route exact path='/linkedin' component={LinkedInPopUp} />
        <Route component={Pages.PageNotFound}></Route>
      </Switch>
      <ApiLoader />
      <AddKeyword />
      {/* <SearchKeyword /> */}
      {/* <NotificationContainer /> */}
      {/* <CookieConsent
        location='bottom'
        buttonText='X'
        cookieName='myAwesomeCookieName2'
        style={{ background: "#ff9226", color: "black" }}
        buttonStyle={{
          backgroundColor: "white",
          // color: "#ff9226",
          color: "black",
          fontSize: "13px",
          borderRadius: "20px",
        }}
        expires={150}
      >
        This website uses cookies to improve service. By continuing to use the
        service, you agree to our <a href='/'>Privacy Policy.</a>
      </CookieConsent> */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    onboarded: state.auth?.auth?.onBoarded,
    isAuth: state.auth.token !== null,
    isLogged: state.auth.isLogged,
    email: state.auth.email,
    loading: state.loading.isLoading,
    auth: state.auth,
    // profile: state?.profile?.userProfile,
  };
};

export default connect(mapStateToProps)(App);
