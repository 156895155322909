import React, { useState, Component, Fragment, useRef } from "react";
import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Paper,
} from "@mui/material";
import { connect, useDispatch, useSelector } from "react-redux";
import testPhoto from "../../../../asset/img/feather.svg";
import testPhoto2 from "../../../../asset/img/fi_smile.svg";
import testPhoto3 from "../../../../asset/img/brandteon_black_bg.png";
import {
  SearchwordTitleDiv,
  KeywordTitle,
  Charts,
  SentimentCharts,
  FacebookSentiment,
  TwitterSentiment,
  BlogSentiment,
  DataDiv,
  MentionShare,
  MentionShareInner,
  TotalMentionsShare,
  FacebookMentionsShare,
  TwitterMentionsShare,
  BlogMentionsShare,
  ShareTitle,
  ShareContent,
  SentimentTitle,
  Engagements,
  EngagementsTitle,
} from "./style/twitterReportStyle";
import { Bar, Pie, Line, Doughnut } from "react-chartjs-2";
// import { Chart } from "chart.js/auto";
import AnimatedNumber from "react-animated-number";
import SocialEngagements from "../../mentions/charts/socialEngagements";
import ButtonComponent from "../../../../Button/Button";
import Chart from "react-apexcharts";
import dayjs from "dayjs";
import ReactToPrint from "react-to-print";

export const TwitterSummaryReport = (props) => {
  // const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const sentiment = selector.sentiment;
  const sentimentData = sentiment.sentimentData;
  const sentimentKeyword = sentiment.selectedKeyWord;

  const currentDate = new Date();
  const today = currentDate.getDate();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();
  const newDate = new Date(currentYear, 0, 0).getDate();
  var now = dayjs().format("DD MMM YYYY");

  // twitterHashtagData
  const twitterHashtag = sentimentData[0] || [];
  var twitterHashtagResult = twitterHashtag?.result || [];
  var twitterHashtagTotalEngagement = twitterHashtag?.Engagement_total || [];
  var twitterHashtagTotalImpression = twitterHashtag?.Impression_total || [];
  var twitterHashtagTotalSentiment = twitterHashtag?.Total_sentiment || [];
  var twitterHashtagEngagementAnalysis =
    twitterHashtag?.engagement_analysis || [];
  var { sentiment: twitterHashtagSentimentCount } =
    twitterHashtag?.sentiment_count || {};
  var {
    NEG: twitterNeg,
    NEU: twitterNeu,
    POS: twitterPos,
  } = twitterHashtagSentimentCount || {};
  var twitlikes = 0;
  var twitcomments = 0;
  var twitRetweets = 0;
  const twitterLikes = twitterHashtagResult.map((item) => {
    return (twitlikes += item.Likes);
  });
  const twitterComments = twitterHashtagResult.map((item) => {
    return (twitcomments += item.Reply_count);
  });
  const twitterRetweets = twitterHashtagResult.map((item) => {
    return (twitRetweets += item.Retweet_count);
  });

  // twitterSearchData
  const twitterKeyword = sentimentData[3] || [];
  var twitterKeywordResult = twitterKeyword?.result || [];
  var twitterKeywordTotalEngagement = twitterKeyword?.Engagement_total || [];
  var twitterKeywordTotalImpression = twitterKeyword?.Impression_total || [];
  var twitterKeywordTotalSentiment = twitterKeyword?.Total_sentiment || [];
  var twitterKeywordEngagementAnalysis =
    twitterKeyword?.engagement_analysis || [];
  var { sentiment: twitterKeywordSentimentCount } =
    twitterKeyword?.sentiment_count || {};
  var {
    NEG: twitterSrcNeg,
    NEU: twitterSrcNeu,
    POS: twitterSrcPos,
  } = twitterKeywordSentimentCount || {};
  var twitSrclikes = 0;
  var twitSrcComments = 0;
  var twitSrcRetweets = 0;
  const twitterSrcLikes = twitterKeywordResult.map((item) => {
    return (twitSrclikes += item.Likes);
  });
  const twitterSrcComments = twitterKeywordResult.map((item) => {
    return (twitSrcComments += item.Reply_count);
  });
  const twitterSrcRetweets = twitterKeywordResult.map((item) => {
    return (twitSrcRetweets += item.Retweet_count);
  });

  const TwitterTotalSentiments =
    twitterNeg +
    twitterSrcNeg +
    twitterSrcNeu +
    twitterNeu +
    twitterPos +
    twitterSrcPos;
  const TwitterPositive = twitterPos + twitterSrcPos;
  const TwitterNeutral = twitterNeu + twitterSrcNeu;
  const TwitterNegative = twitterNeg + twitterSrcNeg;
  const TwitterPosPercentage =
    ((twitterPos + twitterSrcPos) / TwitterTotalSentiments) * 100;
  const TwitterPosSentiment = twitterPos + twitterSrcPos;
  const TwitterNeuPercentage =
    ((twitterNeu + twitterSrcNeu) / TwitterTotalSentiments) * 100;
  const TwitterNeuSentiment = twitterNeu + twitterSrcNeu;
  const TwitterNegPercentage =
    ((twitterNeg + twitterSrcNeg) / TwitterTotalSentiments) * 100;
  const TwitterNegSentiment = twitterNeg + twitterSrcNeg;

  const genTwitterData = [...twitterHashtagResult, ...twitterKeywordResult];
  const totalTwitterLikes = twitlikes + twitSrclikes;
  const totalTwitterComments = twitcomments + twitSrcComments;
  const totalTwitterRetweets = twitRetweets + twitSrcRetweets;
  const allTwitterData =
    totalTwitterLikes + totalTwitterComments + totalTwitterRetweets;

  const twitterDateSort = genTwitterData?.sort((a, b) => {
    return new Date(a.Datetime) - new Date(b.Datetime);
  });

  const testEng = twitterDateSort.map((item) => item.Engagement);

  const testDate = twitterDateSort.map((item) =>
    dayjs(item.Datetime).toISOString()
  );

  const twitterSentDoughSeries = [
    TwitterPositive || 2,
    TwitterNeutral || 5,
    TwitterNegative || 8,
  ];
  const twitterSentDoughOptions = {
    chart: {
      width: 280,
      type: "donut",
    },
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
            },
          },
        },
      },
    },
    labels: ["Positive", "Neutral", "Negative"],
    dataLabels: {
      dropShadow: {
        blur: 3,
        opacity: 0.8,
      },
    },
    states: {
      hover: {
        filter: {
          type: "lighten",
          value: 0.15,
        },
      },
    },
    theme: {
      palette: "palette2",
    },
    title: {
      text: "Twitter Sentiment",
      align: "center",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    colors: ["#38EFC3", "#F2C94C", "#EA738F"],
  };

  const totalTwitterEngagementSeries = [
    totalTwitterLikes || 2,
    totalTwitterComments || 2,
    totalTwitterRetweets || 5,
  ];
  const totalTwitterEngagementOptions = {
    chart: {
      width: 280,
      type: "donut",
    },
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
            },
          },
          // expandOnClick: true,
        },
      },
    },
    labels: ["Likes", "Comments", "Retweets"],
    dataLabels: {
      dropShadow: {
        blur: 3,
        opacity: 0.8,
      },
    },
    states: {
      hover: {
        filter: {
          type: "lighten",
          value: 0.15,
        },
      },
    },
    theme: {
      palette: "palette2",
    },
    title: {
      text: "Twitter Engagements",
      align: "center",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    colors: ["#b7405c", "#5da2d5", "#38EFC3"],
  };

  const twitterEngagementOptions = {
    chart: {
      height: 350,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: testDate,
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };

  var dataTest = twitterDateSort.map((item) => [
    item.Datetime,
    item.Engagement,
  ]);

  const twitterEngagementSeriesTwo = [
    {
      name: "Total Engagement",
      data: twitterDateSort.map((item) => [item.Datetime, item.Engagement]),
    },
  ];
  const twitterEngagementOptionsTwo = {
    chart: {
      id: "area-datetime",
      type: "area",
      height: 350,
      zoom: {
        autoScaleYaxis: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: "Twitter Engagements",
      align: "left",
      offsetX: 14,
    },
    markers: {
      size: 0,
      style: "hollow",
    },
    xaxis: {
      type: "datetime",
      min: now,
      tickAmount: 6,
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy",
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
  };

  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  React.useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, text]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <ButtonComponent>Print</ButtonComponent>;
  }, []);

  return (
    <Box>
      <div>
        <Paper elevation={2} sx={{ p: 1, mt: 2 }}>
          <SearchwordTitleDiv
            className='sentimentTitle'
            style={{
              display: "flex",
              padding: "15px",
              justifyContent: "center",
            }}
          >
            <KeywordTitle>
              Twitter Total Analysis based on{" "}
              <span style={{ color: "#ff9326" }}>
                {sentimentKeyword || ""}{" "}
              </span>
              keyword!
            </KeywordTitle>
          </SearchwordTitleDiv>
          <Charts className='charts'>
            <MentionShare className='mentionsShare'>
              <MentionShareInner
                className='mentionShareInner'
                style={{ display: "flex", flexDirection: "row" }}
              >
                <TotalMentionsShare className='totalTwitterLikes'>
                  <ShareTitle>Total Likes</ShareTitle>
                  <ShareContent
                    // component='text'
                    value={totalTwitterLikes}
                    duration={300}
                    formatValue={(n) => n.toFixed(0)}
                  />
                </TotalMentionsShare>
                <FacebookMentionsShare className='totalTwitterComments'>
                  <ShareTitle>Total Comments</ShareTitle>
                  <ShareContent
                    // component='text'
                    value={totalTwitterComments}
                    duration={300}
                    formatValue={(n) => n.toFixed(0)}
                  />
                </FacebookMentionsShare>
                <TwitterMentionsShare className='totalTwitterRetweets'>
                  <ShareTitle>Total Retweets</ShareTitle>
                  <ShareContent
                    // component='text'
                    value={totalTwitterRetweets}
                    duration={300}
                    formatValue={(n) => n.toFixed(0)}
                  />
                </TwitterMentionsShare>
              </MentionShareInner>
            </MentionShare>
            <div
              className='charts-inner'
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "20px",
                alignItems: "center",
                width: "100%",
              }}
            >
              <SentimentCharts className='sentimentCharts'>
                <TwitterSentiment className='twitterSentiment'>
                  <Chart
                    options={twitterSentDoughOptions}
                    series={twitterSentDoughSeries}
                    type='donut'
                    width='350'
                  />
                </TwitterSentiment>
                <BlogSentiment className='blogSentiment'>
                  <Chart
                    series={totalTwitterEngagementSeries}
                    options={totalTwitterEngagementOptions}
                    type='donut'
                    width='350'
                  />
                </BlogSentiment>
              </SentimentCharts>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <div>
                  <Chart
                    series={twitterEngagementSeriesTwo}
                    options={twitterEngagementOptionsTwo}
                    type='area'
                    width='500'
                  />
                </div>
              </div>
            </div>
          </Charts>
        </Paper>
      </div>
    </Box>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TwitterSummaryReport);
