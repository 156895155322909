import { INSIGHT_DATA, SECOND_ITEM, FIRST_ITEM, BLOG_POST } from "../types";

const initialState = {
  post: [],
  firstComparison: [],
  secondComparison: [],
  blogPost: [],
};

const insightPosts = (state = initialState, action) => {
  switch (action.type) {
    case INSIGHT_DATA:
      return {
        ...state,
        post: action.payload,
      };

    case FIRST_ITEM:
      return {
        ...state,
        firstComparison: action.payload,
      };

    case SECOND_ITEM:
      return {
        ...state,
        secondComparison: action.payload,
      };

    case BLOG_POST:
      return {
        ...state,
        blogPost: action.payload,
      };

    default:
      return state;
  }
};

export default insightPosts;
