export const data = [
  {
    icon: `${require("../../../asset/img/twitterBlack.svg").default}`,
    socialMediaLabel: "All",
    insights: 52,
  },
  {
    icon: `${require("../../../asset/img/twitterBlack.svg").default}`,
    socialMediaLabel: "twitter",
    insights: 52,
  },
  {
    icon: `${require("../../../asset/img/twitterBlack.svg").default}`,
    socialMediaLabel: "Instagram",
    insights: 52,
  },
  {
    icon: `${require("../../../asset/img/twitterBlack.svg").default}`,
    socialMediaLabel: "facebook",
    insights: 52,
  },
  {
    icon: `${require("../../../asset/img/twitterBlack.svg").default}`,
    socialMediaLabel: "youtube",
    insights: 52,
  },
  {
    icon: `${require("../../../asset/img/twitterBlack.svg").default}`,
    socialMediaLabel: "linkedin",
    insights: 52,
  },
  {
    icon: `${require("../../../asset/img/twitterBlack.svg").default}`,
    socialMediaLabel: "news",
    insights: 52,
  },
  {
    icon: `${require("../../../asset/img/twitterBlack.svg").default}`,
    socialMediaLabel: "video",
    insights: 52,
  },
];
