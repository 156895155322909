import React, { useEffect, useState } from 'react';
import filter from '../../../../asset/img/filter.png';
import { data } from './FeedsData';
import {
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Paper,
  Link,
} from '@mui/material';

function Feeds(searchResult) {
  const [results, setResults] = useState([]);

  useEffect(() => {
    setResults(searchResult.searchResult);
  }, []);

  // console.log('result is ', results);

  return (
    <div className="m-4 ">
      <div className=" flex justify-start items-center  my-3 ">
        <input
          type="text"
          className="border-none rounded-sm shadow p-2"
          placeholder="search mentions"
        />
        <div className="p-2 bg-white w-40 flex justify-start items-center shadow m-3">
          <img className="h-5 w-5 mr-3" src={filter} alt="filter" />
          <p>Filters</p>
        </div>

        <div className="p-2 w-40 bg-white flex justify-between items-center shadow m-3">
          <p>Prev</p>
          <p className="text-darkOrange">1</p>
          <p>2</p>
          <p>3</p>
          <p>4</p>
          <p>Next</p>
        </div>
      </div>

      <Paper elevation={2} sx={{ p: 1 }}>
        {/* <h1>Feed</h1> */}
        <Paper>
          <Grid
            container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              minHeight: '113px',
            }}
          >
            {results.length < 1
              ? 'No feed available'
              : results.map((item, index) => (
                  <Grid item xs={12} lg={8} key={index}>
                    <Paper elevation={4} sx={{ p: 1, m: 1 }}>
                      <List
                        sx={{
                          bgcolor: 'background.paper',
                        }}
                      >
                        <Typography
                          sx={{ fontSize: '11px', color: 'orange', mx: '15px' }}
                        >
                          <Link
                            onClick={() => {
                              window.open(item.link, '_blank');
                            }}
                          >
                            {item.link}
                          </Link>
                        </Typography>
                        <ListItem
                          sx={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          {item.thumbnail && (
                            <ListItemAvatar>
                              <Avatar>{item.thumbnail_image}</Avatar>
                            </ListItemAvatar>
                          )}
                          <ListItemText
                            sx={{ ml: '10px' }}
                            primary={item.title}
                            secondary={item.snippet}
                          ></ListItemText>
                        </ListItem>

                        <listItem>
                          <Typography
                            sx={{
                              fontSize: '11px',
                              color: 'orange',
                              mx: '15px',
                            }}
                          >
                            <Link
                              onClick={() => {
                                window.open(item.domain, '_blank');
                              }}
                            >
                              {item.domain}
                            </Link>
                          </Typography>
                        </listItem>
                      </List>
                    </Paper>
                  </Grid>
                ))}
          </Grid>
        </Paper>
      </Paper>
    </div>
  );
}

export default Feeds;
