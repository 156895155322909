import React, { useState, Component, Fragment, useRef } from "react";
import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Paper,
} from "@mui/material";
import { connect, useDispatch, useSelector } from "react-redux";
import testPhoto from "../../../../asset/img/feather.svg";
import testPhoto2 from "../../../../asset/img/fi_smile.svg";
import testPhoto3 from "../../../../asset/img/brandteon_black_bg.png";
import {
  SearchwordTitleDiv,
  KeywordTitle,
  Charts,
  SentimentCharts,
  FacebookSentiment,
  TwitterSentiment,
  BlogSentiment,
  DataDiv,
  MentionShare,
  MentionShareInner,
  TotalMentionsShare,
  FacebookMentionsShare,
  TwitterMentionsShare,
  BlogMentionsShare,
  ShareTitle,
  ShareContent,
  SentimentTitle,
  Engagements,
  EngagementsTitle,
} from "./style/allReportStyle";
import { Bar, Pie, Line, Doughnut } from "react-chartjs-2";
// import { Chart } from "chart.js/auto";
import AnimatedNumber from "react-animated-number";
import SocialEngagements from "../../mentions/charts/socialEngagements";
import ButtonComponent from "../../../../Button/Button";
import Chart from "react-apexcharts";
import dayjs from "dayjs";
import ReactToPrint from "react-to-print";
import {
  InstagramMentionsShare,
  InstagramSentiment,
} from "../../analysis/style/analysisStyle";

export const AllReports = (props) => {
  // const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const sentiment = selector.sentiment;
  const sentimentData = sentiment.sentimentData;
  const sentimentKeyword = sentiment.selectedKeyWord;

  const currentDate = new Date();
  const today = currentDate.getDate();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();
  const newDate = new Date(currentYear, 0, 0).getDate();
  var now = dayjs().format("DD MMM YYYY");

  // twitterHashtagData
  const twitterHashtag = sentimentData[0] || [];
  var twitterHashtagResult = twitterHashtag?.result || [];
  var twitterHashtagTotalEngagement = twitterHashtag?.Engagement_total || [];
  var twitterHashtagTotalImpression = twitterHashtag?.Impression_total || [];
  var twitterHashtagTotalSentiment = twitterHashtag?.Total_sentiment || [];
  var twitterHashtagEngagementAnalysis =
    twitterHashtag?.engagement_analysis || [];
  var { sentiment: twitterHashtagSentimentCount } =
    twitterHashtag?.sentiment_count || {};
  var {
    NEG: twitterNeg,
    NEU: twitterNeu,
    POS: twitterPos,
  } = twitterHashtagSentimentCount || {};
  var twitlikes = 0;
  var twitcomments = 0;
  var twitRetweets = 0;
  const twitterLikes = twitterHashtagResult.map((item) => {
    return (twitlikes += item.Likes);
  });
  const twitterComments = twitterHashtagResult.map((item) => {
    return (twitcomments += item.Reply_count);
  });
  const twitterRetweets = twitterHashtagResult.map((item) => {
    return (twitRetweets += item.Retweet_count);
  });

  // twitterSearchData
  const twitterKeyword = sentimentData[3] || [];
  var twitterKeywordResult = twitterKeyword?.result || [];
  var twitterKeywordTotalEngagement = twitterKeyword?.Engagement_total || [];
  var twitterKeywordTotalImpression = twitterKeyword?.Impression_total || [];
  var twitterKeywordTotalSentiment = twitterKeyword?.Total_sentiment || [];
  var twitterKeywordEngagementAnalysis =
    twitterKeyword?.engagement_analysis || [];
  var { sentiment: twitterKeywordSentimentCount } =
    twitterKeyword?.sentiment_count || {};
  var {
    NEG: twitterSrcNeg,
    NEU: twitterSrcNeu,
    POS: twitterSrcPos,
  } = twitterKeywordSentimentCount || {};
  var twitSrclikes = 0;
  var twitSrcComments = 0;
  var twitSrcRetweets = 0;
  const twitterSrcLikes = twitterKeywordResult.map((item) => {
    return (twitSrclikes += item.Likes);
  });
  const twitterSrcComments = twitterKeywordResult.map((item) => {
    return (twitSrcComments += item.Reply_count);
  });
  const twitterSrcRetweets = twitterKeywordResult.map((item) => {
    return (twitSrcRetweets += item.Retweet_count);
  });

  // facebookSearchData
  const facebookKeyword = sentimentData[1] || [];
  const facebookKeywordResult = facebookKeyword?.result || [];
  const facebookKeywordTotalSentiment = facebookKeyword?.Total_sentiment || [];
  const facebookKeywordTotalEngagement =
    facebookKeyword?.Total_engagement || [];
  const { Total_engagement: facebookTotalEngagement } =
    facebookKeywordTotalEngagement[0] || {};
  const facebookKeywordSentimentCount = facebookKeyword?.sentiment_count || {};
  const facebookKeywordSentimentCountPosts =
    facebookKeywordSentimentCount.posts_sentiment || {};
  const {
    NEG: FacebookNeg,
    NEU: FacebookNeu,
    POS: FacebookPos,
  } = facebookKeywordSentimentCountPosts || {};
  var faceLikes = 0;
  var faceComments = 0;
  var faceShares = 0;
  const facebookLikes = facebookKeywordResult.map((item) => {
    return (faceLikes += item.likes_no);
  });
  const facebookComments = facebookKeywordResult.map((item) => {
    return (faceComments += item.comments_no);
  });
  const facebookShares = facebookKeywordResult.map((item) => {
    return (faceShares += item.shares_no);
  });
  const allFacebookData = faceLikes + faceComments + faceShares;

  // blogSearchData
  const blogSearchword = sentimentData[2] || [];
  const blogSearchwordResult = blogSearchword?.result || [];
  const blogSearchwordSentimentCount = blogSearchword?.sentiment_count || {};
  const blogSearchwordSentimentCountPosts =
    blogSearchwordSentimentCount.sentiment || {};
  const {
    NEG: BlogNeg,
    NEU: BlogNeu,
    POS: BlogPos,
  } = blogSearchwordSentimentCountPosts || {};

  // instagramSearchData
  const instagramKeyword = sentimentData[4] || [];
  var instagramKeywordResult = instagramKeyword?.result || [];
  var instagramKeywordTotalEngagement =
    instagramKeyword?.Total_engagement || [];
  var instagramKetwordTotalSentiment = instagramKeyword?.Total_sentiment || [];
  var { sentiment: instagramKeywordSentimentCount } =
    instagramKeyword?.sentiment_count || {};
  var {
    NEG: instagramSrcNeg,
    NEU: instagramSrcNeu,
    POS: instagramSrcPos,
  } = instagramKeywordSentimentCount || {};
  var instaLikes = 0;
  var instaComments = 0;
  var instaShares = 0;
  const instagramSrcLikes = instagramKeywordResult.map((item) => {
    return (instaLikes += item.like_count);
  });
  const instagramSrcComments = instagramKeywordResult.map((item) => {
    return (instaComments += item.comments_count);
  });
  const allInstagramData = instaLikes + instaComments;

  // console.log("instagramKeywordResult", instagramKeywordResult);

  const TotalMentions =
    twitterHashtagResult.length +
    facebookKeywordResult.length +
    blogSearchwordResult.length +
    twitterKeywordResult.length +
    instagramKeywordResult.length;

  const TotalTwitterMentions =
    twitterHashtagResult.length + twitterKeywordResult.length;

  const FacebookTotalSentiments = FacebookNeg + FacebookNeu + FacebookPos;
  const FacePosPercentage = (FacebookPos / FacebookTotalSentiments) * 100;
  const FaceNeuPercentage = (FacebookNeu / FacebookTotalSentiments) * 100;
  const FaceNegPercentage = (FacebookNeg / FacebookTotalSentiments) * 100;

  const TwitterTotalSentiments =
    twitterNeg +
    twitterSrcNeg +
    twitterSrcNeu +
    twitterNeu +
    twitterPos +
    twitterSrcPos;
  const TwitterPositive = twitterPos + twitterSrcPos;
  const TwitterNeutral = twitterNeu + twitterSrcNeu;
  const TwitterNegative = twitterNeg + twitterSrcNeg;
  const TwitterPosPercentage =
    ((twitterPos + twitterSrcPos) / TwitterTotalSentiments) * 100;
  const TwitterPosSentiment = twitterPos + twitterSrcPos;
  const TwitterNeuPercentage =
    ((twitterNeu + twitterSrcNeu) / TwitterTotalSentiments) * 100;
  const TwitterNeuSentiment = twitterNeu + twitterSrcNeu;
  const TwitterNegPercentage =
    ((twitterNeg + twitterSrcNeg) / TwitterTotalSentiments) * 100;
  const TwitterNegSentiment = twitterNeg + twitterSrcNeg;

  const BlogTotalSentiments = BlogNeg + BlogNeu + BlogPos;
  const BlogPosPercentage = (BlogPos / BlogTotalSentiments) * 100;
  const BlogNeuPercentage = (BlogNeu / BlogTotalSentiments) * 100;
  const BlogNegPercentage = (BlogNeg / BlogTotalSentiments) * 100;

  const genTwitterData = [...twitterHashtagResult, ...twitterKeywordResult];
  const totalTwitterLikes = twitlikes + twitSrclikes;
  const totalTwitterComments = twitcomments + twitSrcComments;
  const totalTwitterRetweets = twitRetweets + twitSrcRetweets;
  const allTwitterData =
    totalTwitterLikes + totalTwitterComments + totalTwitterRetweets;

  const twitterDateSort = genTwitterData?.sort((a, b) => {
    return new Date(a.Datetime) - new Date(b.Datetime);
  });

  const testEng = twitterDateSort.map((item) => item.Engagement);

  const testDate = twitterDateSort.map((item) =>
    dayjs(item.Datetime).toISOString()
  );

  const facebookSentDoughSeries = [
    FacebookPos || 2,
    FacebookNeu || 5,
    FacebookNeg || 8,
  ];
  const facebookSentDoughOptions = {
    chart: {
      width: 280,
      type: "donut",
    },
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
            },
          },
        },
      },
    },
    labels: ["Positive", "Neutral", "Negative"],
    dataLabels: {
      dropShadow: {
        blur: 3,
        opacity: 0.8,
      },
    },
    states: {
      hover: {
        filter: {
          type: "lighten",
          value: 0.15,
        },
      },
    },
    theme: {
      palette: "palette2",
    },
    title: {
      text: "Facebook Sentiment",
      align: "center",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    colors: ["#38EFC3", "#F2C94C", "#EA738F"],
  };

  const twitterSentDoughSeries = [
    TwitterPositive || 2,
    TwitterNeutral || 5,
    TwitterNegative || 8,
  ];
  const twitterSentDoughOptions = {
    chart: {
      width: 280,
      type: "donut",
    },
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
            },
          },
        },
      },
    },
    labels: ["Positive", "Neutral", "Negative"],
    dataLabels: {
      dropShadow: {
        blur: 3,
        opacity: 0.8,
      },
    },
    states: {
      hover: {
        filter: {
          type: "lighten",
          value: 0.15,
        },
      },
    },
    theme: {
      palette: "palette2",
    },
    title: {
      text: "Twitter Sentiment",
      align: "center",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    colors: ["#38EFC3", "#F2C94C", "#EA738F"],
  };

  const blogSentDoughSeries = [BlogPos || 2, BlogNeu || 5, BlogNeg || 8];
  const blogSentDoughOptions = {
    chart: {
      width: 280,
      type: "donut",
    },
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
            },
          },
          // expandOnClick: true,
        },
      },
    },
    labels: ["Positive", "Neutral", "Negative"],
    dataLabels: {
      dropShadow: {
        blur: 3,
        opacity: 0.8,
      },
    },
    states: {
      hover: {
        filter: {
          type: "lighten",
          value: 0.15,
        },
      },
    },
    theme: {
      palette: "palette2",
    },
    title: {
      text: "Blog Sentiment",
      align: "center",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    colors: ["#38EFC3", "#F2C94C", "#EA738F"],
  };

  const totalEngagementSeries = [allFacebookData || 2, allTwitterData || 5];
  const totalEngagementOptions = {
    chart: {
      width: 280,
      type: "donut",
    },
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
            },
          },
          // expandOnClick: true,
        },
      },
    },
    labels: ["Facebook", "Twitter"],
    dataLabels: {
      dropShadow: {
        blur: 3,
        opacity: 0.8,
      },
    },
    states: {
      hover: {
        filter: {
          type: "lighten",
          value: 0.15,
        },
      },
    },
    theme: {
      palette: "palette2",
    },
    title: {
      text: "Total Engagement",
      align: "center",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    colors: ["#255C99", "#7EA3CC"],
  };

  const totalFacebookEngagementSeries = [
    faceLikes || 2,
    faceComments || 2,
    faceShares || 5,
  ];
  const totalFacebookEngagementOptions = {
    chart: {
      width: 280,
      type: "donut",
    },
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
            },
          },
          // expandOnClick: true,
        },
      },
    },
    labels: ["Likes", "Comments", "Shares"],
    dataLabels: {
      dropShadow: {
        blur: 3,
        opacity: 0.8,
      },
    },
    states: {
      hover: {
        filter: {
          type: "lighten",
          value: 0.15,
        },
      },
    },
    theme: {
      palette: "palette2",
    },
    title: {
      text: "Facebook Engagements",
      align: "center",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    colors: ["#b7405c", "#5da2d5", "#38EFC3"],
  };

  const totalTwitterEngagementSeries = [
    totalTwitterLikes || 2,
    totalTwitterComments || 2,
    totalTwitterRetweets || 5,
  ];
  const totalTwitterEngagementOptions = {
    chart: {
      width: 280,
      type: "donut",
    },
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
            },
          },
          // expandOnClick: true,
        },
      },
    },
    labels: ["Likes", "Comments", "Retweets"],
    dataLabels: {
      dropShadow: {
        blur: 3,
        opacity: 0.8,
      },
    },
    states: {
      hover: {
        filter: {
          type: "lighten",
          value: 0.15,
        },
      },
    },
    theme: {
      palette: "palette2",
    },
    title: {
      text: "Twitter Engagements",
      align: "center",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    colors: ["#b7405c", "#5da2d5", "#38EFC3"],
  };

  const twitterEngagementOptions = {
    chart: {
      height: 350,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: testDate,
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };

  var dataTest = twitterDateSort.map((item) => [
    item.Datetime,
    item.Engagement,
  ]);

  const twitterEngagementSeriesTwo = [
    {
      name: "Total Engagement",
      data: twitterDateSort.map((item) => [item.Datetime, item.Engagement]),
    },
  ];
  const twitterEngagementOptionsTwo = {
    chart: {
      id: "area-datetime",
      type: "area",
      height: 350,
      zoom: {
        autoScaleYaxis: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: "Twitter Engagements",
      align: "left",
      offsetX: 14,
    },
    markers: {
      size: 0,
      style: "hollow",
    },
    xaxis: {
      type: "datetime",
      min: now,
      tickAmount: 6,
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy",
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
  };

  const instagramSentDoughSeries = [
    instagramSrcPos || 4,
    instagramSrcNeu || 6,
    instagramSrcNeg || 3,
  ];
  const instagramSentDoughOptions = {
    chart: {
      width: 280,
      type: "donut",
    },
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
            },
          },
          // expandOnClick: true,
        },
      },
    },
    labels: ["Positive", "Neutral", "Negative"],
    dataLabels: {
      dropShadow: {
        blur: 3,
        opacity: 0.8,
      },
    },
    states: {
      hover: {
        filter: {
          type: "lighten",
          value: 0.15,
        },
      },
    },
    theme: {
      palette: "palette2",
    },
    title: {
      text: "Instagram Sentiment",
      align: "center",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    colors: ["#38EFC3", "#F2C94C", "#EA738F"],
  };

  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  React.useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, text]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <ButtonComponent>Print</ButtonComponent>;
  }, []);

  return (
    <Box>
      <div>
        <Paper elevation={2} sx={{ p: 1, mt: 2 }}>
          <SearchwordTitleDiv
            className='sentimentTitle'
            style={{
              display: "flex",
              padding: "15px",
              justifyContent: "center",
            }}
          >
            <KeywordTitle>
              Total Analysis based on{" "}
              <span style={{ color: "#ff9326" }}>
                {sentimentKeyword || ""}{" "}
              </span>
              keyword!
            </KeywordTitle>
          </SearchwordTitleDiv>
          <Charts className='charts'>
            <MentionShare className='mentionsShare'>
              <MentionShareInner
                className='mentionShareInner'
                style={{ display: "flex", flexDirection: "row" }}
              >
                <TotalMentionsShare className='totalMentionsShare'>
                  <ShareTitle>Total Mentions</ShareTitle>
                  <ShareContent
                    // component='text'
                    value={TotalMentions}
                    duration={300}
                    formatValue={(n) => n.toFixed(0)}
                  />
                </TotalMentionsShare>
                <FacebookMentionsShare className='twitterMentionsShare'>
                  <ShareTitle>Twitter Mentions</ShareTitle>
                  <ShareContent
                    // component='text'
                    value={TotalTwitterMentions}
                    duration={300}
                    formatValue={(n) => n.toFixed(0)}
                  />
                </FacebookMentionsShare>
                <TwitterMentionsShare className='facebookMentionsShare'>
                  <ShareTitle>Facebook Mentions</ShareTitle>
                  <ShareContent
                    // component='text'
                    value={facebookKeywordResult.length}
                    duration={300}
                    formatValue={(n) => n.toFixed(0)}
                  />
                </TwitterMentionsShare>
                <BlogMentionsShare className='blogMentionsShare'>
                  <ShareTitle>Blog Mentions</ShareTitle>
                  <ShareContent
                    // component='text'
                    value={blogSearchwordResult.length}
                    duration={300}
                    formatValue={(n) => n.toFixed(0)}
                  />
                </BlogMentionsShare>
                <InstagramMentionsShare className='blogMentionsShare'>
                  <ShareTitle>Instagram Mentions</ShareTitle>
                  <ShareContent
                    // component='text'
                    value={instagramKeywordResult.length}
                    duration={300}
                    formatValue={(n) => n.toFixed(0)}
                  />
                </InstagramMentionsShare>
              </MentionShareInner>
            </MentionShare>
            <div
              className='charts-inner'
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "20px",
                alignItems: "center",
                width: "100%",
              }}
            >
              <SentimentCharts className='sentimentCharts'>
                <FacebookSentiment className='facebookSentiment'>
                  <Chart
                    options={facebookSentDoughOptions}
                    series={facebookSentDoughSeries}
                    type='donut'
                    width='350'
                  />
                </FacebookSentiment>
                <TwitterSentiment className='twitterSentiment'>
                  <Chart
                    options={twitterSentDoughOptions}
                    series={twitterSentDoughSeries}
                    type='donut'
                    width='350'
                  />
                </TwitterSentiment>
                <BlogSentiment className='blogSentiment'>
                  <Chart
                    options={blogSentDoughOptions}
                    series={blogSentDoughSeries}
                    type='donut'
                    width='350'
                  />
                </BlogSentiment>
              </SentimentCharts>
              <SentimentCharts>
                <InstagramSentiment className='instagramSentiment'>
                  <Chart
                    options={instagramSentDoughOptions}
                    series={instagramSentDoughSeries}
                    type='donut'
                    width='350'
                  />
                </InstagramSentiment>
              </SentimentCharts>
              {/* <div className='mentionsCharts'></div> */}
              {/* <div className='engagementsCharts'></div> */}
            </div>
          </Charts>
          <Engagements className='engagements'>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <Chart
                options={totalEngagementOptions}
                series={totalEngagementSeries}
                type='donut'
                width='350'
              />
              <Chart
                series={totalFacebookEngagementSeries}
                options={totalFacebookEngagementOptions}
                type='donut'
                width='350'
              />
              <Chart
                series={totalTwitterEngagementSeries}
                options={totalTwitterEngagementOptions}
                type='donut'
                width='350'
              />
            </div>
          </Engagements>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <div>
              <Chart
                series={twitterEngagementSeriesTwo}
                options={twitterEngagementOptionsTwo}
                type='area'
                width='500'
              />
            </div>
          </div>
        </Paper>
      </div>
    </Box>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AllReports);
