import { getDecodedToken, getValidToken } from "../../utility/axios-token-manager/token"

const initialState = {}

const faceBookReducer = (state = initialState, action) => {
    switch (action.types) {
        case "FACEBOOK_REQUEST":
            return {
                ...state,
                faceBookUser: {}
            }
        case 'FACEBOOK_REQUEST_FAILURE':
            return {
                ...state,
                faceBookUser: {}
            }
        case 'FACEBOOK_AUTH':
            return {
                ...state,
                faceBookUser: action.payload
            }
        default:
            return state
    }
}

export default faceBookReducer