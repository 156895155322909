import dashboard from "../../asset/img/dashboard.svg";
import mentions from "../../asset/img/mentions_@.svg";
import create_post from "../../asset/img/create_post.svg";
import search_icon from "../../asset/img/search_icon.svg";
import report from "../../asset/img/report_growth.svg";
import compare from "../../asset/img/compare.svg";
import map from "../../asset/img/map.svg";
import alerts from "../../asset/img/alert.svg";
import influencers from "../../asset/img/inflencers.svg";

export const TopNav = [
  {
    name: "Dashboard",
    icon: dashboard,
    url: "/console",
  },
  {
    name: "Create Post",
    icon: create_post,
    url: "/social-post",
  },
  {
    name: "Mentions",
    icon: mentions,
    url: "/mentions",
  },
  {
    name: "Analysis",
    icon: search_icon,
    url: "/analysis",
  },
  // {
  //   name: 'Influencers',
  //   icon: influencers,
  //   url: '/influencers',
  // },
  {
    name: "Compare",
    icon: compare,
    url: "/compare",
  },
  // {
  //   name: 'World Map',
  //   icon: map,
  //   url: '/maps',
  // },
];

export const BottomNav = [
  {
    name: "Report",
    icon: report,
    url: "/reports",
  },
];
// {
//   name: 'Alert',
//   icon: alerts,
//   url: '/alerts',
// },
