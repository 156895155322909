import React, { Suspense } from "react";
import spinner from "../asset/img/loading.svg";
import Mentions from "../pages/dashboard/mentions/Index";
import Report from "../pages/dashboard/reports/Index";
import Profile from "../pages/dashboard/profile/Index";
import Analysis from "../pages/dashboard/analysis/Index";
import Influencers from "../pages/dashboard/influencers/Index";
import Compare from "../pages/dashboard/compare/Index";
import Page404 from "../pages/404/Index";
import Layout from "../layout/user/Layout";
import CompareSearchItems from "../pages/dashboard/compare/CompareSearchItems";

const Home = React.lazy(() => import("../pages/landing/Index"));
const Auth = React.lazy(() => import("../pages/auth/Index"));
const Onboading = React.lazy(() => import("../pages/onboarding/Index"));
const SubPlan = React.lazy(() =>
  import("../pages/dashboard/profile/subPlan/subPlanItems")
);
const SubPlanUpdate = React.lazy(() =>
  import("../pages/dashboard/profile/subPlan/subPlanUpdate")
);
const AddAccount = React.lazy(() => import("../pages/addAccount/addAccount"));
const UserPage = React.lazy(() => import("../pages/userPage/userPage"));
const EmailResetPage = React.lazy(() =>
  import("../pages/userPage/emailResetPage")
);
const PasswordResetPage = React.lazy(() =>
  import("../pages/userPage/passwordResetPage")
);
const Dashboard = React.lazy(() => import("../pages/dashboard/Landing"));
const CreatePost = React.lazy(() =>
  import("../pages/dashboard/post/create/CreatePost")
);
const PostHistory = React.lazy(() =>
  import("../pages/dashboard/post/history/PostHistory")
);

export const PageNotFound = () => <Page404 />;
//
export const HomePage = () => (
  <Suspense fallback={<FallBack />}>
    <Home />
  </Suspense>
);
export const AuthPage = () => (
  <Suspense fallback={<FallBack />}>
    <Auth />
  </Suspense>
);
export const OnboadingPage = () => (
  <Suspense fallback={<FallBack />}>
    <Onboading />
  </Suspense>
);
export const DashboardLandingPage = () => (
  <Suspense fallback={<FallBack />}>
    <Dashboard />
  </Suspense>
);
export const SubPlanLandingPage = () => {
  return (
    <Layout>
      <Suspense fallback={<FallBack />}>
        <SubPlan />
      </Suspense>
    </Layout>
  );
};
export const SubPlanUpdateLandingPage = () => {
  return (
    <Layout>
      <Suspense fallback={<FallBack />}>
        <SubPlanUpdate />
      </Suspense>
    </Layout>
  );
};
export const AddAccountPage = () => {
  return (
    <Layout>
      <Suspense fallback={<FallBack />}>
        <AddAccount />
      </Suspense>
    </Layout>
  );
};
export const UserLandingPage = () => {
  return (
    <Layout>
      <Suspense fallback={<FallBack />}>
        <UserPage />
      </Suspense>
    </Layout>
  );
};
export const CreatePostPage = () => {
  return (
    <Layout>
      <Suspense fallback={<FallBack />}>
        <CreatePost />
      </Suspense>
    </Layout>
  );
};
export const HistoryPostPage = () => {
  return (
    <Layout>
      <Suspense fallback={<FallBack />}>
        <PostHistory />
      </Suspense>
    </Layout>
  );
};

export const ComparePage = () => {
  return (
    <Layout>
      <Suspense fallback={<FallBack />}>
        <Compare />
      </Suspense>
    </Layout>
  );
};
export const MentionsPage = () => {
  return (
    <Layout>
      <Suspense fallback={<FallBack />}>
        <Mentions />
      </Suspense>
    </Layout>
  );
};
export const AnalysisPage = () => {
  return (
    <Layout>
      <Suspense fallback={<FallBack />}>
        <Analysis />
      </Suspense>
    </Layout>
  );
};
export const InfluencersPage = () => {
  return (
    <Layout>
      <Suspense fallback={<FallBack />}>
        <Influencers />
      </Suspense>
    </Layout>
  );
};
export const AlertsPage = () => {
  return (
    <Layout>
      <Suspense fallback={<FallBack />}>
        <Mentions />
      </Suspense>
    </Layout>
  );
};
export const MapPage = () => {
  return (
    <Layout>
      <Suspense fallback={<FallBack />}>
        <Mentions />
      </Suspense>
    </Layout>
  );
};

export const ReportPage = () => {
  return (
    <Layout>
      <Suspense fallback={<FallBack />}>
        <Report />
      </Suspense>
    </Layout>
  );
};
export const ProfilePage = () => {
  return (
    <Layout>
      <Suspense fallback={<FallBack />}>
        <Profile />
      </Suspense>
    </Layout>
  );
};
export const EmailPage = () => {
  return (
    <Layout>
      <Suspense fallback={<FallBack />}>
        <EmailResetPage />
      </Suspense>
    </Layout>
  );
};
export const PasswordPage = () => {
  return (
    <Layout>
      <Suspense fallback={<FallBack />}>
        <PasswordResetPage />
      </Suspense>
    </Layout>
  );
};

export const CompareSearchItemsPage = () => {
  return (
    <Layout>
      <Suspense fallback={<FallBack />}>
        <CompareSearchItems />
      </Suspense>
    </Layout>
  );
};

function FallBack() {
  return (
    <div className='fall-back-wrapper'>
      <div>
        <img src={spinner} alt='spinner' />
      </div>
    </div>
  );
}
