import React from "react";
import "./button.css";

function ButtonComponent({
  children,
  transparent,
  btnIcon,
  onClick,
  href,
  textBlack,
  btn_gray
}) {
  return (
    <button
      href={href}
      onClick={()=>onClick()}
      className={`${btnIcon && "btnIcon"} ${btn_gray && "btn_gray"}  ${textBlack && "textBlack"}  ${
        transparent && "btn_trans"
      }  btn`}
    >
      {children}
    </button>
  );
}

export default ButtonComponent;
