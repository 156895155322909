import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { FIRST_ITEM, SECOND_ITEM } from "../../../../redux/types";
// INSIGHT_DATA, (add to above if necessary)
import useAxios from "../../../../utility/axios-token-manager/init";
import ButtonComponent from "../../../Button/Button";
import CompareModal from "./CompareSearchItems";
import { loadStart, loadStop } from "../../../../redux/actions/loading";
import { renderNotification } from "../../../../redux/actions/notification";
import Chart from "react-apexcharts";
import axios from "axios";
// import Form from "react-bootstrap/Form";

export const Index = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [keywordOneData, setKeywordOneData] = useState([]);
  const [keywordTwoData, setKeywordTwoData] = useState([]);
  const [language, setLanguage] = useState("en");
  const [location, setLocation] = useState("nigeria");

  const [keywordOne, setKeywordOne] = useState("");
  const [keywordTwo, setKeywordTwo] = useState("");

  const dispatch = useDispatch();

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };

  const handleCompare = async (proKey) => {
    try {
      dispatch(loadStart());
      const twitterHashtagSearchOne = await axios.post(
        `${process.env.REACT_APP_BRANDTEON}/twitter/tweet-hashtag?search=${keywordOne}&location=${location}`
      );
      const facebookKeywordSearchOne = await axios.post(
        `${process.env.REACT_APP_BRANDTEON}/facebook/post?search_name=${keywordOne}`
      );
      const blogKeywordSearchOne = await axios.post(
        `${process.env.REACT_APP_BRANDTEON}/blog?search=${keywordOne}&location=${location}`
      );
      const twitterKeywordSearchOne = await axios.post(
        `${process.env.REACT_APP_BRANDTEON}/twitter/search_word?search=${keywordOne}&location=${location}&language=${language}`
      );
      const instagramKeywordSearchOne = await axios.post(
        `${process.env.REACT_APP_BRANDTEON}/instagram/hashtag?search_name=${keywordOne
          .split(" ")
          .join("")}`
      );

      const twitterHashtagSearchTwo = await axios.post(
        `${process.env.REACT_APP_BRANDTEON}/twitter/tweet-hashtag?search=${keywordTwo}&location=${location}`
      );
      const facebookKeywordSearchTwo = await axios.post(
        `${process.env.REACT_APP_BRANDTEON}/facebook/post?search_name=${keywordTwo}`
      );
      const blogKeywordSearchTwo = await axios.post(
        `${process.env.REACT_APP_BRANDTEON}/blog?search=${keywordTwo}&location=${location}`
      );
      const twitterKeywordSearchTwo = await axios.post(
        `${process.env.REACT_APP_BRANDTEON}/twitter/search_word?search=${keywordTwo}&location=${location}&language=${language}`
      );
      const instagramKeywordSearchTwo = await axios.post(
        `${process.env.REACT_APP_BRANDTEON}/instagram/hashtag?search_name=${keywordTwo
          .split(" ")
          .join("")}`
      );
      if (
        (twitterHashtagSearchOne.status &&
          facebookKeywordSearchOne.status &&
          blogKeywordSearchOne.status &&
          twitterKeywordSearchOne.status &&
          instagramKeywordSearchOne.status &&
          twitterHashtagSearchTwo.status &&
          facebookKeywordSearchTwo.status &&
          blogKeywordSearchTwo.status &&
          twitterKeywordSearchTwo.status 
          &&
          instagramKeywordSearchTwo.status
          ) === 200
      ) {
        // setTwitterHashtagResulting(twitterHashtagSearch.data);
        var twitterHashtagSrcOne = twitterHashtagSearchOne.data || [];
        // var twitterHashtagSrcOne = [];
        var facebookKeywordSrcOne = facebookKeywordSearchOne.data || [];
        // var facebookKeywordSrcOne = [];
        var blogKeywordSrcOne = blogKeywordSearchOne.data || [];
        // var blogKeywordSrcOne = [];
        var twitterKeywordSrcOne = twitterKeywordSearchOne.data || [];
        // var twitterKeywordSrcOne = [];
        var instagramKeywordSrcOne = instagramKeywordSearchOne.data || [];
        // var instagramKeywordSrcOne = [];
        var keywordOnedata = [
          twitterHashtagSrcOne,
          facebookKeywordSrcOne,
          blogKeywordSrcOne,
          twitterKeywordSrcOne,
          instagramKeywordSrcOne,
        ];
        var twitterHashtagSrcTwo = twitterHashtagSearchTwo.data || [];
        // var twitterHashtagSrcTwo = [];
        var facebookKeywordSrcTwo = facebookKeywordSearchTwo.data || [];
        // var facebookKeywordSrcTwo = [];
        var blogKeywordSrcTwo = blogKeywordSearchTwo.data || [];
        // var blogKeywordSrcTwo = [];
        var twitterKeywordSrcTwo = twitterKeywordSearchTwo.data || [];
        // var twitterKeywordSrcTwo = [];
        var instagramKeywordSrcTwo = instagramKeywordSearchTwo.data || [];
        // var instagramKeywordSrcTwo = [];
        var keywordTwodata = [
          twitterHashtagSrcTwo,
          facebookKeywordSrcTwo,
          blogKeywordSrcTwo,
          twitterKeywordSrcTwo,
          instagramKeywordSrcTwo,
        ];

        dispatch(loadStop());
        dispatch(
          renderNotification({
            show: true,
            message: "Loaded successfully",
          })
        );
        dispatch({ type: "KEYWORD_ONE_DATA", payload: keywordOnedata });
        dispatch({ type: "KEYWORD_TWO_DATA", payload: keywordTwodata });
        setKeywordOneData(keywordOnedata);
        setKeywordTwoData(keywordTwodata);
        handleOpenModal();
        // if (keywordOneStatus && keywordTwoStatus === 200) {
        //   dispatch(loadStop());
        //   dispatch(
        //     renderNotification({
        //       show: true,
        //       message: "Loaded successfully",
        //       type: keywordOneStatus,
        //     })
        //   );

        //   // dispatch(loadStop());
        // }
        // dispatch({ type: "ADD_SENTIMENT", payload: combinedData });
        // setQueryResult(combinedData);
      } else {
        dispatch(loadStop());
        return dispatch(
          renderNotification({
            show: true,
            message: "Error With Links",
            type: 404,
          })
        );
      }
    } catch {
      dispatch(loadStop());
      return dispatch(
        renderNotification({
          show: true,
          message: "Keyword Not Gotten",
        })
      );
    }
  };

  return (
    <>
      <section className=' flex justify-center items-center h-full '>
        <div className=' border shadow-sm rounded-sm p-10 px-32 flex flex-col justify-center items-center'>
          <p className='text-sm font-bold text-black mb-4'>
            To compare, you need at least two topics
          </p>
          <input
            placeholder='First Keyword'
            type='text'
            name='firstItem'
            onChange={(e) => {
              dispatch({ type: "KEYWORD_ONE", payload: e.target.value });
              setKeywordOne(e.target.value);
            }}
            className='border p-4 py-2 my-2 outline-none'
          />
          <input
            placeholder='Second Keyword'
            type='text'
            name='secondItem'
            onChange={(e) => {
              dispatch({ type: "KEYWORD_TWO", payload: e.target.value });
              setKeywordTwo(e.target.value);
            }}
            className='border px-4 py-2 my-2 outline-none'
          />
          <ButtonComponent onClick={handleCompare} textBlack>
            Compare Keywords
          </ButtonComponent>

          <ButtonComponent onClick={handleOpenModal} textBlack>
            Open Data
          </ButtonComponent>
        </div>
      </section>

      {openModal && (
        <CompareModal
          handleToggle={handleOpenModal}
          open={openModal}
          keywordOne={keywordOne}
          keywordTwo={keywordTwo}
          keywordOneData={keywordOneData}
          keywordTwoData={keywordTwoData}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
