import React from 'react';
import { Container } from '@mui/material';
import logo from "../../../components/asset/img/brandteon_logo.png";
import {Link} from "react-router-dom"

function Index() {
    return (
      <div>
        <Container sx={{ pt: 10, pb: 10 }}>
          <div>
            <Link to='/'>
              <img src={logo} width='120px' alt='logo' />
            </Link>
          </div>
          <section className='excite-home-wrapper'>
            <h4 className='text-center mb-4'>Condition of Service</h4>
            <div className='terms'>
              <h3>1. Acceptance of Terms:</h3>
              <p>
                By accessing or using the Brandteon website, you agree to
                comply with and be bound by these Terms and Conditions.
              </p>
              <h3>2. Changes to Terms:</h3>
              <p>
                Brandteon reserves the right to modify or update these Terms
                and Conditions at any time. Your continued use of the Website
                (or any of the Teon Suites Website) after any changes will
                constitute your acceptance of such changes.
              </p>
              {/* <p>
          <span>2.2 Usage Information:</span> We gather information about how
          you interact with our website and services, including IP addresses,
          device information, and browsing patterns.
        </p> */}
              {/* <p>
          <span>2.3 Account Security:</span> You are responsible for maintaining
          the confidentiality of your account credentials and for all activities
          that occur under your account. Notify us immediately of any
          unauthorized use of your account.
        </p> */}
              <h3>3. User Accounts:</h3>
              To access certain features of the Website, you may be required to
              create a user account. You are responsible for maintaining the
              confidentiality of your account information and for all activities
              that occur under your account.
              {/* <p>
          <span>3.2 Content Responsibility: </span>You are solely responsible
          for the content you upload, post, or otherwise transmit through our
          services. We reserve the right to remove any content that violates
          these terms or is deemed inappropriate.
        </p> */}
              <h3>4. Intellectual Property: </h3>
              <p>
                All content on Brandteon Website, including text, graphics,
                logos, images, and software, is the property of Teon Engine and
                is protected by copyright and other intellectual property laws.
              </p>
              <h3>5. Prohibited Activities:</h3>
              <p>
                You agree not to engage in any activities that may harm,
                disrupt, or interfere with the Website, including but not
                limited to hacking, distributing malware, or violating any
                applicable laws.
              </p>
              <h3>6.Privacy:</h3>
              <p>
                Your use of the Website is also governed by our Privacy Policy,
                which can be found{" "}
                <Link to={"/privacy-policy"}>@Privacy Policy.</Link>
              </p>
              <h3> 7. Limitation of Liability:</h3>
              <p>
                Brandteon is not liable for any direct, indirect, incidental,
                consequential, or punitive damages arising out of your use of
                the Website.
              </p>
              <h3>8. Governing Law:</h3>
              <p>
                These Terms and Conditions are governed by and construed in
                accordance with the laws of Federal Republic of Nigeria, without
                regard to its conflict of law principles.
              </p>
              <h3>9. Contact Information </h3>
              <p>
                If you have any questions or concerns about our Terms of Service
                please contact us at{" "}
                <a href={"mailto:support@teonengine.com"} className='underline'>
                  Support@teonengine.com
                </a>
              </p>
            </div>
          </section>
        </Container>
      </div>
    );
}

export default Index
 