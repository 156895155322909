
const initialState={
    show:false,
    message:"",
    type:"",
}
const notificationReducer = (state = initialState, action) => {
  // console.log(action, 'red')

    switch (action.type) {
      case "RENDER-NOTIFICATION":
        return {
          ...state,...action.payload
        };
      default:
        return state;
    } 
  };
  
  export default notificationReducer;