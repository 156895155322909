import React, { useState, Component, Fragment, useRef } from "react";
import { connect } from "react-redux";
import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Paper,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Checkbox,
} from "@mui/material";
import AllReport from "./reportTypes/AllReport";
import FacebookReport from "./reportTypes/FacebookReport";
import TwitterSummaryReport from "./reportTypes/TwitterSummaryReport";
import BlogReport from "./reportTypes/BlogReport";
import InstagramReport from "./reportTypes/InstagramReport";
import ButtonComponent from "../../../Button/Button";
import ReactToPrint from "react-to-print";
import Pdf from "react-to-pdf";

import { ContentPadding, HeadText } from "./style/reportStyle";

export const Index = (props) => {
  const [checked, setChecked] = useState([true, false]);
  const [totalMentions, setTotalMentions] = useState(false);
  const [totalFacebookMentions, setTotalFacebookMentions] = useState(false);
  const [totalTwitterMentions, setTotalTwitterMentions] = useState(false);
  const [totalBlogMentions, setTotalBlogMentions] = useState(false);
  const [totalInstagramMentions, setTotalInstagramMentions] = useState(false);

  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  React.useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, text]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <ButtonComponent>Download Report</ButtonComponent>;
  }, []);

  const ref = React.createRef();

  return (
    <div>
      <Paper elevation={2} sx={{ p: 1, mt: 2, mb: 5 }}>
        <ContentPadding>
          <HeadText>Select content to be in your report</HeadText>
        </ContentPadding>
        {/* <br /> */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <FormControl style={{ padding: "0 10px" }}>
            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              defaultValue='female'
              name='radio-buttons-group'
              // style={{ display: "flex", flexDirection: "row" }}
            >
              <FormControlLabel
                value='other'
                control={<Checkbox />}
                onChange={() => {
                  setTotalMentions(!totalMentions);
                }}
                label='Total Summary of mentions'
                labelPlacement='end'
                onChecked
              />
              <FormControlLabel
                value='other'
                control={<Checkbox />}
                onChange={() => {
                  setTotalFacebookMentions(!totalFacebookMentions);
                }}
                label='Facebook summary of mentions'
                labelPlacement='end'
              />
              <FormControlLabel
                value='other'
                control={<Checkbox />}
                onChange={() => {
                  setTotalTwitterMentions(!totalTwitterMentions);
                }}
                label='Twitter summary of mentions'
                labelPlacement='end'
              />
              <FormControlLabel
                value='other'
                control={<Checkbox />}
                label='Instagram summary of mentions'
                onChange={() => {
                  setTotalInstagramMentions(!totalInstagramMentions);
                }}
                totalInstagramMentions
                labelPlacement='end'
              />
              <FormControlLabel
                value='other'
                control={<Checkbox />}
                onChange={() => {
                  setTotalBlogMentions(!totalBlogMentions);
                }}
                label='Blog summary of mentions'
                labelPlacement='end'
              />
            </RadioGroup>
          </FormControl>
          <ReactToPrint
            content={reactToPrintContent}
            documentTitle='Brandteon Report'
            onAfterPrint={handleAfterPrint}
            onBeforeGetContent={handleOnBeforeGetContent}
            onBeforePrint={handleBeforePrint}
            removeAfterPrint
            trigger={reactToPrintTrigger}
            style={{ height: "20%" }}
          />
        </div>
      </Paper>
      {(totalMentions ||
        totalFacebookMentions ||
        totalTwitterMentions ||
        totalInstagramMentions ||
        totalBlogMentions) === false ? (
        <Paper elevation={2} sx={{ p: 1, mt: 5, mb: 5 }}>
          <div>Select A Section</div>
        </Paper>
      ) : (
        <Paper ref={componentRef} elevation={2} sx={{ p: 1, mb: 5 }}>
          <div style={{ padding: "0 10px" }}>
            {totalMentions && <AllReport />}
            <br />
            {totalFacebookMentions && <FacebookReport />}
            <br />
            {totalTwitterMentions && <TwitterSummaryReport />}
            <br />
            {totalBlogMentions && <BlogReport />}
            <br />
            {totalInstagramMentions && <InstagramReport />}
          </div>
        </Paper>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
