import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Modal, Radio, RadioGroup, FormControlLabel } from "@mui/material";
import { connect, useDispatch, useSelector } from "react-redux";

// import { addKeyWord } from "../../../../redux/actions/modals";
// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Switch from "@mui/material/Switch";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import { TextField } from "@mui/material";

// import useAxios from "../../../../utility/axios-token-manager/init";
// import { renderNotification } from "../../../../redux/actions/notification";
// import {loadStart,loadStop} from "../../../../redux/actions/loading"
import { connectIG } from "../../../../../redux/actions/modals";
import { ButtonCta } from "../../../../asset/component/buttons/ButtonComponent";
import * as socialApi from "../../../../api/social";
import { renderNotification } from "../../../../../redux/actions/notification";
import { loadStart, loadStop } from "../../../../../redux/actions/loading";
import { instagramPagedata } from "../../../../../utility/host";
import { getInstagramPageInfoAction } from "../../../../../redux/actions/instagramPageAction";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxWidth: "90vw",
  bgcolor: "background.paper",
  boxShadow: 15,
  borderRadius: "5px",
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

function ConnectIGModal({ open, facebookPages }) {
  const [pageId, setPageId] = React.useState("");
  // const { instagramPageData } = useSelector(state => state.instaPage)
  const dispatch = useDispatch();

  const handleClose = () => {
    //
    setPageId("");
    dispatch(connectIG(false));
  };

  const handleConnectIG = async () => {
    if (!pageId)
      return dispatch(renderNotification({
        show: true,
        message: "Please select a page",
        type: 400,
      }));
    dispatch(loadStart());
    try {
      const response = await socialApi.addSocialAccountIG(pageId);
      const { status, data } = response;
      if (data.success === true) {
        localStorage.setItem(instagramPagedata, JSON.stringify(data.data))
        dispatch(
          renderNotification({
            show: true,
            message: data.message,
            type: status,
          })
        );
        dispatch(connectIG(false));
        dispatch(loadStop());
        window.location.reload()
      }
      else {
        dispatch(loadStop());
        dispatch(
          renderNotification({
            show: true,
            message: data.message,
            type: 300,
          })
        );
      }
    } catch (error) {
      dispatch(loadStop());
      const data = {
        message: error.message,
        success: false,
      }
      return dispatch(
        renderNotification({
          show: true,
          message: data.message,
          type: 500,
        })
      );
    }
  };
  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="body2"
            component="p"
            sx={{ mb: 2 }}
          >
            Select connected facebook page
          </Typography>
          <RadioGroup>
            {facebookPages?.map((item, index) => (
              <FormControlLabel
                label={item.name}
                control={<Radio />}
                value={item.id}
                key={index}
                onChange={() => setPageId(item.id)}
              />
            ))}
          </RadioGroup>
          <br />
          <ButtonCta
            text="Connect"
            type="outline"
            onClick={() => handleConnectIG()}
          />
        </Box>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    open: state.modals.connectIG,
    facebookPages: state.profile?.userProfile?.authProfiles?.facebook?.pages,
  };
};

export default connect(mapStateToProps)(ConnectIGModal);
