import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch, connect } from "react-redux";
// import { signUOutCompany } from "../../../redux/actions/auth";
import "./../../asset/css/side-bar.css";
import { Link } from "react-router-dom";
// import classnames from 'classnames'
import {
  ProSidebar,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
  Menu,
  MenuItem,
  SubMenu,
} from "react-pro-sidebar";
import { logOut } from "../../../redux/actions/auth";

import styles from "./layout.module.css";
import logo from "./../../asset/img/brandteon_black_bg.png";
import brandteon_icon from "./../../asset/img/brand_icon.png";
import open_nav from "./../../asset/img/open_nav.svg";
import collapse_nav from "./../../asset/img/collapse_nav.svg";
import { TbLayoutSidebarLeftCollapse } from "react-icons/tb";
import { TopNav, BottomNav } from "./menu";
import { ButtonCta } from "../../asset/component/buttons/ButtonComponent";
// import { Avatar } from "@material-ui/core";
// import {getMyProfile} from './../../../redux/actions/employee';
import { useHistory } from "react-router";
import { addKeyWord, searchKeyWord } from "../../../redux/actions/modals";
import { getUserProfile } from "../../../redux/actions/profile";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import alerts from "../../asset/img/alert.svg";
import { IoLogOut } from "react-icons/io5";
import { CgProfile } from "react-icons/cg";

function Layout({ children, profile }) {
  // console.log(profile)
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = history.location;
  const handleActiveState = (url) => {
    if (url === pathname) return true;
    return false;
  };
  React.useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const subscriptionType = profile?.subscriptionType;
  const subscriptionPrice = profile?.subscriptionPrice;
  const subscriptionStart = profile?.subscriptionStart;
  const subscriptionEnd = profile?.subscriptionEnd;

  const date = new Date();
  var startDate = date.setDate(date.getDate());
  var daysRemaining = Math.floor(
    (new Date(subscriptionEnd) - new Date(startDate)) / 1000 / 60 / 60 / 24
  );

  const [navCollapse, setNavCollapse] = useState(false);
  const [navToggle, setNavToggle] = useState(false);
  //
  // const path = window.location.pathname;
  //

  const ImageContainer = styled.div``;
  const Hr = styled.hr``;
  const Image = styled.img`
    width: 37px;
    height: 37px;
    border-radius: 50%;
    margin-left: 130px;
  `;
  const Image2 = styled.img`
    width: 37px;
    height: 37px;
    margin-left: 10px;
    margin-top: 10px;
  `;
  const DropDownDiv = styled.div`
    display: flex;
    margin-top: 10px;
    color: white;
    margin-right: 72px;
  `;
  const UserDiv = styled.div`
    display: flex;
    margin-bottom: 10px;
  `;
  const UserEmail = styled.p`
    font-size: 12px;
    color: #828282;
  `;
  const SideBarTglIcon = styled.img`
    width: 30px;
    margin-left: 23px;
    margin-right: 13px;
    margin-bottom: 50px;
  `;
  const SideBarTglDiv = styled.div`
    display: flex;
    font-size: 15px;
    width: 205px;
    margin-bottom: 15px;
    padding-right: 10px;
  `;
  const DropDownFlex = styled.div`
    margin-top: 20px;
    display: flex;
  `;

  // const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  //   <a
  //     href=''
  //     ref={ref}
  //     onClick={(e) => {
  //       e.preventDefault();
  //       onClick(e);
  //     }}
  //   >
  //     {children}
  //     &#x25bc;
  //   </a>
  // ));

  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  // const CustomMenu = React.forwardRef(
  //   ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
  //     const [value, setValue] = useState("");

  //     return (
  //       <div
  //         ref={ref}
  //         style={style}
  //         className={className}
  //         aria-labelledby={labeledBy}
  //       >
  //         <Form.Control
  //           autoFocus
  //           className='mx-3 my-2 w-auto'
  //           placeholder='Type to filter...'
  //           onChange={(e) => setValue(e.target.value)}
  //           value={value}
  //         />
  //         <ul className='list-unstyled'>
  //           {React.Children.toArray(children).filter(
  //             (child) =>
  //               !value || child.props.children.toLowerCase().startsWith(value)
  //           )}
  //         </ul>
  //       </div>
  //     );
  //   }
  // );

  const SideBar = ({ collapsed }) => {
    return (
      // <div style={{position:'fixed', height:'100vh'}}>
      <ProSidebar
        collapsed={collapsed}
        toggled={navToggle}
        breakPoint='md'
        onToggle={() => setNavToggle(false)}
      >
        <SidebarHeader>
          <Link to='/'>
            <div className={styles.sideBarHeader}>
              <img
                src={collapsed ? brandteon_icon : logo}
                alt='brand'
                width={collapsed ? "50px" : "100px"}
              />
            </div>
          </Link>
          <br />
        </SidebarHeader>
        <SidebarContent>
          {TopNav.map((nav, index) => (
            <Menu key={index}>
              <MenuItem
                icon={
                  <img src={nav.icon} alt='icon' style={{ width: "24px" }} />
                }
              >
                <div className='nav-item-wrapper'>
                  <Link to={nav.url} style={{ color: "#fff" }}>
                    {nav.name}
                  </Link>
                  <div
                    className={
                      handleActiveState(nav.url)
                        ? "nav-indicator active-indicator"
                        : "nav-indicator"
                    }
                  ></div>
                </div>
              </MenuItem>
            </Menu>
          ))}

          {/* spacer */}
          <div style={{ margin: "50px 0" }} />
          {/*  */}
          {BottomNav.map((nav, index) => (
            <Menu key={index}>
              <MenuItem
                icon={
                  <img src={nav.icon} alt='icon' style={{ width: "24px" }} />
                }
                style={{
                  background: handleActiveState(nav.url)
                    ? "#171717"
                    : "inherit",
                }}
              >
                <div className='nav-item-wrapper'>
                  <Link to={nav.url} style={{ color: "#fff" }}>
                    {nav.name}
                  </Link>
                  <div
                    className={
                      handleActiveState(nav.url)
                        ? "nav-indicator active-indicator"
                        : "nav-indicator"
                    }
                  ></div>
                </div>
              </MenuItem>
            </Menu>
          ))}
          <Menu>
            <SubMenu
              icon={
                <CgProfile
                  style={{ height: "100%", width: "100%", color: "white" }}
                />
              }
              title='Profile & Accounts'
            >
              <MenuItem>
                <Link to='/user'>Accounts settings</Link>
              </MenuItem>
              <MenuItem>
                <Link to='/subplan'>Billing & Subscription</Link>
              </MenuItem>
              <MenuItem>
                <Link to='/profiles'>Social Profiles</Link>
              </MenuItem>
            </SubMenu>
          </Menu>
        </SidebarContent>
        <SidebarFooter>
          <Menu>
            <MenuItem
              icon={
                <IoLogOut
                  style={{ height: "100%", width: "100%", color: "white" }}
                />
              }
              onClick={() => {
                dispatch(logOut());
                window.localStorage.clear();
                window.location.href = "/accounts?q=login";
              }}
            >
              <div className='nav-item-wrapper' style={{ color: "#fff" }}>
                Log Out
              </div>
            </MenuItem>
          </Menu>
        </SidebarFooter>
      </ProSidebar>
    );
  };

  const ToolBar = () => {
    return (
      <div className={styles.toolBar}>
        <div className='container-fluid'>
          <div className={styles.togglers}>
            <span
              className={styles.nav}
              onClick={() => setNavToggle(!navToggle)}
            >
              {/* <img src={open_nav} alt='navigation' style={{ width: "24px" }} /> */}
              <TbLayoutSidebarLeftCollapse
                style={{ width: "24px", height: "24px", color: "white" }}
              />
            </span>
            <div
              className={styles.collapse}
              onClick={() => setNavCollapse(!navCollapse)}
            >
              <TbLayoutSidebarLeftCollapse
                style={{ width: "24px", height: "24px", color: "white" }}
              />
            </div>
          </div>
          <div className={styles.panels}>
            <div className={styles.title}>
              <h5>Welcome, {profile?.name}</h5>
            </div>
            <div className={styles.ctas} style={{ marginRight: "10px" }}>
              {daysRemaining > 0 ? (
                <ButtonCta
                  text='Create Keyword'
                  className='btn-yellow-bg'
                  onClick={() => dispatch(addKeyWord(true))}
                  // style={{ marginRight: "5px" }}
                />
              ) : (
                <Link to='/subplan'>
                  <ButtonCta
                    text='Subscribe to a plan'
                    className='btn-yellow-rd'
                  />
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <section className={styles.layout}>
        <div className={styles.sideWrapper}>
          <SideBar collapsed={navCollapse} />
        </div>
        <div className={styles.views}>
          <ToolBar />
          <div className={styles.layoutChildren}>{children}</div>
        </div>
      </section>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    profile: state?.profile?.userProfile,
  };
};

export default connect(mapStateToProps)(Layout);
