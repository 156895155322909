
export const addKeyWord=(state)=>{
    return{
        type:"ADD-KEYWORD",
        payload:state
    }
}

export const searchKeyWord=(state)=>{
    return{
        type:"SEARCH-KEYWORD",
        payload:state
    }
}

export const connectIG = (state)=>{
    return{
        type:"CONNECT-IG",
        payload:state
    }
}