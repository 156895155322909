export const data = [
    {
        avatar:`${require('../../../../asset/img/avatar.png').default}`,
        username:"adebisi the headmaster",
        handle:"@busybody",
        socialMediaIcon: `${require('../../../../asset/img/twitter.png').default}`,
        postedImage: "",       
        caption:"You guys, the indomie i just made ehn!!",
        date:"09.04.2021",
        time:"12:31Am",
        activity:"view activity",
        socialHandle:"twitter.com",
        influencerScore:"7/10",
        influencerStrength:80,
    },
    {
        avatar:`${require('../../../../asset/img/avatar.png').default}`,
        username:"adebisi the headmaster",
        handle:"@busybody",
        socialMediaIcon: `${require('../../../../asset/img/twitter.png').default}`,
        postedImage: `${require('../../../../asset/img/noodles.png').default}`,       
        caption:"You guys, the indomie i just made ehn!!",
        date:"09.04.2021",
        time:"12:31Am",
        activity:"view activity",
        socialHandle:"twitter.com",
        influencerScore:"7/10",
        influencerStrength:80,
    },

    {
        avatar:`${require('../../../../asset/img/avatar.png').default}`,
        username:"olawale",
        handle:"",
        socialMediaIcon: `${require('../../../../asset/img/twitter.png').default}`,
        postedImage: "",       
        caption:"This people should bring back indomie pepper soup oo!! #Peppersoupislife",
        date:"09.04.2021",
        time:"12:31Am",
        activity:"view activity",
        socialHandle:"olablog.com",
        influencerScore:"7/10",
        influencerStrength:80,
    },

    {
        avatar:`${require('../../../../asset/img/avatar.png').default}`,
        username:"african heroes",
        handle:"",
        socialMediaIcon: `${require('../../../../asset/img/twitter.png').default}`,
        postedImage: "",       
        caption:"You guys, the indomie i just made ehn!!",
        date:"09.04.2021",
        time:"12:31Am",
        activity:"view activity",
        socialHandle:"twitter.com",
        influencerScore:"7/10",
        influencerStrength:80,
    },

    {
        avatar:`${require('../../../../asset/img/avatar.png').default}`,
        username:"adebisi the headmaster",
        handle:"@busybody",
        socialMediaIcon: `${require('../../../../asset/img/twitter.png').default}`,
        postedImage: "",       
        caption:"You guys, the indomie i just made ehn!!",
        date:"09.04.2021",
        time:"12:31Am",
        activity:"view activity",
        socialHandle:"twitter.com",
        influencerScore:"7/10",
        influencerStrength:80,
    },

    {
        avatar:`${require('../../../../asset/img/avatar.png').default}`,
        username:"adebisi the headmaster",
        handle:"@busybody",
        socialMediaIcon: `${require('../../../../asset/img/twitter.png').default}`,
        postedImage: "",       
        caption:"You guys, the indomie i just made ehn!!",
        date:"09.04.2021",
        time:"12:31Am",
        activity:"view activity",
        socialHandle:"twitter.com",
        influencerScore:"7/10",
        influencerStrength:80,
    },
    {
        avatar:`${require('../../../../asset/img/avatar.png').default}`,
        username:"adebisi the headmaster",
        handle:"@busybody",
        socialMediaIcon: `${require('../../../../asset/img/twitter.png').default}`,
        postedImage: `${require('../../../../asset/img/noodles2.png').default}`,       
        caption:"You guys, the indomie i just made ehn!!",
        date:"09.04.2021",
        time:"12:31Am",
        activity:"view activity",
        socialHandle:"twitter.com",
        influencerScore:"7/10",
        influencerStrength:80,
    },

    {
        avatar:`${require('../../../../asset/img/avatar.png').default}`,
        username:"olawale",
        handle:"",
        socialMediaIcon: `${require('../../../../asset/img/twitter.png').default}`,
        postedImage: "",       
        caption:"This people should bring back indomie pepper soup oo!! #Peppersoupislife",
        date:"09.04.2021",
        time:"12:31Am",
        activity:"view activity",
        socialHandle:"olablog.com",
        influencerScore:"7/10",
        influencerStrength:80,
    },

    {
        avatar:`${require('../../../../asset/img/avatar.png').default}`,
        username:"african heroes",
        handle:"",
        socialMediaIcon: `${require('../../../../asset/img/twitter.png').default}`,
        postedImage: "",       
        caption:"You guys, the indomie i just made ehn!!",
        date:"09.04.2021",
        time:"12:31Am",
        activity:"view activity",
        socialHandle:"twitter.com",
        influencerScore:"7/10",
        influencerStrength:80,
    },

    {
        avatar:`${require('../../../../asset/img/avatar.png').default}`,
        username:"adebisi the headmaster",
        handle:"@busybody",
        socialMediaIcon: `${require('../../../../asset/img/twitter.png').default}`,
        postedImage: "",       
        caption:"You guys, the indomie i just made ehn!!",
        date:"09.04.2021",
        time:"12:31Am",
        activity:"view activity",
        socialHandle:"twitter.com",
        influencerScore:"7/10",
        influencerStrength:80,
    },
    {
        avatar:`${require('../../../../asset/img/avatar.png').default}`,
        username:"adebisi the headmaster",
        handle:"@busybody",
        socialMediaIcon: `${require('../../../../asset/img/twitter.png').default}`,
        postedImage: "",       
        caption:"You guys, the indomie i just made ehn!!",
        date:"09.04.2021",
        time:"12:31Am",
        activity:"view activity",
        socialHandle:"twitter.com",
        influencerScore:"7/10",
        influencerStrength:80,
    },
    {
        avatar:`${require('../../../../asset/img/avatar.png').default}`,
        username:"adebisi the headmaster",
        handle:"@busybody",
        socialMediaIcon: `${require('../../../../asset/img/twitter.png').default}`,
        postedImage: `${require('../../../../asset/img/noodles.png').default}`,       
        caption:"You guys, the indomie i just made ehn!!",
        date:"09.04.2021",
        time:"12:31Am",
        activity:"view activity",
        socialHandle:"twitter.com",
        influencerScore:"7/10",
        influencerStrength:80,
    },
];