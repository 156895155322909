
const initialState = {
    success: false,
    instagramPageData: {},
    message: ''
}
const instagramDataReducer = (state = initialState, action) => {
    // console.log(action, 'red')

    switch (action.type) {
        case "SAVE_INSTAGRAM_FAILED":
            return {
                ...state, instagramPageData: [], success: false, message: action.message
            };
        case "SAVE_INSTAGRAM_DATA":
            return {
                ...state, instagramPageData: action.payload, success: action.success, message: action.message
            };
        default:
            return state;
    }
};

export default instagramDataReducer;