import styled from "styled-components";

const KeywordDiv = styled.div`
  // width: 60%;
  // height: 70px;
  left: 284px;
  top: 1298px;
  background: #ffffff;
  // box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
`;

const KeywordInnerDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  padding: 10px;
  // justify-content: space-between;
  justify-content: center;
`;

const ServicesDiv = styled.div`
  // width: 60%;
  // height: 70px;
  left: 284px;
  top: 1298px;
  background: #ffffff;
  // box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
`;

const ServicesInnerDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  padding: 10px;
  justify-content: space-evenly;
`;

const Service = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ServiceIcon = styled.div`
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  padding-bottom: 7px;
`;

const ServiceName = styled.div`
  font-family: Karla;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #828282;
  padding-bottom: 3px;
`;

const ServiceCount = styled.div`
  font-family: Karla;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  color: #828282;
`;

const KeywordItem = styled.div`
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #ff9326;

  &:hover {
    cursor: pointer;
    background: #ff9326;
  }

  &.clicked {
    background: #ff9326;
    color: #ffffff;
  }
`;

const ItemDiv = styled.div`
  width: 100%;
  //   height: 212px;
  left: 284px;
  top: 1298px;
  background: #ffffff;
  // box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  box-shadow: 0px;
  border-radius: 5px;
  display: flex;
  /* flex-direction; */
  margin-bottom: 20px;
  //   flex-wrap: wrap;
`;

const InnerDiv = styled.div`
  margin: 20px;
  display: flex;
  align-items: center;
  width: 90%;
`;

const ProfileDiv = styled.div`
  margin: 30px;
  width: 15%;
`;

const Content = styled.div`
  width: 70%;
  margin: 30px 0 10px 0;
`;

const ContentTop = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
`;

const ContentTopLeft = styled.div``;

const ProfileName = styled.h1`
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
`;

const ContentTopRight = styled.div`
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ff9326;
`;

const ContentMiddle = styled.div`
  display: flex;
  padding-bottom: 10px;
`;

const ContentMiddleText = styled.h1`
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  max-width: 450px;
  color: #171717;
`;

const ContentBottom = styled.div`
  display: flex;
  width: 70%;
  justify-content: space-between;
  margin: 10px 0;
`;

const ContentBottomLeft = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
`;

const SentimentColorGreen = styled.div`
  width: 8px;
  height: 8px;
  left: 656px;
  top: 1407px;
  background: #38efc3;
  border-radius: 50%;
  margin: 0 5px 0 0;
`;

const SentimentColorRed = styled.div`
  width: 8px;
  height: 8px;
  left: 656px;
  top: 1407px;
  background: red;
  border-radius: 50%;
  margin: 0 5px 0 0;
`;

const SentimentColorBlue = styled.div`
  width: 8px;
  height: 8px;
  left: 656px;
  top: 1407px;
  background: orange;
  border-radius: 50%;
  margin: 0 5px 0 0;
`;

const Charts = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  background: #ffffff;
  // box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 20px;
`;

const SentimentCharts = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 10px 0;
`;

const FacebookSentiment = styled.div`
  width: 250px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const TwitterSentiment = styled.div`
  width: 250px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const BlogSentiment = styled.div`
  width: 250px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const DataDiv = styled.div`
  background: #ffffff;
  // box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`;

const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding-bottom: 5px;
`;

const DateDiv = styled.div`
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 12px;
  color: #828282;
  padding-bottom: 5px;
`;

const TimeDiv = styled.div`
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 12px;
  color: #828282;
  padding-bottom: 5px;
`;

const MentionsList = styled.div`
  display: flex;
  // overflow-y: scroll;
  // width: 500px;
  // height: 110px;
  // overflow-x: hidden;
  // overflow-y: auto;
  // text-align: justify;
`;
const MentionsListInnerDiv = styled.div`
  display: flex;
  // overflow-y: scroll;
  // width: 500px;
  height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
`;

export {
  KeywordDiv,
  KeywordInnerDiv,
  ServicesDiv,
  ServicesInnerDiv,
  Service,
  ServiceIcon,
  ServiceName,
  ServiceCount,
  KeywordItem,
  ItemDiv,
  InnerDiv,
  ProfileDiv,
  Content,
  ContentTop,
  ContentTopLeft,
  ProfileName,
  ContentTopRight,
  ContentMiddle,
  ContentMiddleText,
  ContentBottom,
  ContentBottomLeft,
  SentimentColorGreen,
  SentimentColorRed,
  SentimentColorBlue,
  Charts,
  SentimentCharts,
  FacebookSentiment,
  TwitterSentiment,
  BlogSentiment,
  DataDiv,
  ProfileImage,
  DateDiv,
  TimeDiv,
  MentionsList,
  MentionsListInnerDiv,
};
