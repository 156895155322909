import styled from "styled-components";
import AnimatedNumber from "react-animated-number";

const SearchwordTitleDiv = styled.div`
  display: flex;
  padding: 15px;
  justifycontent: center;
`;

const KeywordTitle = styled.h1`
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  color: #171717;
`;

const Charts = styled.div`
  display: flex;
  // align-items: center;
  flex-direction: column;
  justify-content: space-around;
  background: #ffffff;
  // box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 20px;
`;

const Sentiments = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  width: 10%;
`;

const SentimentTitleHead = styled.h1`
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #171717;
`;

const SentimentCharts = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 10px 0;
`;

const FacebookSentiment = styled.div`
  width: 250px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const FacebookEngagement = styled.div`
  width: 250px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const BlogSentiment = styled.div`
  width: 250px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const DataDiv = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`;

const MentionShare = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MentionShareInner = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: row;
  width: 70%;
  justify-content: space-around;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 10%);
  border-radius: 5px;
  margin-bottom: 20px;
`;

const TotalFacebookShares = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const TotalFacebookLikes = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const TotalFacebookComments = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const BlogMentionsShare = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ShareTitle = styled.h1`
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #171717;
`;

const ShareContent = styled(AnimatedNumber)`
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  text-transform: uppercase;
  color: #ff9326;
  transitionproperty: "background-color, color, opacity";
`;

const SentimentTitle = styled.div`
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  color: #171717;
  margin-bottom: 10px;
`;

const Engagements = styled.div`
  padding: 20px;
`;

const EngagementsTitle = styled.h1`
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #171717;
`;

export {
  SearchwordTitleDiv,
  KeywordTitle,
  Charts,
  Sentiments,
  SentimentTitleHead,
  SentimentCharts,
  FacebookSentiment,
  FacebookEngagement,
  BlogSentiment,
  DataDiv,
  MentionShare,
  MentionShareInner,
  TotalFacebookShares,
  TotalFacebookLikes,
  TotalFacebookComments,
  BlogMentionsShare,
  ShareTitle,
  ShareContent,
  SentimentTitle,
  Engagements,
  EngagementsTitle,
};
