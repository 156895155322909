import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Avatar, Grid, Paper, Typography } from "@mui/material";
import SocialButton from "../../../../../utility/SocialLoginComponent";
import * as socialApi from "../../../../api/social";
import { loadStart, loadStop } from "../../../../../redux/actions/loading";
import { renderNotification } from "../../../../../redux/actions/notification";
import facebookIcon from "../../../../asset/img/icons8-facebook-logo-144 1.svg";
import instagramIcon from "../../../../asset/img/icons8-instagram-logo-144 3.svg";
import plus from "../../../../asset/img/plus white.svg";
import ConnectIGModal from "./ConnectIGModal";
import { connectIG } from "../../../../../redux/actions/modals";
import { facebookLogin } from "../../../../../redux/actions/auth";
import SocialLogin from "react-social-login";
import FacebookAuth from "react-facebook-auth";


//
export const SocialProfiles = ({ facebook, instagram }) => {
  const [loggedIn, setloggedIn] = useState(false);
  const [faceBookUser, setfaceBookUser] = useState({});
  useEffect(() => {
    const storage = localStorage.getItem(`${process.env.NODE_ENV}-facebook`)
    const returned = JSON.parse(storage)
    storage !== null ? setfaceBookUser(returned) : setfaceBookUser({})
    // return () => {
    //   cleanup
    // }
  }, []);
  const dispatch = useDispatch();
  const handleLinkedFacebook = async (result) => {
    dispatch(loadStart());
    const info = {
      name: result.name,
      userId: result.userID,
      profilePicture: result.picture.data.url,
      shortLivedUserAccessToken: result.accessToken,
    };
    dispatch(facebookLogin(result));
    setfaceBookUser(info);
    // setfaceBookUser(result._profile)
    localStorage.setItem(
      `${process.env.NODE_ENV}-facebook`,
      JSON.stringify(info)
    );
    const response = await socialApi.addSocialAccount(info, `facebook`);
    const { status, data } = response;
    // console.log("data", data);
    if (data.success === true) {
      dispatch(loadStop());
      setloggedIn(true);
      dispatch(
        renderNotification({
          show: true,
          message: data.message,
          type: status,
        })
      );
      window.location.reload();
    } else {
      dispatch(loadStop());
      setloggedIn(false);
      dispatch(
        renderNotification({
          show: true,
          message: data.message || "Something went wrong",
          type: 300,
        })
      );
    }
  };
  const handleConnectIG = () => {
    dispatch(connectIG(true));
  };
  const findLinkedFbToIGPage = (fbPageId) => {
    const fbPages = facebook.pages.find((item) => item.id === fbPageId);
    if (!fbPages) return "no name";
    return fbPages.name;
  };

  const MyFacebookButton = ({ onClick }) => (
    <div className='btn w-100 d-flex align-items-center justify-content-start link-fb'>
      <img
        src={facebookIcon}
        alt='facebook'
        style={{
          width: "2em",
          height: "2em",
          borderRadius: "100%",
          marginRight: ".4em",
          backgroundColor: "white",
        }}
      />
      <button onClick={onClick}>Login with facebook</button>
    </div>
  );

  return (
    <>
      <Grid container spacing={12}>
        <Grid item lg={6}>
          <Paper
            sx={{ p: 4, boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)" }}
            elevation={0}
          >
            <Typography variant='h6' className='mb-4'>
              Select a social profile you would like to connect
            </Typography>
            {Object.keys(faceBookUser).length !== 0 ? (
              <div className='btn w-100 d-flex align-items-center justify-content-start link-fb'>
                <div className='d-flex align-items-center'>
                  <img
                    src={faceBookUser.profilePicture}
                    alt='facebook'
                    style={{
                      width: "2.5em",
                      height: "2.5em",
                      borderRadius: "100%",
                      marginRight: "1em",
                    }}
                  />
                </div>
                {`You are logged in as ${faceBookUser.name}`}
              </div>
            ) : (
              <FacebookAuth
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                cookiePolicy={"single_host_origin"}
                autoLoad={false}
                scope='instagram_basic,pages_read_engagement,pages_show_list,instagram_manage_insights,read_insights,public_profile,email,instagram_content_publish,pages_manage_posts,'
                onFailure={() =>
                  dispatch(
                    renderNotification({
                      show: true,
                      message: "Facebook login failed",
                      type: "warning",
                    })
                  )
                }
                callback={handleLinkedFacebook}
                component={MyFacebookButton}
              />
            )}
            {Object.keys(faceBookUser).length !== 0 ? (
              <div className='link-instagram'>
                <button className='btn w-100' onClick={handleConnectIG}>
                  <div className='d-flex align-items-center'>
                    <img
                      src={faceBookUser?.profilePicture}
                      alt='facebook'
                      style={{
                        width: "2.5em",
                        height: "2.5em",
                        borderRadius: "100%",
                        marginRight: ".4em",
                      }}
                    />
                    {`You are logged in as ${faceBookUser.name}`}
                  </div>
                  <img src={plus} alt='plus' className='ml-auto' />
                </button>
              </div>
            ) : (
              <div className='link-instagram'>
                <button className='btn w-100' onClick={handleConnectIG}>
                  <div className='d-flex align-items-center'>
                    <img
                      src={instagramIcon}
                      alt='facebook'
                      style={{
                        width: "2.5em",
                        height: "2.5em",
                        borderRadius: "100%",
                        marginRight: ".4em",
                        backgroundColor: "white",
                      }}
                    />
                    {`Instagram business profile`}
                  </div>
                  <img src={plus} alt='plus' className='ml-auto' />
                </button>
              </div>
            )}
          </Paper>
        </Grid>
        <Grid item lg={6}>
          <Paper
            sx={{ p: 4, boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)" }}
            elevation={0}
          >
            <Typography variant='h6' className='mb-4'>
              You already connected these profiles
            </Typography>
            {facebook?.pages?.map((item, index) => (
              <div className='connected-acct' key={index}>
                <div className='avatar'>
                  <Avatar src={facebook.profilePicture} alt='picture' />
                  <img src={facebookIcon} alt='facebook' width='15px' />
                </div>
                <Typography> {`${item.name}`}</Typography>
              </div>
            ))}
            {/* Instagram */}
            {instagram?.length > 0 &&
              instagram?.map((item, index) => (
                <div className='connected-acct' key={index}>
                  <div className='avatar'>
                    <Avatar src={facebook.profilePicture} alt='picture' />
                    <img src={instagramIcon} alt='facebook' width='15px' />
                  </div>
                  <Typography>{findLinkedFbToIGPage(item.fbPageId)}</Typography>
                </div>
              ))}
          </Paper>
        </Grid>
      </Grid>
      {/* Modal */}
      <ConnectIGModal />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    facebook: state.profile?.userProfile?.authProfiles?.facebook,
    instagram: state.profile?.userProfile?.authProfiles?.instagram,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SocialProfiles);
